import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import Breadcrumbs from '../Breadbrumbs/Breadcrumbs';

import Dashboard from '../../pages/dashboard/Dashboard';
import Profile from '../../pages/profile/Profile';
import StockGudangPage from '../Users/list/StockGudangPage';

import BarCharts from '../../pages/charts/bar/BarCharts';
import LineCharts from '../../pages/charts/line/LineCharts';
import PieCharts from '../../pages/charts/pie/PieCharts';
import OtherCharts from '../../pages/charts/other/OtherCharts';

import Login from '../../pages/auth/login/Login';

import s from './Layout.module.scss';

import AdminListPage from '../Users/list/AdminListPage';

import TambahStock from '../../pages/uielements/lists/TambahStock';
import KeluarStock from '../../pages/uielements/lists/KeluarStock';
import AjukanPermintaan from '../../pages/uielements/lists/AjukanPermintaan';

import MasukStockPuskes from '../../pages/uielements/lists/MasukStockPuskes';
import StockPage from '../Users/list/StockPage';
import KeluarStockPuskes from '../../pages/uielements/lists/KeluarStockPuskes';
import TableLapNeraca from '../../pages/tables/TableLapNeraca';
import TableNeracaMasukGudang from '../../pages/tables/TableNeracaMasukGudang';
import TableNeracaMasukGudang_Gudang from '../../pages/tables/TableNeracaMasukGudang_Gudang';
import TableLapNeraca_Gudang from '../../pages/tables/TableLapNeraca_Gudang';
import TableResponPermintaan from '../../pages/tables/TableResponPermintaan';
import TableRekapMasuk from '../../pages/tables/TableRekapMasuk';
import TableRekapKeluar from '../../pages/tables/TableRekapKeluar';
import TableDataObat from '../../pages/tables/TableDataObat';

const Layout = (props) => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
            <Route
              path='/template'
              exact
              render={() => <Redirect to='template/dashboard' />}
            />
            <Route path='/template/dashboard' exact component={Dashboard} />
            <Route
              path='/template/user'
              exact
              render={() => <Redirect to={'/template/user/profile'} />}
            />
            <Route path='/template/user/profile' exact component={Profile} />

            <Route
              path='/template/stockgudang'
              exact
              render={() => <Redirect to={'/template/stockgudang/Neraca'} />}
            />
            <Route
              path='/template/stockgudang/Neraca'
              exact
              component={StockGudangPage}
            />

            <Route
              path='/template/stockgudang/masuk'
              exact
              component={TambahStock}
            />
            <Route
              path='/template/stockgudang/keluar'
              exact
              component={KeluarStock}
            />
            <Route
              path='/template/permintaan/ajukan'
              exact
              component={AjukanPermintaan}
            />
            <Route
              path='/template/permintaan/respon'
              exact
              component={TableResponPermintaan}
            />

            <Route
              path='/template/rekapan/masuk'
              exact
              component={TableRekapMasuk}
            />

            <Route
              path='/template/rekapan/keluar'
              exact
              component={TableRekapKeluar}
            />

            <Route path='/template/dataobat' exact component={TableDataObat} />

            <Route
              path='/template/stock'
              exact
              render={() => <Redirect to={'/template/stock/neraca'} />}
            />

            <Route
              path='/template/stock/masuk'
              exact
              component={MasukStockPuskes}
            />

            <Route
              path='/template/stock/keluar'
              exact
              component={KeluarStockPuskes}
            />

            <Route path='/template/stock/neraca' exact component={StockPage} />

            <Route
              path='/template/laporan'
              exact
              render={() => <Redirect to={'/template/laporan/neraca'} />}
            />

            <Route
              path='/template/laporan/neraca'
              exact
              component={TableLapNeraca}
            />
            <Route
              path='/template/laporan/neracaobat'
              exact
              component={TableNeracaMasukGudang}
            />
            <Route path='/admin/listadmin' exact component={AdminListPage} />

            <Route
              path='/template/laporan/neracaobatgudang'
              exact
              component={TableNeracaMasukGudang_Gudang}
            />

            <Route
              path='/template/laporan/neracagudang'
              exact
              component={TableLapNeraca_Gudang}
            />

            <Route path='/template/extra/login' exact component={Login} />

            <Route path='*' exact render={() => <Redirect to='/error' />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    currentUser: store.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Form,
  Input,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget.js';
import './table.css';

import optionsIcon from '../../assets/tables/optionsIcon.svg';
import printerIcon from '../../assets/tables/printerIcon.svg';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import s from './Tables.module.scss';

import {
  API_TIMEOUT,
  KELUAR,
  LOKASI,
  MASUK,
  REKAPAN,
} from '../../utils/index.js';
import axios from 'axios';

import { useDispatch } from 'react-redux';

import Success from '../../assets/success.png';
import { formatDate, logApp, numberWithCommas } from '../../utils/func.js';
import Select, { components } from 'react-select';
import swal from 'sweetalert';
import { dateTime } from '../../utils/func.js';
import Stop from '../../assets/stop.png';

const TableRekapKeluar = function () {
  const [firstTable, setFirstTable] = useState([]);
  const [secondTable, setSecondTable] = useState([]);
  const [lokasi, setLokasi] = useState('');
  const [level, setLevel] = useState('');
  const [puskesmas, setPuskesmas] = useState('');
  const [unit, setUnit] = useState('');

  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);

  const [modalDelete, setModalDelete] = useState(false);

  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState('All');
  const [preFirstTable, setPreFirstTable] = useState([]);

  const [tahun, setTahun] = useState('2023');
  const [jumlahTerima, setJumlahTerima] = useState('');
  const [modalEdit, setModalEdit] = useState(false);

  const [currentUser, setCurrentUser] = useState();
  const [idLokasi, setIdLokasi] = useState('');
  const [status, setStatus] = useState(-1);

  const getDataKeluar = (faktur) => {
    axios({
      method: 'get',
      url:
        level == 'Dinas'
          ? KELUAR + 'gudang/' + faktur
          : level == 'Puskesmas'
          ? KELUAR + 'puskesmas/' + faktur
          : KELUAR + 'unit/' + faktur,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          setSecondTable(data);

          //menyesuakian kolom dari permintaan sesuai dengan input dari list
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, 'Rekapan Keluar');
      setIdLokasi(currentUser.idLokasi);
    }
  }, []);

  const containerStyle = {
    width: '100%',
    height: '300px', // Atur tinggi sesuai kebutuhan Anda
    overflow: 'auto',
    /* Gaya tampilan scroll */
    scrollbarWidth: 'thin',
    scrollbarColor: '#888888 #f0f0f0',
  };

  useEffect(() => {
    if (status) {
      if (status == -1) {
        setFirstTable(preFirstTable);
      } else if (status == 0) {
        const dd = preFirstTable.filter((item) => {
          return item.status == 0;
        });

        setFirstTable(dd);
      } else if (status == 1) {
        const dd = preFirstTable.filter((item) => {
          return item.status == 1;
        });

        setFirstTable(dd);
      } else if (status == 2) {
        const dd = preFirstTable.filter((item) => {
          return item.status == 2;
        });

        setFirstTable(dd);
      } else if (status == 3) {
        const dd = preFirstTable.filter((item) => {
          return item.status == 3;
        });

        setFirstTable(dd);
      }
    }
  }, [status]);

  useEffect(() => {
    if (idLokasi) {
      ambilDataLokasi();
    }
  }, [idLokasi]);

  useEffect(() => {
    if (level) {
      ambilRekapKeluar(idLokasi);
    }
  }, [level]);

  const ambilDataLokasi = () => {
    axios({
      method: 'get',
      url: LOKASI + 'ambil/',
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          if (data.length > 0) {
            const fil = data.filter((item) => {
              return idLokasi == item.id;
            });

            const level = fil[0].level;

            if (level == 'Puskesmas') {
              setPuskesmas(fil[0].lokasi);
              setUnit('');

              const datafilter = data.filter((item) => {
                return item.id_Puskesmas == fil[0].id;
              });
              let lok = [];
              for (var i = 1; i < datafilter.length; i++) {
                lok.push({
                  value: datafilter[i].id,
                  label: datafilter[i].lokasi,
                  color: 'primary',
                });
              }

              setLokasi(lok);
            } else {
              const datafilter = data.filter((item) => {
                return item.id == fil[0].id_Puskesmas;
              });

              setUnit(fil[0].lokasi);

              setPuskesmas(datafilter[0].lokasi);

              setLokasi([
                {
                  value: 74,
                  label: 'Pasien',
                  color: 'primary',
                },
              ]);
            }
            setLevel(level);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleEdit = () => {};

  const ambilRekapKeluar = (id) => {
    axios({
      method: 'get',
      url:
        level == 'Dinas'
          ? REKAPAN + 'keluargudang/'
          : level == 'Puskesmas'
          ? REKAPAN + 'keluarpuskes/' + idLokasi
          : REKAPAN + 'keluarunit/' + idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          setPreFirstTable(data);
          setFirstTable(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const listStatus = [
    { value: -1, status: 'All' },
    { value: 0, status: 'On Progress' },
    { value: 1, status: 'Semua Diterima' },
    { value: 2, status: 'Sebagian Diterima' },
    { value: 3, status: 'Semua Ditolak' },
  ];

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curStatus = listStatus.filter((item) => {
      return item.status == select;
    });
    setStatus(curStatus[0].value);
    setTahun(curStatus[0].status);

    // ambilDataBantuanKategori(currentBantuan, curAnak[0].tahun);
  };

  const OptionComponent = ({ data, ...props }) => {
    return (
      <components.Option {...props}>
        <div className={`bullet bullet-${data.color} bullet-sm mr-2`}></div>
        {data.label}
      </components.Option>
    );
  };

  const pageSize = 100;
  const pageSize2 = 100;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize2);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const openModal = (data) => {
    getDataKeluar(data.noFaktur);

    setModalDelete(true);
  };

  const openModal2 = (data) => {
    setJumlahTerima(data.totalTerima);
    setModalEdit(true);
  };

  const closeModal = () => {
    setSecondTable([]);
    setModalDelete(false);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className='mb-4'>
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className='headline-2'>
                    Daftar Faktur <code>{'Keluar'}</code>
                  </div>
                  <div>
                    {' '}
                    <ReactHTMLTableToExcel
                      table='neraca_sumber'
                      filename={'Rekapan Keluar ' + new Date()}
                      sheet='Data'
                      className='btnKu'
                    />
                  </div>

                  <div className='d-flex'>
                    <Col className='pr-grid-col' xs={4}>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color='primary'>
                          {simpleDropdownValue2}
                        </DropdownToggle>

                        <DropdownMenu>
                          {listStatus &&
                            listStatus.map((item) => (
                              <DropdownItem
                                onClick={changeSelectDropdownSimple2}
                              >
                                {item.status}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </div>
                </div>
                <div className='widget-table-overflow'>
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    id='neraca_sumber'
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>No</th>
                        <th className='w-25'>Faktur</th>

                        <th className='w-25'>TANGGAL KELUAR</th>

                        <th className='w-25'>STATUS</th>

                        <th className='w-25'>JUMLAH OBAT</th>
                        <th className='w-25'>TOTAL HARGA</th>

                        <th className='w-25'>PETUGAS</th>
                        <th className='w-25'>TUJUAN</th>

                        <th className='w-25'>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item, index) => (
                          <tr key={uuidv4()}>
                            <td>{index + 1}</td>
                            <td className='d-flex align-items-center'>
                              <span className='ml-3'>{item.noFaktur}</span>
                            </td>

                            <td>{formatDate(item.tanggalKeluar)}</td>

                            <td>
                              {item.status == 0 ? (
                                'On Progres'
                              ) : (
                                <img
                                  style={{ width: 25, height: 25 }}
                                  src={
                                    item.status == 1
                                      ? Success
                                      : item.status == 3 || item.status == 2
                                      ? Stop
                                      : ''
                                  }
                                />
                              )}
                            </td>
                            <td>{numberWithCommas(item.jumlahObat)}</td>
                            <td>Rp.{numberWithCommas(item.totalHarga)}</td>

                            <td>{item.nama}</td>
                            <td>{item.lokasi}</td>

                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color='primary'
                                style={{ position: 'relative' }}
                                size='xs'
                                onClick={() => {
                                  openModal(item);
                                }}
                              >
                                {(item.status == 2 || item.status == 3) && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      backgroundColor: '#FF5668',
                                      position: 'absolute',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: 5,
                                      borderRadius: '50%',
                                      right: -7,
                                      top: -10,
                                      width: 15,
                                      height: 15,
                                      color: 'white',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => ''}
                                  >
                                    <text style={{ fontSize: 12 }}> {''}</text>
                                  </div>
                                )}
                                Data
                              </Button>
                            </td>

                            <td></td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className='pagination-borderless'
                    aria-label='Page navigation example'
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href='#top'
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href='#top'
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href='#top'
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size='lg' isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          <div className={s.tableTitle}>
            <div className='headline-2'>
              Daftar Obat
              <code> Keluar </code>
            </div>
            <div className='d-flex'>
              <a>
                <ReactHTMLTableToExcel
                  table='dataBantuan'
                  filename={'faktur minta-'}
                  sheet='Data'
                  className='btnKu'
                />
              </a>
              <a>
                <img src={printerIcon} alt='Printer' />
              </a>
              <a>
                <img
                  className='d-none d-sm-block'
                  src={optionsIcon}
                  alt='Options'
                />
              </a>
              <a>
                <img
                  style={{ width: 30, height: 30 }}
                  src={Success}
                  alt='Funnel'
                />
              </a>
            </div>
          </div>
        </ModalHeader>

        <Widget className='widget-p-md'>
          <div className='widget-table-overflow'>
            <Table
              id='dataBantuan'
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead>
                <tr>
                  <th className={s.checkboxCol}>
                    <div className='checkbox checkbox-primary'>
                      <input
                        className='styled'
                        id='checkbox100'
                        type='checkbox'
                      />
                      <label for='checkbox100' />
                    </div>
                  </th>
                  <th className='w-25'>Obat</th>
                  <th className='w-25'>Satuan</th>

                  <th className='w-25'>Jumlah Keluar</th>
                  <th className='w-25'>Total Harga</th>
                  <th className='w-25'>Jumlah Terima</th>
                  <th className='w-25'>Catatan</th>
                  <th className='w-25'>Sumber</th>
                  <th className='w-25'>Tahun Pengadaan</th>
                  <th className='w-25'>Expired Date</th>
                  <th className='w-25'>Action</th>
                </tr>
              </thead>
              <tbody>
                {secondTable
                  .slice(
                    secondTableCurrentPage * pageSize2,
                    (secondTableCurrentPage + 1) * pageSize2
                  )
                  .map((item) => (
                    <tr key={uuidv4()}>
                      <td>
                        <div className='checkbox checkbox-primary'>
                          <input
                            id={item.nama}
                            className='styled'
                            type='checkbox'
                          />
                          <Label for={item.nama} />
                        </div>
                      </td>
                      <td className='d-flex align-items-center'>
                        <span className='ml-3'>{item.nama}</span>
                      </td>

                      <td>{item.satuan}</td>
                      <td>
                        {item.totalKeluar && numberWithCommas(item.totalKeluar)}
                      </td>
                      <td>
                        Rp.
                        {item.totalHarga && numberWithCommas(item.totalHarga)}
                      </td>

                      <td>{item.totalTerima}</td>
                      <td>{item.catatan}</td>
                      <td>{item.sumber}</td>
                      <td>{item.tahunPengadaan}</td>
                      <td>{formatDate(item.ed)}</td>
                      <td>
                        {item.totalTerima == item.totalKeluar ? (
                          <img
                            style={{ width: 20, height: 20 }}
                            src={Success}
                          />
                        ) : (
                          <Button
                            className={`${s.controlBtn} mx-2`}
                            color='primary'
                            size='xs'
                            onClick={() => {
                              openModal2(item);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              className='pagination-borderless'
              aria-label='Page navigation example'
            >
              <PaginationItem disabled={secondTableCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    setSecondTablePage(e, secondTableCurrentPage - 1)
                  }
                  previous
                  href='#top'
                />
              </PaginationItem>
              {[...Array(secondTablePagesCount)].map((page, i) => (
                <PaginationItem active={i === secondTableCurrentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => setSecondTablePage(e, i)}
                    href='#top'
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={secondTableCurrentPage >= secondTablePagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    setFirstTablePage(e, secondTableCurrentPage + 1)
                  }
                  next
                  href='#top'
                />
              </PaginationItem>
            </Pagination>
          </div>
        </Widget>
      </Modal>
      <Modal size='sm' isOpen={modalEdit} toggle={() => setModalEdit(false)}>
        <ModalHeader toggle={() => setModalEdit(false)}>
          <div className={s.tableTitle}>
            <div className='headline-2'>{'Edit Obat Keluar'}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Widget className='widget-p-md'>
            <FormGroup>
              <Label for='description'>Jumlah Keluar</Label>
              <Input
                type='text'
                name='text'
                id='description'
                value={jumlahTerima}
                onChange={(e) => setJumlahTerima(e.target.value)}
              />
            </FormGroup>
          </Widget>
        </ModalBody>
        <ModalFooter>
          <Button
            className='btn-rounded'
            size='sm'
            color='secondary-red'
            onClick={() => setModalEdit(false)}
          >
            Cancel
          </Button>
          <div style={{ marginLeft: 5 }}></div>
          <Button
            className='btn-rounded'
            color='primary'
            size='sm'
            onClick={() => handleEdit()}
          >
            {' '}
            {'Simpan'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TableRekapKeluar;

import React from "react";
import { Badge, Col, Row, Spinner } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Widget from "../../../components/Widget/Widget.js";
import {
  Form,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
  CustomInput,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Select, { components } from "react-select";

import Flatpickr from "react-flatpickr";
import { numberWithCommas } from "../../../utils/func.js";

import s from "./Lists.module.scss";

import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useParams } from "react-router";
import {
  API_TIMEOUT,
  MASUK_GUDANG,
  STOCK_GUDANG,
  SUB_SUMBER,
  SUMBER,
} from "../../../utils/index.js";
import { logApp } from "../../../utils/func.js";
import swal from "sweetalert";
import { set } from "lodash";

export default function TambahStock() {
  const [list, setList] = useState([]);
  const [listTampil, setListTampil] = useState([]);

  const [allDay, setAllDay] = useState(false);

  const [ed, setEd] = useState("");

  const [firstTable, setFirstTable] = useState([]);

  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [tampilDaftarObat, setTampilDaftarObat] = useState(true);

  const [edit, setEdit] = useState(false);

  const [prosesSimpan, setProsesSimpan] = useState(false);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const pageSize = 10;

  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);

  const { makan } = useParams();
  let kategoriKu = [];

  if (kategoriKu.length == 1) kategoriKu = kategoriKu[0];
  if (kategoriKu.length == 2) kategoriKu = kategoriKu[0] + " " + kategoriKu[1];

  const containerStyle = {
    width: "100%",
    height: "300px", // Atur tinggi sesuai kebutuhan Anda
    overflow: "auto",
    /* Gaya tampilan scroll */
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
  };

  const radialChartStyle = {
    top: "50%",
    left: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px",
  };

  const tahunKu = [
    { value: 2018, label: 2018, color: "primary" },
    { value: 2019, label: 2019, color: "primary" },
    { value: 2020, label: 2020, color: "primary" },
    { value: 2021, label: 2021, color: "primary" },
    { value: 2022, label: 2022, color: "primary" },
    { value: 2023, label: 2023, color: "primary" },
  ];

  const penyediaKu = [
    { value: "PT Intan Berlian", label: "PT Intan Berlian", color: "primary" },
    { value: "PT Kuda Laut", label: "PT Kuda Laut", color: "primary" },
    { value: "PT Halilintar", label: "PT Halilintar", color: "primary" },
  ];

  const [selected, setSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [jumlah, setJumlah] = useState(0);

  const [uid, setUid] = useState();
  const [user, setUser] = useState();
  const [nama, setNama] = useState("");

  const [id_dataObat, setId_dataObat] = useState("");
  const [id_Petugas, setId_Petugas] = useState("");
  const [sumber, setSumber] = useState("");

  const [sumberOk, setSumberOk] = useState("");

  const [noRegistrasi, setNoRegistrasi] = useState("");
  const [penyedia, setPenyedia] = useState("");
  const [batch, setBatch] = useState("");
  const [pbfPenyalur, setPbfPenyalur] = useState("");

  const [tahunPengadaan, setTahunPengadaan] = useState("");

  const [tahunPengadaanOk, setTahunPengadaanOk] = useState("");
  const [harga, setHarga] = useState("");
  const [jumlahMasuk, setJumlahMasuk] = useState("");
  const [satuan, setSatuan] = useState("");
  const [namaObat, setNamaObat] = useState("");
  const [idEdit, setIdEdit] = useState();
  const [tanggalMasuk, setTanggalMasuk] = useState("");

  const [listSumber, setListSumber] = useState([]);
  const [listSubSumber, setListSubSumber] = useState([]);
  const [selectSubSumber, setSelectSubSUmber] = useState([]);

  const [subSumber, setSubSumber] = useState("");

  const CloseBtn = (
    <i
      className="eva eva-close cursor-pointer"
      onClick={() => onModalClose()}
    />
  );

  function generateInvoiceNumber() {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    const invoiceNumber =
      `INV${year}${month}${day}${hours}${minutes}${seconds}` + "T1" + "mas";
    return invoiceNumber;
  }

  const [noFaktur, setNoFaktur] = useState("");

  useEffect(() => {
    ambilSumber();
    ambilSubSumber();
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setUser(user);
      setUid(user.uid);
      logApp(user.uid, "Stock Gudang Keluar");
    }
  }, []);

  useEffect(() => {
    ambilDataObat();
  }, []);

  const ambilSumber = () => {
    axios({
      method: "get",
      url: SUMBER,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const data = response.data.data;

          const listsum = data.map((item) => {
            return { value: item.id, label: item.sumber };
          });
          setListSumber(listsum);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilSubSumber = () => {
    axios({
      method: "get",
      url: SUB_SUMBER,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setListSubSumber(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilDataObat = () => {
    axios({
      method: "get",
      url: STOCK_GUDANG,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setList(response.data.data);
          setListTampil(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const selectAction = (item) => {
    setSelected(item);
    setNamaObat(item.nama);
    setId_dataObat(item.id);
    setSatuan(item.satuan);
    setModalOpen(true);
  };

  const handleSimpan = () => {
    if (firstTable.length < 1) {
      swal("Info", "Anda belum memasukan data obat", "info");
    } else {
      setProsesSimpan(true);
      handleSimpanDB();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (edit) {
      handleEditList();
      return;
    }

    const data = {};

    if (!subSumber) {
      swal("Info", "Anda perlu mengisi data sumber", "info");
      return;
    }

    if (!penyedia) {
      swal("Info", "Anda perlun mengisi data penyedia", "info");
      return;
    }

    if (!ed) {
      swal("Info", "Anda perlu mengisi data expired date", "info");
      return;
    }

    data.id = firstTable.length + 1;
    // data.tanggalMasuk = tanggalMasuk;
    data.id_dataObat = id_dataObat;
    data.nama = namaObat;
    data.id_Petugas = uid;
    data.sumber = sumberOk;
    data.sumberlabel = sumber[0].label;
    data.noRegistrasi = noRegistrasi;
    data.penyedia = penyedia[0].value;
    data.ed = formatDateDB(ed);
    data.tanggalMasuk = formatDateDB(tanggalMasuk);
    data.batch = batch;
    data.pbfPenyalur = pbfPenyalur;
    data.noFaktur = noFaktur;
    data.tahunPengadaan = tahunPengadaanOk;
    data.harga = harga;
    data.jumlahMasuk = jumlahMasuk;
    data.subSumber = subSumber[0].value;
    data.subSumberLabel = subSumber[0].label;

    setFirstTable((prevArray) => [...prevArray, data]);

    onModalClose();
  };

  const onModalClose = () => {
    setId_dataObat("");
    setId_Petugas("");

    setNoRegistrasi("");
    setPenyedia("");
    setBatch("");
    setPbfPenyalur("");
    setSatuan("");
    setSubSumber("");

    setHarga("");
    setJumlahMasuk("");
    setSatuan("");
    setNamaObat("");

    setEd("");
    setEdit(false);
    setIdEdit();

    setModalOpen(false);
  };

  const openEdit = (item) => {
    setEdit(true);
    setIdEdit(item.id);

    setNamaObat(item.nama);
    setId_dataObat(item.id_dataObat);
    setId_Petugas(item.id_Petugas);
    setSumber([
      { value: item.sumber, label: item.sumberlabel, color: "primary" },
    ]);

    setSumberOk(item.sumber);

    handleSumberChange(item.sumber);

    const ls = listSubSumber.filter((itemKu) => {
      return itemKu.id == item.subSumber;
    });

    setSubSumber([{ value: ls[0].id, label: ls[0].subsumber }]);

    setNoRegistrasi(item.noRegistrasi);
    setPenyedia([
      { value: item.penyedia, label: item.penyedia, color: "primary" },
    ]);
    setBatch(item.batch);
    setPbfPenyalur(item.pbfPenyalur);

    setTahunPengadaan([
      {
        value: item.tahunPengadaan,
        label: item.tahunPengadaan,
        color: "primary",
      },
    ]);
    setTahunPengadaanOk(item.tahunPengadaan);
    setHarga(item.harga);
    setJumlahMasuk(item.jumlahMasuk);
    setSatuan("");

    setEd(new Date(item.ed));
    setTanggalMasuk(new Date(item.tanggalMasuk));

    setModalOpen(true);
  };

  const formatDateDB = (date) => {
    const tgl = new Date(date);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;
    return date_string;
  };

  const handleEditList = () => {
    if (!idEdit) {
      return;
    }

    const data = firstTable;
    setFirstTable([]);
    const index = data.findIndex((item) => item.id === idEdit);

    if (index !== -1) {
      data[index].id_dataObat = id_dataObat;
      data[index].nama = namaObat;
      data[index].id_Petugas = uid;
      data[index].sumber = sumberOk;
      data[index].noRegistrasi = noRegistrasi;
      data[index].penyedia = penyedia[0].value;
      data[index].ed = formatDateDB(ed);
      data[index].batch = batch;
      data[index].pbfPenyalur = pbfPenyalur;
      data[index].noFaktur = noFaktur;
      data[index].tahunPengadaan = tahunPengadaanOk;
      data[index].harga = harga;
      data[index].jumlahMasuk = jumlahMasuk;
      data[index].tanggalMasuk = formatDateDB(tanggalMasuk);

      data[index].subSumber = subSumber[0].value;
      data[index].subSumberLabel = subSumber[0].label;
    }

    setFirstTable(data);
    onModalClose();
  };

  const searchItems = (e) => {
    const src = e.target.value;
    if (src == "") {
      setListTampil(list);
    } else {
      const filteredItems = list.filter((item) =>
        item.nama.toUpperCase().includes(src.toUpperCase())
      );
      setListTampil(filteredItems);
    }
  };

  const handleSimpanDB = () => {
    const dataKu = firstTable;

    const dataOk = dataKu.map((item) => {
      return [
        item.id_dataObat,
        item.id_Petugas,
        item.sumber,
        item.noRegistrasi,
        item.penyedia,
        item.ed,
        item.batch,
        item.pbfPenyalur,
        item.noFaktur,
        item.tahunPengadaan,
        item.harga,
        item.jumlahMasuk,
        item.tanggalMasuk,
        item.subSumber,
      ];
    });

    axios({
      method: "post",
      url: MASUK_GUDANG + "input/items",
      timeout: API_TIMEOUT,
      data: dataOk,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          setProsesSimpan(false);
          setFirstTable([]);
          setTanggalMasuk("");
          ambilDataObat();
          setSumber("");
          setSumberOk("");
          setTahunPengadaan("");
          setTahunPengadaanOk("");
          swal("Success", "Data berhasil disimpan", "success");
        }
      })
      .catch((error) => {
        // ERROR
        setProsesSimpan(false);
        swal("Gagal", error, "error");
      });
  };

  const handleDelete = (id) => {
    const data = firstTable.filter((item) => {
      return item.id != id;
    });

    setFirstTable(data);
  };

  const handleSumberChange = (id) => {
    const listsubsum = listSubSumber.filter((item) => {
      return item.sumber == id;
    });

    const listOk = listsubsum.map((item) => {
      return { value: item.id, label: item.subsumber };
    });

    setSelectSubSUmber(listOk);
  };

  return (
    <div>
      <Row>
        <Col>
          <FormGroup>
            <CustomInput
              type="switch"
              id="allDay"
              name="customSwitch"
              label="Tampilkan Dafar Obat"
              checked={tampilDaftarObat}
              onChange={(e) => setTampilDaftarObat(e.target.checked)}
              inline
            />
          </FormGroup>
          <Row className="gutter mb-4">
            {tampilDaftarObat && (
              <Col xs={12} md={firstTable.length > 0 ? 4 : 6}>
                <Widget className="widget-p-md">
                  <div className="headline-2">Daftar data obat</div>
                  <div className="mt-2 mb-3 body-3 muted">
                    Lakukan pencarian data obat
                  </div>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search-input1"
                        onChange={(e) => searchItems(e)}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="default">Search</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <div style={containerStyle}>
                    <ul className="list-group">
                      {listTampil ? (
                        listTampil.map((item) => (
                          <li className="list-group-item" key={item.id}>
                            <div className={s.listBadge}>
                              {item.nama}{" "}
                              {item.stok_ready > 0 && (
                                <span style={{ color: "green", fontSize: 12 }}>
                                  ready stock{" "}
                                  {item.stok_ready + " " + item.satuan}
                                </span>
                              )}
                              <Badge
                                color="primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (!tanggalMasuk) {
                                    swal(
                                      "Info",
                                      "Anda belum memilih tanggal Masuk obat",
                                      "info"
                                    );
                                    return;
                                  }

                                  selectAction(item);
                                }}
                              >
                                <i className="fa fa-plus mr-2" />
                              </Badge>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div />
                      )}
                    </ul>
                  </div>
                </Widget>
              </Col>
            )}
            <Col
              xs={12}
              md={!tampilDaftarObat ? 12 : firstTable.length > 0 ? 8 : 6}
              className="mt-4 mt-md-0"
            >
              <Widget className="widget-p-md">
                <div className="headline-2">Daftar obat Masuk</div>
                <Row>
                  <Col>
                    <Label>
                      <span>No </span>
                      <code>{noFaktur}</code>
                    </Label>
                    <FormGroup>
                      <Label for="label">Sumber</Label>
                      <Select
                        id="label"
                        value={sumber}
                        options={listSumber}
                        className="react-select"
                        classNamePrefix="select"
                        isClearable={false}
                        onChange={(data) => {
                          if (firstTable.length == 0) {
                            setSumber([data]);
                            setSumberOk(data.value);
                            handleSumberChange(data.value);
                          } else {
                            swal(
                              "Info",
                              "Perubahan Sumber tidak bisa dilaksanakan apabila data tabel obat masuk sudah terisi",
                              "info"
                            );
                          }
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="tahun">Tahun Pengadaan</Label>
                      <Select
                        id="tahun"
                        value={tahunPengadaan}
                        options={tahunKu}
                        className="react-select"
                        classNamePrefix="select"
                        isClearable={false}
                        onChange={(data) => {
                          if (firstTable.length == 0) {
                            setTahunPengadaan([data]);
                            setTahunPengadaanOk(data.value);
                          } else {
                            swal(
                              "Info",
                              "Perubahan Tahun Pengadaan tidak bisa dilaksanakan apabila data tabel obat masuk sudah terisi",
                              "info"
                            );
                          }
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="endDate">Tanggal Masuk</Label>
                      <Flatpickr
                        id="endDate"
                        tag={Flatpickr}
                        value={tanggalMasuk}
                        name="endDate"
                        className="form-control"
                        onChange={(date) => {
                          if (firstTable.length == 0) {
                            setTanggalMasuk(date[0]);
                            setNoFaktur(generateInvoiceNumber(1));
                          } else {
                            swal(
                              "Info",
                              "Perubahan tanggal tidak bisa dilaksanakan apabila data tabel obat masuk sudah terisi",
                              "info"
                            );
                          }
                        }}
                        options={{
                          enableTime: allDay === false,
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className="checkbox checkbox-primary">
                            <input
                              className="styled"
                              id="checkbox100"
                              type="checkbox"
                            />
                            <label for="checkbox100" />
                          </div>
                        </th>
                        <th className="w-35">Obat</th>
                        <th className="w-25">Sumber</th>
                        <th className="w-25">Sub Sumber</th>
                        <th className="w-25">No Registrasi</th>
                        <th className="w-25">Expired Date</th>
                        <th className="w-25">Batch</th>

                        <th className="w-25">PBF Penyalur</th>
                        <th className="w-25">Nomor Faktur</th>
                        <th className="w-25">Tahun Pengadaan</th>
                        <th className="w-25">Jumlah</th>
                        <th className="w-25">Harga</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id={item.nama}
                                  className="styled"
                                  type="checkbox"
                                />
                                <Label for={item.nama} />
                              </div>
                            </td>
                            <td className="d-flex align-items-center">
                              <span className="ml-3">{item.nama}</span>
                            </td>

                            <td>{item.sumberlabel}</td>
                            <td>{item.subSumberLabel}</td>
                            <td>{item.noRegistrasi}</td>
                            <td>{item.ed}</td>

                            <td>{item.batch}</td>

                            <td>{item.pbfPenyalur}</td>
                            <td>{item.noFaktur}</td>
                            <td>{item.tahunPengadaan}</td>
                            <td>{numberWithCommas(item.jumlahMasuk)}</td>
                            <td>{numberWithCommas(item.harga)}</td>
                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="primary"
                                size="xs"
                                onClick={() => {
                                  openEdit(item);
                                }}
                              >
                                edit
                              </Button>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="danger"
                                size="xs"
                                onClick={() => handleDelete(item.id)}
                              >
                                delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
                {prosesSimpan ? (
                  <Button>
                    <Spinner size="sm" /> Simpan...
                  </Button>
                ) : (
                  <Button color="primary" onClick={handleSimpan}>
                    Simpan
                  </Button>
                )}
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal isOpen={""}>
        <ModalBody>
          <FormGroup>
            <Label for="bar">{selected && selected.asupan}</Label>
            <Row>
              <Col md={8}>
                <InputGroup>
                  <Input type="text" id="bar" value={jumlah} />
                  <InputGroupAddon addonType="append">
                    <ButtonGroup>
                      <Button
                        color="secondary-red"
                        className="px-3 border-radius-0"
                        onClick={() => setJumlah(() => jumlah - 1)}
                      >
                        <i className={`fa fa-minus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color="secondary-yellow"
                        className="px-3"
                        onClick={() => setJumlah(() => jumlah + 1)}
                      >
                        <i className={`fa fa-plus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color="success"
                        className="px-3"
                        onClick={() => setJumlah(0)}
                      >
                        <i className={`fa fa-refresh ${s.btnIcon}`} />
                      </Button>
                    </ButtonGroup>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={4}>
                <Input
                  type="text"
                  id="disabled-input"
                  disabled="disabled"
                  value={selected && selected.ukuran}
                />
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <div className="mx-auto">
            <Button
              className="btn-rounded btn-outline-secondary mr-3"
              outline
              onClick={() => {
                setModalOpen(false);
                setSelected(null);
                setJumlah(0);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-rounded"
              color="primary"
              onClick={() => handleSimpan()}
            >
              Simpan
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalOpen}
        toggle={() => onModalClose()}
        className="sidebar-lg"
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader className="mb-1" tag="div" close={CloseBtn}>
          <h5 className="modal-title">{"Add"}</h5>
        </ModalHeader>
        <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="namaobat">
                Nama Obat <span className="text-danger">*</span>
              </Label>
              <Input
                id="namaobat"
                name="namaobat"
                placeholder="nama obat"
                value={namaObat}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="subsumber">Sub Sumber</Label>
              <Select
                id="subsumber"
                value={subSumber}
                options={selectSubSumber}
                className="react-select"
                classNamePrefix="select"
                isClearable={false}
                onChange={(data) => {
                  setSubSumber([data]);
                }}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="endDate">Expired Date</Label>
              <Flatpickr
                id="endDate"
                tag={Flatpickr}
                name="endDate"
                className="form-control"
                onChange={(date) => {
                  setEd(date[0]);
                }}
                value={ed}
                options={{
                  enableTime: allDay === false,
                  dateFormat: "d-m-Y",
                }}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="noreg">Nomor Registrasi</Label>
              <Input
                id="noreg"
                value={noRegistrasi}
                onChange={(e) => setNoRegistrasi(e.target.value)}
                placeholder="no registrasi"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="penyedia">Penyedia</Label>
              <Select
                id="penyedia"
                value={penyedia}
                options={penyediaKu}
                className="react-select"
                classNamePrefix="select"
                isClearable={false}
                onChange={(data) => {
                  setPenyedia([data]);
                }}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="jml">Jumlah</Label>
              <Input
                id="jml"
                type="number"
                value={jumlahMasuk}
                placeholder="jumlah"
                onChange={(e) => setJumlahMasuk(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="harga">Harga</Label>
              <Input
                id="harga"
                type="number"
                value={harga}
                onChange={(e) => setHarga(e.target.value)}
                placeholder="harga"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="batch">Batch</Label>
              <Input
                id="batch"
                value={batch}
                onChange={(e) => setBatch(e.target.value)}
                placeholder="batch"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="pbf">PBF Penyalur</Label>
              <Input
                id="pbf"
                value={pbfPenyalur}
                onChange={(e) => setPbfPenyalur(e.target.value)}
                placeholder="pbf penyalur"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="nofaktur">Nomor Faktur</Label>
              <Input
                id="nofaktur"
                value={noFaktur}
                placeholder="no faktur"
                required
              />
            </FormGroup>
            <FormGroup>
              <Fragment>
                <Button
                  className="mr-3 btn-rounded"
                  type="submit"
                  color="primary"
                >
                  {edit ? "Edit" : "Add"}
                </Button>
                <Button
                  className="btn-rounded"
                  color="secondary"
                  type="reset"
                  onClick={() => onModalClose()}
                  outline
                >
                  Cancel
                </Button>
              </Fragment>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

import React from "react";
import { Badge, Col, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Widget from "../../../components/Widget/Widget.js";
import {
  Form,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  Progress,
  CustomInput,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";

import Flatpickr from "react-flatpickr";

import { numberWithCommas } from "../../../utils/func.js";

import DatePicker from "react-datepicker";
import s from "./Lists.module.scss";

import { useState, useEffect, Fragment } from "react";
import axios from "axios";

import {
  API_TIMEOUT,
  LOKASI,
  PERMINTAAN,
  FAKTUR_MASUK,
  KELUAR_MASUK,
  MASUK,
  KELUAR_MASUK_UNIT,
  MASUK_UNIT,
  STOCK_UNIT_TERSEDIA,
  STOCK_PUSKESMAS_TERSEDIA,
} from "../../../utils/index.js";

import swal from "sweetalert";
import { logApp } from "../../../utils/func.js";

export default function MasukStockPuskes() {
  const [list, setList] = useState([]);
  const [listTampil, setListTampil] = useState([]);

  const [allDay, setAllDay] = useState(false);

  const [firstTable, setFirstTable] = useState([]);
  const [secondTable, setSecondTable] = useState([]);

  const [stok, setStok] = useState(0);

  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tampilDaftarObat, setTampilDaftarObat] = useState(true);
  const [modalPermintaan, setModalPermintaan] = useState(false);
  const [idFaktur, setIdFaktur] = useState("");

  const [prosesSimpan, setProsesSimpan] = useState(false);

  const [modalOpen2, setModalOpen2] = useState(false);
  const [infoSelected, setInfoSelected] = useState();

  const [level, setLevel] = useState("");
  const [puskesmas, setPuskesmas] = useState("");
  const [unit, setUnit] = useState("");
  const [catatan, setCatatan] = useState("");

  const [edit, setEdit] = useState(false);

  const [lokasi, setLokasi] = useState("");

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const pageSize = 10;
  const pageSize2 = 10;

  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const closeModal = () => {
    setModalPermintaan(false);
  };

  let kategoriKu = [];

  if (kategoriKu.length == 1) kategoriKu = kategoriKu[0];
  if (kategoriKu.length == 2) kategoriKu = kategoriKu[0] + " " + kategoriKu[1];

  const containerStyle = {
    width: "100%",
    height: "300px", // Atur tinggi sesuai kebutuhan Anda
    overflow: "auto",
    /* Gaya tampilan scroll */
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
  };

  const radialChartStyle = {
    top: "50%",
    left: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px",
  };

  const [selected, setSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [jumlah, setJumlah] = useState(0);

  const [uid, setUid] = useState();
  const [user, setUser] = useState();

  const [id_dataObat, setId_dataObat] = useState("");

  const [totalMasuk, setTotalMasuk] = useState("");
  const [totalTerima, setTotalTerima] = useState(0);
  const [satuan, setSatuan] = useState("");
  const [namaObat, setNamaObat] = useState("");
  const [idEdit, setIdEdit] = useState();

  const [id_Masuk, setId_Masuk] = useState("");
  const [id_tujuan_Keluar, setId_Tujuan_Keluar] = useState("");
  const [id_petugasKeluar, setId_petugasKeluar] = useState("");
  const [tanggalMasuk, setTanggalMasuk] = useState("");
  const [stokError, setStokError] = useState("");
  const [permintaan, setPermintaan] = useState(false);
  const [jumlahFakturMinta, setJumlahFakturMinta] = useState(0);

  const [idLokasi, setIdLokasi] = useState("");

  const CloseBtn = (
    <i
      className="eva eva-close cursor-pointer"
      onClick={() => onModalClose()}
    />
  );

  function mergeRows(dataArray) {
    return dataArray.reduce((accumulator, currentItem) => {
      const existingItem = accumulator.find(
        (item) => item.id_dataObat === currentItem.id_dataObat
      );

      if (existingItem) {
        existingItem.list_masuk.push({
          id_masuk: currentItem.id_masuk,
          id_masukGudang: currentItem.id_masukGudang,
          tanggalMasuk: formatDate(currentItem.tanggalMasuk),
          ed: formatDate(currentItem.ed),
          sumber: currentItem.sumber,
          penyedia: currentItem.penyedia,
          total_masuk: currentItem.total_masuk,
          total_keluar: currentItem.total_keluar,
          stok_ready: currentItem.stok_ready,
          noFaktur: currentItem.noFaktur,
          harga: currentItem.harga,
          tahunPengadaan: currentItem.tahunPengadaan,
        });
        existingItem.stok_ready += currentItem.stok_ready;
      } else {
        accumulator.push({
          id_dataObat: currentItem.id_dataObat,
          nama: currentItem.nama,
          satuan: currentItem.satuan,
          list_masuk: [
            {
              id_masuk: currentItem.id_masuk,
              id_masukGudang: currentItem.id_masukGudang,
              tanggalMasuk: formatDate(currentItem.tanggalMasuk),
              ed: formatDate(currentItem.ed),
              sumber: currentItem.sumber,
              penyedia: currentItem.penyedia,
              total_masuk: currentItem.total_masuk,
              total_keluar: currentItem.total_keluar,
              stok_ready: currentItem.stok_ready,
              noFaktur: currentItem.noFaktur,
              harga: currentItem.harga,
              tahunPengadaan: currentItem.tahunPengadaan,
            },
          ],
          stok_ready: currentItem.stok_ready,
        });
      }

      return accumulator;
    }, []);
  }

  function generateInvoiceNumber(lok) {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    const invoiceNumber =
      `INV${year}${month}${day}${hours}${minutes}${seconds}` +
      "1" +
      idLokasi +
      "mas";
    return invoiceNumber;
  }

  const [noFaktur, setNoFaktur] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setUser(user);
      setUid(user.uid);
      setIdLokasi(user.idLokasi);
      logApp(user.uid, "Stock Masuk");
    }
  }, []);

  useEffect(() => {
    if (idLokasi) {
      ambilDataLokasi();
      ambilJumlahFakturMinta();
    }
  }, [idLokasi]);

  useEffect(() => {
    if (level && idLokasi) {
      ambilDataObat();
    }
  }, [level, idLokasi]);

  const selectInfoAction = (item) => {
    setModalOpen2(true);
    setInfoSelected(item);
  };

  const ambilDataObat = () => {
    axios({
      method: "get",
      url:
        level == "Puskesmas"
          ? STOCK_PUSKESMAS_TERSEDIA + idLokasi
          : STOCK_UNIT_TERSEDIA + idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const data = mergeRows(response.data.data);

          setList(data);
          setListTampil(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilJumlahFakturMinta = () => {
    axios({
      method: "get",
      url: FAKTUR_MASUK + "ambil/" + idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          setJumlahFakturMinta(response.data.data.length);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilFakturMasuk = () => {
    axios({
      method: "get",
      url: FAKTUR_MASUK + "ambil/" + idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          setSecondTable(response.data.data);

          setModalPermintaan(true);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  //masuk list stok keluar dari permintaan

  const getFakturPermintaan = () => {
    if (!tanggalMasuk) {
      swal("Info", "Tanggal masuk harus ditentukan terlebih dahulu", "info");
      return;
    }
    ambilFakturMasuk();
    setPermintaan(true);
  };

  const [fakturPermintaan, setFakturPermintaan] = useState([]);
  const [tabelSementara, setTableSementara] = useState([]);

  useEffect(() => {
    if (tabelSementara.length > 0) {
      const data = tabelSementara;

      let date_string = "";
      if (tanggalMasuk) {
        const tgl = new Date(tanggalMasuk);
        const year = tgl.getFullYear();
        const month = String(tgl.getMonth() + 1).padStart(2, "0");
        const day = String(tgl.getDate()).padStart(2, "0");
        date_string = `${year}-${month}-${day}`;
      }
      for (let i = 0; i < data.length; i++) {
        let id = { id: i + 1 };
        let namaObat = { namaObat: data[i].nama };
        let tanggalMasuk = { tanggalMasuk: date_string };

        let totalMinta = 0;

        // pada table keluar gudang, id_Masuk adalah id_masukgudang, sengan tabel keluar berbeda
        let id_masukGudang = {
          id_masukGudang: data[i].id_Masuk
            ? data[i].id_Masuk
            : data[i].id_masukGudang,
        };

        // perlu mencatat id keluar gudang

        const dataobat = fakturPermintaan.filter((itemKu) => {
          return data[i].id_dataObat == itemKu.id_dataObat;
        });

        if (dataobat.length > 0) {
          totalMinta = dataobat[0].jumlahMinta;
        }
        let totalPermintaan = { totalMinta: totalMinta };
        let id_petugasMasuk = { id_petugasMasuk: uid };

        let id_LokasiMasuk = { id_LokasiMasuk: idLokasi };
        let terima = { terima: true };
        let jumlahTerima = { jumlahTerima: 0 };
        let catatan = { catatan: "" };

        data[i] = {
          ...data[i],

          ...id,
          ...namaObat,
          ...tanggalMasuk,
          ...id_masukGudang,
          ...totalPermintaan,
          ...id_LokasiMasuk,
          ...id_petugasMasuk,
          ...terima,
          ...jumlahTerima,
          ...catatan,
        };
      }

      setFirstTable(data);
      setModalPermintaan(false);
      setTampilDaftarObat(false);
    }
  }, [tabelSementara]);

  const getFakturMinta = (noFaktur) => {
    if (!noFaktur) {
      setFakturPermintaan([]);
      return;
    }
    axios({
      method: "get",
      url: PERMINTAAN + noFaktur,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setFakturPermintaan(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  //jika levelnya puskemas
  const getMasuk = (item) => {
    setIdFaktur(item.id);

    axios({
      method: "get",
      url: KELUAR_MASUK + item.noFaktur,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setTableSementara(response.data.data);

          //menyesuakian kolom dari permintaan sesuai dengan input dari list

          const nomorFak = generateInvoiceNumber(idLokasi);
          setNoFaktur(nomorFak);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  //jika levelnya ada pada unit - unit di bawah puskesmas
  const getMasuk2 = (item) => {
    setIdFaktur(item.id);

    axios({
      method: "get",
      url: KELUAR_MASUK_UNIT + item.noFaktur,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setTableSementara(response.data.data);

          const nomorFak = generateInvoiceNumber(idLokasi);
          setNoFaktur(nomorFak);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilDataLokasi = () => {
    axios({
      method: "get",
      url: LOKASI + "ambil/",
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          if (data.length > 0) {
            const fil = data.filter((item) => {
              return idLokasi == item.id;
            });

            const level = fil[0].level;

            if (level == "Puskesmas") {
              setPuskesmas(fil[0].lokasi);
              setUnit("");

              const datafilter = data.filter((item) => {
                return item.id_Puskesmas == fil[0].id;
              });
              let lok = [];
              for (var i = 1; i < datafilter.length; i++) {
                lok.push({
                  value: datafilter[i].id,
                  label: datafilter[i].lokasi,
                  color: "primary",
                });
              }

              setLokasi(lok);
            } else {
              const datafilter = data.filter((item) => {
                return item.id == fil[0].id_Puskesmas;
              });

              setUnit(fil[0].lokasi);

              setPuskesmas(datafilter[0].lokasi);

              setLokasi([
                {
                  value: 74,
                  label: "Pasien",
                  color: "primary",
                },
              ]);
            }
            setLevel(level);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const validateStok = (value) => {
    setTotalMasuk(value);

    setStokError("");
  };

  //menambahkan list ke dalam table first table dari list data obat
  const selectAction = (item) => {
    if (!tanggalMasuk || !idLokasi) {
      swal(
        "Info",
        "Tanggal pengeluaran dan Tujuan obat keluar harus diisi terlebih dahulu",
        "info"
      );
      return;
    }
    setSelected(item);
    setNamaObat(item.nama);
    setId_dataObat(item.id_dataObat);
    setId_Masuk(item.id);
    setStok(item.stok);
    setSatuan(item.satuan);
    setModalOpen(true);
  };

  const handleSimpan = () => {
    if (firstTable.length < 1) {
      swal("Info", "Anda belum memasukan data obat", "info");
    } else {
      setProsesSimpan(true);
      handleSimpanDB();
    }
  };

  const formatDate = (tglKu) => {
    const tgl = new Date(tglKu);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${day}-${month}-${year}`;
    return date_string;
  };
  const formatDateDB = (tglKu) => {
    const tgl = new Date(tglKu);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;
    return date_string;
  };

  //masuk list stok keluar dari list Daftar Obat
  const handleSubmit = (e) => {
    e.preventDefault();

    if (edit) {
      handleEditList();
      return;
    }

    const data = {};

    const tgl = new Date(tanggalMasuk);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    data.id = firstTable.length + 1;
    data.id_dataObat = id_dataObat;
    data.nama = namaObat;
    data.id_Masuk = id_Masuk;
    data.satuan = satuan;
    data.id_petugasKeluar = uid;
    data.stok = stok;
    data.ket = "listDO";

    data.id_tujuan_Keluar = id_tujuan_Keluar[0].value;
    data.lokasi = id_tujuan_Keluar[0].label;
    data.tanggalKeluar = date_string;

    data.catatan = catatan;
    data.noFaktur = noFaktur;
    data.jumlahMinta = 0;
    data.totalMasuk = totalMasuk;

    setFirstTable((prevArray) => [...prevArray, data]);

    onModalClose();
  };

  const onModalClose = () => {
    setId_dataObat("");
    setId_petugasKeluar("");
    setTotalMasuk("");
    setId_Masuk("");
    setSatuan("");
    setNamaObat("");
    setStok(0);
    setStokError("");
    setEdit(false);
    setIdEdit();
    setCatatan("");
    setTotalTerima(0);

    setModalOpen(false);
  };

  const openEdit = (item) => {
    setEdit(true);
    setIdEdit(item.id);

    setNamaObat(item.nama);
    setId_dataObat(item.id_dataObat);
    setId_Masuk(item.id_Masuk);
    setSatuan(item.satuan);
    setCatatan(item.catatan);

    setTotalMasuk(item.jumlahMasuk);
    setTotalTerima(item.jumlahTerima ? item.jumlahTerima : 0);

    setModalOpen(true);
  };

  const handleEditList = () => {
    if (!idEdit) {
      return;
    }

    if (!tanggalMasuk) {
      swal("Gagal", "Tanggal Masuk belum ditentukan", "error");
      return;
    }

    const data = firstTable;
    setFirstTable([]);
    const index = data.findIndex((item) => item.id === idEdit);

    if (index !== -1) {
      data[index].catatan = catatan;
      data[index].jumlahTerima = totalTerima;
    }

    setFirstTable(data);
    onModalClose();
  };

  const searchItems = (e) => {
    const src = e.target.value;
    if (src == "") {
      setListTampil(list);
    } else {
      const filteredItems = list.filter((item) =>
        item.nama.toUpperCase().includes(src.toUpperCase())
      );
      setListTampil(filteredItems);
    }
  };

  const handleSimpanDB = () => {
    const dataKu = firstTable;

    let status = 1;

    const dataObatDiterima = dataKu.filter((item) => {
      return item.jumlahTerima > 0;
    });

    if (dataObatDiterima.length < 1) {
      swal(
        "Gagal",
        "Tidak ada satu jenis obat pun yang diterima. Tidak dapat menyimpan",
        "error"
      );
      return;
    }

    //hasil verifikasi terhadap obat masuk
    const obatTidakSesuai = dataKu.filter((item) => {
      return item.catatan != "" || item.jumlahMasuk != item.jumlahTerima;
    });

    if (obatTidakSesuai.length > 0) {
      status = 2;
    }

    const dataFaktur = {
      id_petugas2: uid,
      tanggalRes: formatDateDB(tanggalMasuk),
      id: idFaktur,
      status: status,
    };

    //ini untuk disimpan pada tabel masuk
    const dataOk = dataObatDiterima.map((item) => {
      return [
        item.id_keluar,
        item.jumlahTerima,
        item.tanggalMasuk,
        noFaktur,
        idLokasi,
        item.id_masukGudang,
        item.id_dataObat,
        uid,
      ];
    });

    const dataObatEditKeluar = dataKu.map((item) => {
      return {
        id: item.id_keluar,
        jumlahTerima: item.jumlahTerima,
        tanggalMasuk: item.tanggalMasuk,
        id_petugasMasuk: uid,
        catatan: item.catatan,
      };
    });

    //penggunaan nama dataOatTidakSesuai sebenarnya merujuk pada nama dataObatEditKeluar
    const dataKita = {
      data: dataOk,
      dataFaktur: dataFaktur,
      dataObatTidakSesuai: dataObatEditKeluar,
    };

    axios({
      method: "post",
      url:
        level == "Puskesmas"
          ? MASUK + "input/items"
          : MASUK_UNIT + "input/items",
      timeout: API_TIMEOUT,
      data: dataKita,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setFirstTable([]);
          setFakturPermintaan([]);

          setTanggalMasuk("");
          setTableSementara([]);

          setId_Tujuan_Keluar("");
          setTampilDaftarObat(true);
          setNoFaktur("");
          setPermintaan(false);
          setIdFaktur("");
          ambilJumlahFakturMinta();
          ambilDataObat();
          setCatatan("");
          setProsesSimpan(false);
          swal("Success", "Data berhasil disimpan", "success");
        }
      })
      .catch((error) => {
        // ERROR
        setProsesSimpan(false);
        swal("Gagal", "Data gagal tersimpan", "error");
      });
  };

  const handleDelete = (id) => {
    const data = firstTable.filter((item) => {
      return item.id != id;
    });

    setFirstTable(data);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row>
            <Col>
              <FormGroup>
                <CustomInput
                  type="switch"
                  id="allDay"
                  name="customSwitch"
                  label="Tampilkan Dafar Obat"
                  checked={tampilDaftarObat}
                  onChange={(e) => setTampilDaftarObat(e.target.checked)}
                  inline
                />
              </FormGroup>
            </Col>
            <FormGroup style={{ position: "relative" }}>
              <Button color="primary" onClick={() => getFakturPermintaan()}>
                Masuk
              </Button>
              {jumlahFakturMinta ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#ff5252",
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 5,
                    borderRadius: "50%",
                    right: 4,
                    top: -15,
                    width: 27,
                    height: 27,
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => getFakturPermintaan()}
                >
                  <text style={{ fontSize: 12 }}> {jumlahFakturMinta}</text>
                </div>
              ) : (
                <div />
              )}
            </FormGroup>
          </Row>
          <Row className="gutter mb-4">
            {tampilDaftarObat && (
              <Col xs={12} md={firstTable.length > 0 ? 4 : 6}>
                <Widget className="widget-p-md">
                  <div className="headline-2">
                    {level == "Puskesmas" ? (
                      <div>
                        Daftar obat tersedia # Puskesmas
                        <code>{puskesmas}</code>
                      </div>
                    ) : (
                      <div>
                        Daftar obat tersedia #<code>{unit}</code>
                        <div>
                          Puskesmas <code>{puskesmas}</code>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-2 mb-3 body-3 muted">
                    Lakukan pencarian data obat tersedia
                  </div>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search-input1"
                        onChange={(e) => searchItems(e)}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="default">Search</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <div style={containerStyle}>
                    <ul className="list-group">
                      {listTampil ? (
                        listTampil.map((item) => (
                          <li className="list-group-item" key={item.id}>
                            <div className={s.listBadge}>
                              <div>
                                {item.nama} <code>{item.stok_ready}</code>{" "}
                                {item.satuan}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Badge
                                  color="warning"
                                  style={{
                                    cursor: "pointer",
                                    position: "relative",
                                  }}
                                  onClick={() => selectInfoAction(item)}
                                >
                                  {item.list_masuk.length > 1 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "#24ae24",
                                        position: "absolute",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 5,
                                        borderRadius: "50%",
                                        left: -10,
                                        top: -10,
                                        width: 17,
                                        height: 17,
                                        color: "white",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => ""}
                                    >
                                      <text style={{ fontSize: 8 }}>
                                        {" "}
                                        {item.list_masuk.length}
                                      </text>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                  <i className="fa fa-info mr-2" />
                                </Badge>
                                <div style={{ marginLeft: 5 }}></div>
                                {/* <Badge
                                  color='primary'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => selectAction(item)}
                                >
                                  <i className='fa fa-plus mr-2' />
                                </Badge> */}
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div />
                      )}
                    </ul>
                  </div>
                </Widget>
              </Col>
            )}
            <Col
              xs={12}
              md={!tampilDaftarObat ? 12 : firstTable.length > 0 ? 8 : 6}
              className="mt-4 mt-md-0"
            >
              <Widget className="widget-p-md">
                <div className="headline-2">Daftar obat Masuk</div>
                <Row>
                  <Col>
                    <Label>
                      <span>No </span>
                      <code>{noFaktur}</code>
                    </Label>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="endDate">Tanggal Masuk</Label>
                      <Flatpickr
                        id="endDate"
                        tag={Flatpickr}
                        name="endDate"
                        className="form-control"
                        onChange={(date) => {
                          if (firstTable.length == 0) {
                            setTanggalMasuk(date[0]);
                            setNoFaktur(generateInvoiceNumber(idLokasi));
                          } else {
                            swal(
                              "Info",
                              "Perubahan tanggal tidak bisa dilaksanakan apabila data tabel obat masuk sudah terisi",
                              "info"
                            );
                          }
                        }}
                        value={tanggalMasuk}
                        options={{
                          enableTime: allDay === false,
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className="checkbox checkbox-primary">
                            <input
                              className="styled"
                              id="checkbox100"
                              type="checkbox"
                            />
                            <label for="checkbox100" />
                          </div>
                        </th>
                        <th className="w-25">Obat</th>
                        <th className="w-25">Tujuan</th>

                        <th className="w-25">Jumlah permintaan</th>
                        <th className="w-25">Jumlah dikirim</th>
                        <th className="w-25">Jumlah diterima</th>
                        <th className="w-25">Tanggal Keluar</th>
                        <th className="w-25">Akomodir</th>
                        <th>Catatan</th>
                        <th className="w-25">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item, index) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id={item.nama}
                                  className="styled"
                                  type="checkbox"
                                  checked={firstTable[index].terima}
                                  onChange={(e) =>
                                    (firstTable[index].terima =
                                      e.target.checked)
                                  }
                                />
                                <Label for={item.nama} />
                              </div>
                            </td>
                            <td className="d-flex align-items-center">
                              <span className="ml-3">{item.nama}</span>
                            </td>

                            <td>{item.label ? item.label : item.lokasi}</td>
                            <td>{numberWithCommas(item.totalMinta)}</td>
                            <td>{numberWithCommas(item.jumlahMasuk)}</td>
                            <td>{numberWithCommas(item.jumlahTerima)}</td>

                            <td>{formatDate(item.tanggalKeluar)}</td>

                            <td>
                              {item.totalMinta == 0 ? (
                                <code> tidak diminta</code>
                              ) : (
                                <div
                                  className={`d-flex justify-content-between`}
                                >
                                  <div className="d-flex flex-column mt-3">
                                    <div className={s.activity}>
                                      <p className="body-2">
                                        {Math.round(
                                          (item.jumlahMasuk / item.totalMinta) *
                                            100
                                        )}
                                        % akomodir{" "}
                                      </p>
                                      <p className="body-2">
                                        {item.jumlahMasuk}
                                        <span className="body-3 muted">
                                          /{item.totalMinta}
                                        </span>
                                      </p>
                                    </div>
                                    <Progress
                                      color={
                                        (item.jumlahMasuk / item.totalMinta) *
                                          100 >
                                        50
                                          ? "success"
                                          : "danger"
                                      }
                                      className="progress-xs"
                                      value={
                                        (item.jumlahMasuk / item.totalMinta) *
                                        100
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>{item.catatan}</td>
                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="primary"
                                size="xs"
                                onClick={() => {
                                  openEdit(item);
                                }}
                              >
                                Terima
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
                {prosesSimpan ? (
                  <Button color="primary" disabled={true}>
                    <Spinner size="sm" color="light" />
                    Simpan...
                  </Button>
                ) : (
                  <Button color="primary" onClick={handleSimpan}>
                    Simpan
                  </Button>
                )}
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        isOpen={modalOpen}
        toggle={() => onModalClose()}
        className="sidebar-lg"
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader className="mb-1" tag="div" close={CloseBtn}>
          <h5 className="modal-title">{"Add"}</h5>
        </ModalHeader>
        <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="namaobat">
                Nama Obat <span className="text-danger">*</span>
              </Label>
              <Input
                id="namaobat"
                name="namaobat"
                placeholder="nama obat"
                value={namaObat}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="jml">
                <span className="label muted" style={{ color: "green" }}>
                  jumlah yang dikirim dalam <code> {satuan}</code>
                </span>
              </Label>

              <Input
                id="jml"
                type="number"
                value={totalMasuk}
                placeholder="jumlah"
              />
            </FormGroup>

            <FormGroup>
              <Label for="jml">
                <span
                  className="label muted"
                  style={{
                    color: totalMasuk != totalTerima ? "red" : "initial",
                  }}
                >
                  Total yang diterima dalam <code>{satuan}</code>
                </span>
              </Label>

              <Input
                id="jml"
                type="number"
                value={totalTerima}
                placeholder="jumlah"
                onChange={(e) => setTotalTerima(e.target.value)}
                style={{
                  borderColor: totalMasuk != totalTerima ? "red" : "initial",
                }}
              />
            </FormGroup>

            <FormGroup>
              <Label for="description">Catatan</Label>
              <Input
                type="textarea"
                name="text"
                id="description"
                rows="3"
                value={catatan}
                onChange={(e) => setCatatan(e.target.value)}
                placeholder="catatan"
              />
            </FormGroup>

            <FormGroup>
              <Label for="nofaktur">Nomor Faktur</Label>
              <Input
                id="nofaktur"
                value={noFaktur}
                placeholder="no faktur"
                required
              />
            </FormGroup>
            <FormGroup>
              <Fragment>
                <Button
                  className="mr-3 btn-rounded"
                  type="submit"
                  color="primary"
                >
                  {edit ? "Edit" : "Add"}
                </Button>
                <Button
                  className="btn-rounded"
                  color="secondary"
                  type="reset"
                  onClick={() => onModalClose(false)}
                  outline
                >
                  Cancel
                </Button>
              </Fragment>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={modalPermintaan} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          <div className={s.tableTitle}>
            <div className="headline-2">
              {"Permintaan"}
              <code> Tahun {"Obat"}</code>
            </div>
          </div>
        </ModalHeader>

        <Widget className="widget-p-md">
          <div className="widget-table-overflow">
            <Table
              id="dataBantuan"
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead>
                <tr>
                  <th className="w-15">No Faktur</th>
                  <th className="w-25">Asal</th>
                  <th className="w-25">Tanggal</th>
                  <th className="w-25">Jumlah</th>
                  <th className="w-25">Status</th>
                  <th className="w-25">No Faktur Permintaan</th>

                  <th className="w-25">Action</th>
                </tr>
              </thead>
              <tbody>
                {secondTable
                  .slice(
                    secondTableCurrentPage * pageSize2,
                    (secondTableCurrentPage + 1) * pageSize2
                  )
                  .map((item) => (
                    <tr key={uuidv4()}>
                      <td>{item.noFaktur}</td>
                      <td>{item.asal}</td>
                      <td>{formatDate(item.tanggal)}</td>

                      <td>{item.jumlah}</td>
                      <td>{item.status}</td>
                      <td>{item.noFakturPermintaan}</td>
                      <td>
                        <Button
                          size="xs"
                          color="primary"
                          onClick={() => {
                            getFakturMinta(item.noFakturPermintaan);

                            level == "Puskesmas"
                              ? getMasuk(item)
                              : getMasuk2(item);

                            // jika ada pada level unit mempergunakan getMasuk2
                          }}
                        >
                          Add
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              className="pagination-borderless"
              aria-label="Page navigation example"
            >
              <PaginationItem disabled={secondTableCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    setSecondTablePage(e, secondTableCurrentPage - 1)
                  }
                  previous
                  href="#top"
                />
              </PaginationItem>
              {[...Array(secondTablePagesCount)].map((page, i) => (
                <PaginationItem active={i === secondTableCurrentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => setSecondTablePage(e, i)}
                    href="#top"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={secondTableCurrentPage >= secondTablePagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    setFirstTablePage(e, secondTableCurrentPage + 1)
                  }
                  next
                  href="#top"
                />
              </PaginationItem>
            </Pagination>
          </div>
        </Widget>
      </Modal>
      <Modal isOpen={modalOpen2} toggle={() => setModalOpen2(false)}>
        <ModalBody>
          <Widget className="widget-p-md">
            {infoSelected && (
              <div>
                <Row>
                  <Col>Nama Obat</Col>
                  <Col>
                    <div style={{ color: "red" }}>{infoSelected.nama}</div>
                  </Col>
                </Row>

                <Row>
                  <Col>Satuan Terkecil</Col>
                  <Col>{infoSelected.satuan}</Col>
                </Row>

                <ul className="list-group">
                  {infoSelected.list_masuk.map((item, index) => (
                    <li className="list-group-item" key={index}>
                      <div className={s.listBadge}>
                        <div>
                          Tanggal masuk{" "}
                          <Button size="xs" color="secondary-red">
                            {item.tanggalMasuk}{" "}
                          </Button>{" "}
                          Stok Ready
                          <code>{item.stok_ready}</code> {infoSelected.satuan}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        ></div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Widget>
        </ModalBody>
        <ModalFooter>
          <div className="mx-auto">
            <Button
              className="btn-rounded"
              color="success"
              onClick={() => setModalOpen2(false)}
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

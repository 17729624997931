import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import "./table.css";

import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";

import TulufaliLogo from "../../components/Icons/tulufali_logo.png";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import s from "./Tables.module.scss";

import {
  API_TIMEOUT,
  DOMAIN_SIMOBAT,
  FAKTUR_PERMINTAAN,
  KELUAR_MASUK,
  KELUAR_MASUK_UNIT,
  LOKASI,
  NERACA,
  PERMINTAAN,
} from "../../utils/index.js";
import axios from "axios";

import { useDispatch } from "react-redux";

import Success from "../../assets/success.png";
import { formatDate, logApp, numberWithCommas } from "../../utils/func.js";
import Select, { components } from "react-select";
import swal from "sweetalert";
import { dateTime } from "../../utils/func.js";
import Stop from "../../assets/stop.png";

const TableResponPermintaan = function () {
  const [firstTable, setFirstTable] = useState([]);
  const [secondTable, setSecondTable] = useState([]);
  const [lokasi, setLokasi] = useState("");
  const [level, setLevel] = useState("");
  const [puskesmas, setPuskesmas] = useState("");
  const [unit, setUnit] = useState("");

  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);

  const [modalDelete, setModalDelete] = useState(false);

  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState("2023");
  const [bantuanModal, setBantuanModal] = useState("");
  const [tahun, setTahun] = useState("2023");

  const [fakturKeluar, setFakturKeluar] = useState("");

  const [currentUser, setCurrentUser] = useState();
  const [idLokasi, setIdLokasi] = useState("");

  const [tabelSementara, setTableSementara] = useState([]);
  const [fakturPermintaan, setFakturPermintaan] = useState([]);

  useEffect(() => {
    if (fakturPermintaan.length > 0) {
      getMasuk(fakturKeluar);
    }
  }, [fakturPermintaan]);

  const getMasuk = (faktur) => {
    axios({
      method: "get",
      url: (level == "Puskesmas" ? KELUAR_MASUK : KELUAR_MASUK_UNIT) + faktur,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setTableSementara(response.data.data);
          const tabelSementara = response.data.data;

          const data = fakturPermintaan;

          for (let i = 0; i < data.length; i++) {
            // pada table keluar gudang, id_Masuk adalah id_masukgudang, sengan tabel keluar berbeda

            // perlu mencatat id keluar gudang

            const dataobat = tabelSementara.filter((itemKu) => {
              return data[i].id_dataObat == itemKu.id_dataObat;
            });

            data[i] = {
              ...data[i],
              ...dataobat[0],
            };
          }
          console.log(data);
          setSecondTable(data);

          //menyesuakian kolom dari permintaan sesuai dengan input dari list
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const getFakturMinta = (noFaktur) => {
    console.log(noFaktur);
    axios({
      method: "get",
      url: PERMINTAAN + noFaktur,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          console.log(response.data.data);
          setFakturPermintaan(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleOpenModalPermintaan = (item) => {
    console.log(item);
    getFakturMinta(item.fakturMinta);
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, "Permintaan Response");
      setIdLokasi(currentUser.idLokasi);
    }
  }, []);

  const containerStyle = {
    width: "100%",
    height: "300px", // Atur tinggi sesuai kebutuhan Anda
    overflow: "auto",
    /* Gaya tampilan scroll */
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
  };

  useEffect(() => {
    if (idLokasi) {
      ambilDataLokasi();
      ambilFakturJoin(idLokasi);
    }
  }, [idLokasi]);

  const ambilDataLokasi = () => {
    axios({
      method: "get",
      url: LOKASI + "ambil/",
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          if (data.length > 0) {
            const fil = data.filter((item) => {
              return idLokasi == item.id;
            });

            const level = fil[0].level;

            if (level == "Puskesmas") {
              setPuskesmas(fil[0].lokasi);
              setUnit("");

              const datafilter = data.filter((item) => {
                return item.id_Puskesmas == fil[0].id;
              });
              let lok = [];
              for (var i = 1; i < datafilter.length; i++) {
                lok.push({
                  value: datafilter[i].id,
                  label: datafilter[i].lokasi,
                  color: "primary",
                });
              }

              setLokasi(lok);
            } else {
              const datafilter = data.filter((item) => {
                return item.id == fil[0].id_Puskesmas;
              });

              setUnit(fil[0].lokasi);

              setPuskesmas(datafilter[0].lokasi);

              setLokasi([
                {
                  value: 74,
                  label: "Pasien",
                  color: "primary",
                },
              ]);
            }
            setLevel(level);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilFakturJoin = (id) => {
    axios({
      method: "get",
      url: FAKTUR_PERMINTAAN + "ambil/join/" + id,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          setFirstTable(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
  ];

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listTahun.filter((item) => {
      return item.tahun == select;
    });

    setTahun(curAnak[0].tahun);
    // ambilDataBantuanKategori(currentBantuan, curAnak[0].tahun);
  };

  const OptionComponent = ({ data, ...props }) => {
    return (
      <components.Option {...props}>
        <div className={`bullet bullet-${data.color} bullet-sm mr-2`}></div>
        {data.label}
      </components.Option>
    );
  };

  const pageSize = 100;
  const pageSize2 = 100;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize2);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const openModal = (data) => {
    console.log(data);
    handleOpenModalPermintaan(data);
    setFakturKeluar(data.fakturKeluar);
    setModalDelete(true);
  };
  const closeModal = () => {
    setFakturPermintaan([]);
    setTableSementara([]);
    setSecondTable([]);
    setModalDelete(false);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">
                    Daftar #<code>{"Permintaan "}</code>
                  </div>
                  <div>
                    {" "}
                    <ReactHTMLTableToExcel
                      table="neraca_sumber"
                      filename={
                        level == "Puskesmas"
                          ? "Permintaan -  Sumber" + puskesmas + " " + tahun
                          : "Permintaan -  Sumber" +
                            puskesmas +
                            "-" +
                            unit +
                            " " +
                            tahun
                      }
                      sheet="Data"
                      className="btnKu"
                    />
                  </div>

                  <div className="d-flex">
                    <Col className="pr-grid-col" xs={4}>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary">
                          {simpleDropdownValue2}
                        </DropdownToggle>

                        <DropdownMenu>
                          {listTahun &&
                            listTahun.map((item) => (
                              <DropdownItem
                                onClick={changeSelectDropdownSimple2}
                              >
                                {item.tahun}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    id="neraca_sumber"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>No</th>
                        <th className="w-25">Faktur</th>

                        <th className="w-25">TANGGAL MINTA</th>

                        <th className="w-25">STATUS</th>

                        <th className="w-25">TANGGAL RESPONSE</th>
                        <th className="w-25">AKOMODIR</th>
                        <th className="w-25">CATATAN</th>
                        <th className="w-25">PETUGAS KELUAR</th>
                        <th className="w-25">SURAT</th>

                        <th className="w-25">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item, index) => (
                          <tr key={uuidv4()}>
                            <td>{index + 1}</td>
                            <td className="d-flex align-items-center">
                              <span className="ml-3">{item.fakturMinta}</span>
                            </td>

                            <td>{formatDate(item.tanggalMinta)}</td>

                            <td>
                              {item.status == 0 ? (
                                "On Progress"
                              ) : (
                                <img
                                  style={{ width: 25, height: 25 }}
                                  src={
                                    item.status == 1
                                      ? Success
                                      : item.status == 3
                                      ? Stop
                                      : item.status == 2
                                      ? Success
                                      : ""
                                  }
                                />
                              )}
                            </td>
                            <td>{formatDate(item.tanggalRes)}</td>
                            <td>
                              <div className={`d-flex justify-content-between`}>
                                <div className="d-flex flex-column mt-3">
                                  <div className={s.activity}>
                                    <p className="body-2">
                                      {item.jumlahObatKeluar
                                        ? Math.round(
                                            (item.jumlahObatMinta /
                                              item.jumlahObatKeluar) *
                                              100
                                          )
                                        : 0}
                                      % akomodir{" "}
                                    </p>
                                    <p className="body-2">
                                      {item.jumlahObatMinta}
                                      <span className="body-3 muted">
                                        /{item.jumlahObatKeluar}
                                      </span>
                                    </p>
                                  </div>
                                  <Progress
                                    color={
                                      (item.jumlahObatMinta /
                                        item.jumlahObatKeluar) *
                                        100 >
                                      50
                                        ? "success"
                                        : "danger"
                                    }
                                    className="progress-xs"
                                    value={
                                      (item.jumlahObatMinta /
                                        item.jumlahObatKeluar) *
                                      100
                                    }
                                  />
                                </div>
                              </div>
                            </td>

                            <td>{item.catatan}</td>
                            <td>{item.petugasRespon}</td>
                            <td>
                              {item.file ? (
                                <a
                                  href={DOMAIN_SIMOBAT + item.file}
                                  target="blank"
                                  style={{ color: "green" }}
                                >
                                  <i className="eva eva-file" />
                                </a>
                              ) : (
                                <div />
                              )}
                            </td>

                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="primary"
                                size="xs"
                                onClick={() => {
                                  openModal(item);
                                }}
                              >
                                Data
                              </Button>
                            </td>

                            <td></td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size="lg" isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          <div className={s.tableTitle}>
            <div className="headline-2">
              Daftar Obat Minta
              <code> Vs</code> Response
            </div>
            <div className="d-flex">
              <a>
                <ReactHTMLTableToExcel
                  table="dataBantuan"
                  filename={"faktur minta-"}
                  sheet="Data"
                  className="btnKu"
                />
              </a>
              <a>
                <img src={printerIcon} alt="Printer" />
              </a>
              <a>
                <img
                  className="d-none d-sm-block"
                  src={optionsIcon}
                  alt="Options"
                />
              </a>
              <a>
                <img
                  style={{ width: 30, height: 30 }}
                  src={Success}
                  alt="Funnel"
                />
              </a>
            </div>
          </div>
        </ModalHeader>

        <Widget className="widget-p-md">
          <div className="widget-table-overflow">
            <Table
              id="dataBantuan"
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead>
                <tr>
                  <th className={s.checkboxCol}>
                    <div className="checkbox checkbox-primary">
                      <input
                        className="styled"
                        id="checkbox100"
                        type="checkbox"
                      />
                      <label for="checkbox100" />
                    </div>
                  </th>
                  <th className="w-25">Obat</th>
                  <th className="w-25">Satuan</th>

                  <th className="w-25">Permintaan</th>
                  <th className="w-25">Dikirim</th>

                  <th className="w-25">Akomodir</th>
                </tr>
              </thead>
              <tbody>
                {secondTable
                  .slice(
                    secondTableCurrentPage * pageSize2,
                    (secondTableCurrentPage + 1) * pageSize2
                  )
                  .map((item) => (
                    <tr key={uuidv4()}>
                      <td>
                        <div className="checkbox checkbox-primary">
                          <input
                            id={item.nama}
                            className="styled"
                            type="checkbox"
                          />
                          <Label for={item.nama} />
                        </div>
                      </td>
                      <td className="d-flex align-items-center">
                        <span className="ml-3">{item.nama}</span>
                      </td>

                      <td>{item.satuan}</td>
                      <td>
                        {item.jumlahMinta && numberWithCommas(item.jumlahMinta)}
                      </td>
                      <td>
                        {item.jumlahMasuk && numberWithCommas(item.jumlahMasuk)}
                      </td>

                      <td>
                        {
                          <div className={`d-flex justify-content-between`}>
                            <div className="d-flex flex-column mt-3">
                              <div className={s.activity}>
                                <p className="body-2">
                                  {item.jumlahMasuk
                                    ? Math.round(
                                        (item.jumlahMasuk / item.jumlahMinta) *
                                          100
                                      )
                                    : 0}
                                  % akomodir{" "}
                                </p>
                                <p className="body-2">
                                  {item.jumlahMasuk ? item.jumlahMasuk : 0}
                                  <span className="body-3 muted">
                                    /{item.jumlahMinta}
                                  </span>
                                </p>
                              </div>
                              <Progress
                                color={
                                  (item.jumlahMasuk / item.jumlahMinta) * 100 >
                                  50
                                    ? "success"
                                    : "danger"
                                }
                                className="progress-xs"
                                value={
                                  (item.jumlahMasuk / item.jumlahMinta) * 100
                                }
                              />
                            </div>
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              className="pagination-borderless"
              aria-label="Page navigation example"
            >
              <PaginationItem disabled={secondTableCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    setSecondTablePage(e, secondTableCurrentPage - 1)
                  }
                  previous
                  href="#top"
                />
              </PaginationItem>
              {[...Array(secondTablePagesCount)].map((page, i) => (
                <PaginationItem active={i === secondTableCurrentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => setSecondTablePage(e, i)}
                    href="#top"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={secondTableCurrentPage >= secondTablePagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    setFirstTablePage(e, secondTableCurrentPage + 1)
                  }
                  next
                  href="#top"
                />
              </PaginationItem>
            </Pagination>
          </div>
        </Widget>
      </Modal>
    </div>
  );
};

export default TableResponPermintaan;

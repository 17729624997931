

export function timeDifference(dateString) {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  if (
    currentDate.getFullYear() === givenDate.getFullYear() &&
    currentDate.getMonth() === givenDate.getMonth() &&
    currentDate.getDate() === givenDate.getDate()
  ) {
    return `hari ini ${givenDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${givenDate.getMinutes().toString().padStart(2, '0')}`;
  } else {
    return `${givenDate.getDate()} ${
      monthNames[givenDate.getMonth()]
    } ${givenDate.getFullYear()} ${givenDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${givenDate.getMinutes().toString().padStart(2, '0')}`;
  }
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup/LinksGroup';
import { changeActiveSidebarItem } from '../../actions/navigation.js';

import SimObat from '../Icons/simobat.png';
import 'eva-icons/style/eva-icons.css';

const Sidebar = (props) => {
  const { activeItem = '', ...restProps } = props;

  const [burgerBtnToggled, setBurgerBtnToggled] = useState(false);

  const [currentUser, setCurrentUser] = useState();
  const [status, setStatus] = useState();

  const [idLokasi, setIdLokasi] = useState('');
  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
      setStatus(currentUser.status);
      setIdLokasi(currentUser.idLokasi);
    }
  }, []);

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerBtnToggled(true);
    } else {
      setTimeout(() => {
        setBurgerBtnToggled(false);
      }, 0);
    }
  }, [props.sidebarOpened]);

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerBtnToggled })}>
      <header className={s.logo}>
        <img style={{ width: 50, height: 50 }} src={SimObat} />
        <span className={s.title}>SIM Obat</span>
      </header>
      <ul className={s.nav}>
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header='Dashboard'
          isHeader
          iconName={<i className='eva eva-home-outline' />}
          link='/template/dashboard'
          index='dashboard'
          badge='9'
        />
        <h5 className={[s.navTitle, s.groupTitle].join(' ')}>DATA</h5>

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header='Profil Saya'
          isHeader
          iconName={<i className='eva eva-person-outline' />}
          link='/admin/profil'
          index='profil'
          exact={false}
          childrenLinks={[
            {
              header: 'Profile',
              link: '/template/user/profile',
            },
          ]}
        />
        {idLokasi ? (
          idLokasi == 1 ? (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header='Stock Gudang'
              isHeader
              iconName={<i className='eva eva-battery' />}
              link='/template/stockgudang'
              index='stockgudang'
              exact={false}
              childrenLinks={[
                {
                  header: 'Stock Gudang',
                  link: '/template/stockgudang/neraca',
                },
                {
                  header: 'Stock masuk',
                  link: '/template/stockgudang/masuk',
                },
                {
                  header: 'Stock keluar',
                  link: '/template/stockgudang/keluar',
                },
              ]}
            />
          ) : (
            <div />
          )
        ) : (
          <div />
        )}

        {idLokasi ? (
          idLokasi != 1 ? (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header='Stock'
              isHeader
              iconName={<i className='eva eva-battery' />}
              link='/template/stock'
              index='stock'
              label='new'
              labelColor='success'
              childrenLinks={[
                {
                  header: 'Stock',
                  link: '/template/stock/neraca',
                },
                {
                  header: 'Stock masuk',
                  link: '/template/stock/masuk',
                },
                {
                  header: 'Stock keluar',
                  link: '/template/stock/keluar',
                },
              ]}
            />
          ) : (
            <div />
          )
        ) : (
          <div />
        )}

        {idLokasi ? (
          idLokasi != 1 ? (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header='Permintaan'
              isHeader
              iconName={<i className='eva eva-cube' />}
              link='/template/permintaan'
              index='permintaan'
              childrenLinks={[
                {
                  header: 'Ajukan',
                  link: '/template/permintaan/ajukan',
                },
                {
                  header: 'Respons',
                  link: '/template/permintaan/respon',
                },
              ]}
            />
          ) : (
            <div />
          )
        ) : (
          <div />
        )}

        {status ? (
          status != 3 ? (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header='Users'
              isHeader
              iconName={<i className='fa fa-child' />}
              link='/admin/listadmin'
              index='listadmin'
              childrenLinks={[
                {
                  header: 'User SimObat',
                  link: '/admin/listadmin',
                },
              ]}
            />
          ) : (
            <div />
          )
        ) : (
          <div />
        )}

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header='Rekapan'
          isHeader
          iconName={<i className='fa fa-inbox' />}
          link='/template/rekapan'
          index='rekapan'
          childrenLinks={[
            {
              header: 'Masuk',
              link: '/template/rekapan/masuk',
            },
            {
              header: 'Keluar',
              link: '/template/rekapan/keluar',
            },
          ]}
        />
        {idLokasi ? (
          idLokasi == 1 &&
          status != 3 && (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header='Data Obat'
              isHeader
              iconName={<i className='fa fa-cube' />}
              link='/template/dataobat'
              index='dataobat'
              childrenLinks={[
                {
                  header: 'Obats',
                  link: '/template/dataobat',
                },
              ]}
            />
          )
        ) : (
          <div />
        )}

        {idLokasi ? (
          idLokasi != 1 ? (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header='Laporan'
              isHeader
              iconName={<i className='eva eva-clipboard' />}
              link='/template/laporan'
              index='laporan'
              childrenLinks={[
                {
                  header: 'Neraca',
                  link: '/template/laporan/neraca',
                },
                {
                  header: 'Neraca Obat',
                  link: '/template/laporan/neracaobat',
                },
              ]}
            />
          ) : (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header='Laporan'
              isHeader
              iconName={<i className='eva eva-clipboard' />}
              link='/template/laporangudang'
              index='laporangudang'
              childrenLinks={[
                {
                  header: 'Neraca',
                  link: '/template/laporan/neracagudang',
                },
                {
                  header: 'Neraca Obat',
                  link: '/template/laporan/neracaobatgudang',
                },
              ]}
            />
          )
        ) : (
          <div />
        )}

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header='Documentation'
          link='/documentation'
          isHeader
          iconName={<i className='eva eva-book-open-outline' />}
          index='documentation'
          label='new'
          target='_blank'
          labelColor='success'
        />
      </ul>
    </nav>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));

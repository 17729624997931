import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Modal,ModalHeader,ModalBody
} from 'reactstrap';
import { logoutUser } from '../../actions/auth.js';
import { closeSidebar, openSidebar } from '../../actions/navigation.js';
import MenuIcon from '../Icons/HeaderIcons/MenuIcon.js';
import SearchBarIcon from '../Icons/HeaderIcons/SearchBarIcon.js';
import BellIcon from '../Icons/HeaderIcons/BellIcon.js';
import SearchIcon from '../Icons/HeaderIcons/SearchIcon.js';

import { useHistory } from 'react-router';

import ProfileIcon from '../../assets/navbarMenus/pfofileIcons/ProfileIcon.js';
import MessagesIcon from '../../assets/navbarMenus/pfofileIcons/MessagesIcon.js';
import TasksIcon from '../../assets/navbarMenus/pfofileIcons/TasksIcon.js';

import logoutIcon from '../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg';
import basketIcon from '../../assets/navbarMenus/basketIcon.svg';
import calendarIcon from '../../assets/navbarMenus/calendarIcon.svg';
import envelopeIcon from '../../assets/navbarMenus/envelopeIcon.svg';
import mariaImage from '../../assets/navbarMenus/mariaImage.jpg';
import notificationImage from '../../assets/navbarMenus/notificationImage.jpg';
import userImg from '../../assets/user.svg';
import SimObat from '../Icons/simobat.png';
import App from '../../chat/components/App.js'
import s from './Header.module.scss';
import 'animate.css';
import {
  API_TIMEOUT,
  FAKTUR_MASUK,
  FAKTUR_PERMINTAAN,
} from '../../utils/index.js';
import axios from 'axios';
import messageImg from '../../assets/img/message.png'


const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [listPermintaan, setListPermintaa] = useState([]);
  const [listMasuk, setListMasuk] = useState([]);
  const [notifCount, setNotifCount] = useState(0);
  const history = useHistory();
  const [modalOpen,setModalOpen]=useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));

      setCurrentUser(currentUser);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.level == 'Puskesmas' || currentUser.level == 'Dinas') {
        ambilFakturPermintaan();
      }
      if (currentUser.level == 'Puskesmas' || currentUser.level == 'Unit') {
        ambilFakturMasuk();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const count = listMasuk.length + listPermintaan.length;
    setNotifCount(count);
  }, [listMasuk, listPermintaan]);

  function hitungSelisihHari(tanggal) {
    const hariIni = new Date();
    const tanggalTujuan = new Date(tanggal);

    const satuHari = 24 * 60 * 60 * 1000; // jam * menit * detik * milidetik

    const selisih = Math.abs(hariIni - tanggalTujuan);
    const selisihHari = Math.floor(selisih / satuHari);
    let hasil = '';
    if (selisihHari < 1) {
      hasil = 'hari ini';
    } else {
      hasil = selisihHari + ' hari yang lalu';
    }
    return hasil;
  }

  const ambilFakturMasuk = () => {
    axios({
      method: 'get',
      url: FAKTUR_MASUK + 'ambil/' + currentUser.idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          setListMasuk(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilFakturPermintaan = () => {
    axios({
      method: 'get',
      url: FAKTUR_PERMINTAAN + 'ambil/' + currentUser.idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          setListPermintaa(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const doLogout = () => {
    props.dispatch(logoutUser());
  };

  const toggleSidebar = () => {
    if (props.sidebarOpened) {
      props.dispatch(closeSidebar());
    } else {
      props.dispatch(openSidebar());
    }
  };

  return (
    <Navbar className={`header-navbar d-print-none`}>
      <div>
        <NavLink
          onClick={() => toggleSidebar()}
          className={`d-md-none mr-3`}
          href='#'
        >
          <MenuIcon className={s.menuIcon} />
        </NavLink>
      </div>
      <Form className='d-none d-sm-block' inline>
        <FormGroup>
          <InputGroup>
            <Input
              id='search-input'
              placeholder='Search Dashboard'
              className='focus no-border'
            />
            <InputGroupAddon addonType='prepend'>
              <span className='d-flex align-self-center px-3'>
                <SearchBarIcon />
              </span>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Form>
      <Nav className='ml-auto'>
        <NavItem className='d-sm-none mr-4'>
          <NavLink className='' href='#'>
            <SearchIcon />
          </NavLink>
        </NavItem>
        <FormGroup
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 10,
            cursor: 'pointer',
          }}
          
        >
          <span
            className='small d-none d-sm-block ml-1 mr-2 body-1'
            style={{ color: '#d2592d' }}
          >
            {currentUser && currentUser.lokasi}
          </span>
        </FormGroup>
        <div
                style={{
                  marginTop:10,
                  width: 50,
                  height: 30,
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => setModalOpen(true)}
              >
                <img
                  src={messageImg}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain', // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                  }}
                />
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: '#fd5c63',
                    borderRadius: 6,
                    position: 'absolute',
                    right: 5,
                    top: 1,
                  }}
                ></div>
              </div>
        <Dropdown
          nav
          isOpen={menuOpen}
          toggle={() => toggleMenu()}
          className='tutorial-dropdown mr-2 mr-sm-3'
        >
          <DropdownToggle nav>
            <div className={s.navbarBlock} style={{ position: 'relative' }}>
              <BellIcon maskId={114}></BellIcon>
              {notifCount > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#ff5252',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 5,
                    borderRadius: '50%',
                    right: -5,
                    top: -8,
                    width: 15,
                    height: 15,
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  <text style={{ fontSize: 8 }}> {notifCount}</text>
                </div>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu
            right
            className='navbar-dropdown notifications-dropdown'
            style={{ width: '340px' }}
          >
            {listPermintaan.length > 0 && (
              <div>
                {listPermintaan.map((item) => (
                  <DropdownItem
                    onClick={() => {
                      // history.push('template / dashboard');
                      currentUser.idLokasi == 1
                        ? history.push('/template/stockgudang/keluar')
                        : history.push('/template/stock/keluar');
                    }}
                  >
                    <div>
                      <div className='d-flex flex-row mb-1'>
                        <img
                          src={SimObat}
                          alt='Maria'
                          className={s.mariaImage}
                        />
                        <div className='d-flex flex-column'>
                          <p className='body-3'>{item.asal}</p>
                          <p className='label muted'>
                            {hitungSelisihHari(item.tanggal)}
                          </p>
                        </div>
                      </div>

                      <img src={basketIcon} alt='Calendar Icon' />
                      <span>Jumlah permintaan {item.jumlah} item obat</span>
                    </div>
                  </DropdownItem>
                ))}
              </div>
            )}
            {listMasuk.length > 0 && (
              <div>
                {listMasuk.map((item) => (
                  <DropdownItem
                    onClick={() => history.push('/template/stock/masuk')}
                  >
                    <img src={envelopeIcon} alt='Envelope Icon' />
                    <span>Obat masuk berjumlah {item.jumlah} jenis</span>
                    <div
                      className='d-flex'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <p className='body-3'>Asal {item.asal}</p>

                      <code>{hitungSelisihHari(item.tanggal)}</code>
                    </div>
                  </DropdownItem>
                ))}
              </div>
            )}
          </DropdownMenu>
        </Dropdown>
        <Dropdown
          isOpen={notificationsOpen}
          toggle={() => toggleNotifications()}
          nav
          id='basic-nav-dropdown'
          className='ml-3'
        >
          <DropdownToggle nav caret className='navbar-dropdown-toggle'>
            <div className={`${s.avatar} rounded-circle float-left mr-2`}>
              <img
                src={
                  currentUser
                    ? currentUser.avatar
                      ? currentUser.avatar
                      : SimObat
                    : SimObat
                }
                alt='User'
              />
            </div>
            <span
              className='small d-none d-sm-block ml-1 mr-2 body-1'
              style={{ color: '#0000ff' }}
            >
              {currentUser && currentUser.nama}
            </span>
          </DropdownToggle>
          <DropdownMenu
            className='navbar-dropdown profile-dropdown'
            style={{ width: '194px' }}
          >
            <DropdownItem className={s.dropdownProfileItem}>
              <Link to='/template/user/profile'>
                <ProfileIcon />
                <span>Profile</span>
              </Link>
            </DropdownItem>
            <DropdownItem className={s.dropdownProfileItem}>
              <TasksIcon />
              <span>Tasks</span>
            </DropdownItem>
            <DropdownItem className={s.dropdownProfileItem}>
              <MessagesIcon />
              <span>Messages</span>
            </DropdownItem>
            <NavItem>
              <NavLink onClick={() => doLogout()} href='#'>
                <button
                  className='btn btn-primary rounded-pill mx-auto logout-btn'
                  type='submit'
                >
                  <img src={logoutIcon} alt='Logout' />
                  <span className='ml-1'>Logout</span>
                </button>
              </NavLink>
            </NavItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>

      <Modal
        size='lg'
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        contentClassName='p-0'
       
      >
        <ModalHeader toggle={() => setModalOpen(false)}>
          <div>
            <div className='headline-2'>{'Percakapan - SIMO'}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <App id={currentUser && currentUser.uid} />
        </ModalBody>
      </Modal>
    </Navbar>
  );
};

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

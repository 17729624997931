import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as dataFormat from './UsersDataFormatters';
import actions from '../../../actions/usersListActions';
import axios from 'axios';
import {
  GET_USER,
  API_TIMEOUT,
  STOCK_GUDANG,
  STOCK_PUSKESMAS,
  LOKASI,
  STOCK_UNIT,
} from '../../../utils';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Badge,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Widget from '../../Widget/Widget';

import s from '../Users.module.scss';
import { useDispatch } from 'react-redux';
import { numberWithCommas } from '../../../utils/func';
import { logApp } from '../../../utils/func';
import {
  GET_BANTUAN_TAHUN,
  DELETE_BANTUAN_TAHUN,
  MASUK_GUDANG,
} from '../../../utils';
import { set } from 'lodash';

const { SearchBar } = Search;

const StockListTable = (props) => {
  const { rows, dispatch, loading, idToDelete, modalOpen } = props;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);
  const [rowsTable, setRowsTable] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [rowsKu, setRowsKu] = useState([]);
  const [rowsKuTampil, setRowsKuTampil] = useState([]);
  const [idHapus, setIdHapus] = useState();
  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState('All');
  const [jumlahObat, setJumlahObat] = useState(0);

  const [infoSelected, setInfoSelected] = useState();
  const listKondisi = [
    { kondisi: 'All' },
    { kondisi: 'Ready' },
    { kondisi: 'Expired' },
  ];

  const [tahun, setTahun] = useState('2023');
  const [idLokasi, setIdLokasi] = useState('');

  const [kondisi, setKondisi] = useState('All');

  const [level, setLevel] = useState('');
  const [puskesmas, setPuskesmas] = useState('');
  const [unit, setUnit] = useState('');

  const [modalDelete, setModalDelete] = useState(false);

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const listKondisiKu = listKondisi.filter((item) => {
      return item.kondisi == select;
    });
    setKondisi(listKondisiKu[0].kondisi);
    ambilDataKondisi(listKondisiKu[0].kondisi);
  };

  function mergeRows(dataArray) {
    return dataArray.reduce((accumulator, currentItem) => {
      const existingItem = accumulator.find(
        (item) => item.id_dataObat === currentItem.id_dataObat
      );

      if (existingItem) {
        existingItem.list_masuk.push({
          id_masuk: currentItem.id_masuk,
          id_masukGudang: currentItem.id_masukGudang,
          tanggalMasuk: formatDate(currentItem.tanggalMasuk),
          ed: formatDate(currentItem.ed),
          sumber: currentItem.sumber,
          penyedia: currentItem.penyedia,
          total_masuk: currentItem.total_masuk,
          total_keluar: currentItem.total_keluar,
          stok_ready: currentItem.stok_ready,
          noFaktur: currentItem.noFaktur,
          harga: currentItem.harga,
          tahunPengadaan: currentItem.tahunPengadaan,
          stok_ready: currentItem.stok_ready,

          total_masuk: currentItem.total_masuk,

          total_keluar: currentItem.total_keluar,
          stok: currentItem.stok,
          stok_expired: currentItem.stok_expired,
        });
        existingItem.stok_ready += currentItem.stok_ready;
        existingItem.total_masuk += currentItem.total_masuk;

        existingItem.total_keluar += currentItem.total_keluar;
        existingItem.stok += currentItem.stok;
        existingItem.stok_expired += currentItem.stok_expired;
      } else {
        accumulator.push({
          id_dataObat: currentItem.id_dataObat,
          nama: currentItem.nama,
          satuan: currentItem.satuan,
          list_masuk: [
            {
              id_masuk: currentItem.id_masuk,
              id_masukGudang: currentItem.id_masukGudang,
              tanggalMasuk: formatDate(currentItem.tanggalMasuk),
              ed: formatDate(currentItem.ed),
              sumber: currentItem.sumber,
              penyedia: currentItem.penyedia,
              total_masuk: currentItem.total_masuk,
              total_keluar: currentItem.total_keluar,
              stok_ready: currentItem.stok_ready,
              noFaktur: currentItem.noFaktur,
              harga: currentItem.harga,
              tahunPengadaan: currentItem.tahunPengadaan,
              stok_ready: currentItem.stok_ready,

              total_masuk: currentItem.total_masuk,

              total_keluar: currentItem.total_keluar,
              stok: currentItem.stok,
              stok_expired: currentItem.stok_expired,
            },
          ],
          stok_ready: currentItem.stok_ready,

          total_masuk: currentItem.total_masuk,

          total_keluar: currentItem.total_keluar,
          stok: currentItem.stok,
          stok_expired: currentItem.stok_expired,
        });
      }

      return accumulator;
    }, []);
  }

  const formatDate = (tglKu) => {
    const tgl = new Date(tglKu);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${day}-${month}-${year}`;
    return date_string;
  };

  const ambilDataKondisi = (kondisi) => {
    if (kondisi == 'Ready') {
      const data = rowsKuTampil.filter((item) => {
        return item.stok_ready > 0;
      });
      setRowsKu(data);
    } else if (kondisi == 'Expired') {
      const data = rowsKuTampil.filter((item) => {
        return item.stok_expired > 0;
      });
      setRowsKu(data);
    } else {
      setRowsKu(rowsKuTampil);
    }
  };

  const ambilDataLokasi = () => {
    axios({
      method: 'get',
      url: LOKASI + 'ambil/' + idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          const levelKu = data[0].level;

          setLevel(levelKu);
          props.setLevelKu(levelKu);

          if (levelKu == 'Puskesmas') {
            setPuskesmas(data[0].lokasi);
            props.setPuskesmasKu(data[0].lokasi);
          } else {
            setUnit(data[0].lokasi);
            props.setUnitKu(data[0].lokasi);
            ambilPuskesLokasi(data[0].id_Puskesmas);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilPuskesLokasi = (id) => {
    axios({
      method: 'get',
      url: LOKASI + 'ambil/' + id,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          setPuskesmas(data[0].lokasi);
          props.setPuskesmasKu(data[0].lokasi);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const cariStockTahun = () => {
    axios({
      method: 'get',
      url:
        level == 'Puskesmas'
          ? STOCK_PUSKESMAS + idLokasi
          : STOCK_UNIT + idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = mergeRows(response.data.data);

          setJumlahObat(data.length);
          setRowsKuTampil([...data]);
          setRowsKu([...data]);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  useEffect(() => {
    if (!currentUser) {
      let currentUser = JSON.parse(localStorage.getItem('user'));
      //untuk kepentngan development

      setCurrentUser(currentUser);
      setIdLokasi(currentUser.idLokasi);

      logApp(currentUser.uid, 'Stock');
    }
  }, []);

  useEffect(() => {
    if (idLokasi) {
      ambilDataLokasi();
    }
  }, [idLokasi]);

  useEffect(() => {
    if (idLokasi && level) {
      cariStockTahun();
    }
  }, [idLokasi, level]);

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: DELETE_BANTUAN_TAHUN + idHapus,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          alert('Data bantuan berhasil dihapus');
          const idToDelete = idHapus;
          const filteredArray = rowsKu.filter((obj) => obj.id !== idToDelete);
          setRowsKu(filteredArray);
          setModalDelete(false);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };
  const openModal = (cell) => {
    const id = cell;
    setInfoSelected(cell);
    setIdHapus(id);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };
  const actionFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={s.controlBtn}
          color='warning'
          size='sm'
          style={{ position: 'relative' }}
          onClick={() => {
            openModal(cell);
          }}
        >
          {cell ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#24ae24',
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 5,
                borderRadius: '50%',
                right: -10,
                top: -15,
                width: 25,
                height: 25,
                color: 'white',
                cursor: 'pointer',
              }}
              onClick={() => ''}
            >
              <text style={{ fontSize: 12 }}> {cell.length}</text>
            </div>
          ) : (
            <div />
          )}
          Detail
        </Button>
      </div>
    );
  };

  const updateWindowDimensions = () => {
    setTableWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(actions.doFetch({}));
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const columns = [
    {
      dataField: 'id_dataObat',
      sort: true,

      text: 'id',
    },
    {
      dataField: 'nama',
      sort: true,
      text: 'Nama',
    },
    {
      dataField: 'total_masuk',
      sort: true,
      text: 'Masuk',
    },
    {
      dataField: 'total_keluar',
      sort: true,
      text: 'Keluar',
    },
    {
      dataField: 'stok',
      sort: true,
      text: 'Stock',
    },
    {
      dataField: 'stok_ready',
      sort: true,
      text: 'Ready',
    },
    {
      dataField: 'stok_expired',
      sort: true,
      text: 'expired',
    },
    {
      dataField: 'satuan',
      sort: true,
      text: 'Satuan',
    },

    {
      dataField: 'list_masuk',
      formatter: actionFormatter,
      text: 'Actions',
    },
  ];

  return (
    <div>
      <Widget className='widget-p-md'>
        <Row>
          <Col
            className='pr-grid-col'
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <code> Jumlah Obat </code>{' '}
            <Badge color='danger'>{rowsKu.length}</Badge>
          </Col>
          <Col
            className='pr-grid-col'
            xs={6}
            style={{
              display: 'flex',

              justifyContent: 'flex-end',
            }}
          >
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color='primary'>
                {simpleDropdownValue2}
              </DropdownToggle>

              <DropdownMenu>
                {listKondisi &&
                  listKondisi.map((item) => (
                    <DropdownItem onClick={changeSelectDropdownSimple2}>
                      {item.kondisi}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>

        <ToolkitProvider
          columns={columns}
          data={rowsKu}
          keyField='id_dataObat'
          search
        >
          {(props) => (
            <React.Fragment>
              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover mt-4 ${
                  tableWidth < 1150 ? 'table-responsive' : ''
                }`}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Widget>
      <Modal size='sm' isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Info Obat</ModalHeader>
        <ModalBody>
          <Widget className='widget-p-md'>
            {infoSelected && (
              <div>
                <ul className='list-group'>
                  {infoSelected.map((item, index) => (
                    <li className='list-group-item' key={index}>
                      <div className={s.listBadge}>
                        <div>
                          Tanggal masuk{' '}
                          <Button size='xs' color='secondary-red'>
                            {item.tanggalMasuk}{' '}
                          </Button>{' '}
                          Stok Ready
                          <code>{item.stok_ready}</code> {infoSelected.satuan}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        ></div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Widget>
        </ModalBody>
        <ModalFooter>
          <Button color='success' onClick={() => closeModal()}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.list.loading,
    rows: store.users.list.rows,
    modalOpen: store.users.list.modalOpen,
    idToDelete: store.users.list.idToDelete,
  };
}

export default connect(mapStateToProps)(StockListTable);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as dataFormat from './UsersDataFormatters';
import actions from '../../../actions/usersListActions';
import axios from 'axios';
import { GET_USER, API_TIMEOUT, STOCK_GUDANG } from '../../../utils';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Badge,
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Widget from '../../Widget/Widget';

import s from '../Users.module.scss';
import { useDispatch } from 'react-redux';
import { numberWithCommas } from '../../../utils/func';
import { logApp } from '../../../utils/func';
import {
  GET_BANTUAN_TAHUN,
  DELETE_BANTUAN_TAHUN,
  MASUK_GUDANG,
} from '../../../utils';
import { set } from 'lodash';

const { SearchBar } = Search;

const StoackGudangListTable = (props) => {
  const { rows, dispatch, loading, idToDelete, modalOpen } = props;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);
  const [rowsTable, setRowsTable] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [rowsKu, setRowsKu] = useState([]);
  const [idHapus, setIdHapus] = useState();
  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState('All');
  const [jumlahObat, setJumlahObat] = useState(0);
  const [rowsKuTampil, setRowsKuTampil] = useState([]);
  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
  ];

  const [tahun, setTahun] = useState('2023');

  const listKondisi = [
    { kondisi: 'All' },
    { kondisi: 'Ready' },
    { kondisi: 'Expired' },
  ];

  const [modalDelete, setModalDelete] = useState(false);
  const [kondisi, setKondisi] = useState('All');
  const dispatchKu = useDispatch();

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const listKondisiKu = listKondisi.filter((item) => {
      return item.kondisi == select;
    });
    setKondisi(listKondisiKu[0].kondisi);
    ambilDataKondisi(listKondisiKu[0].kondisi);
  };

  const ambilDataKondisi = (kondisi) => {
    if (kondisi == 'Ready') {
      const data = rowsKuTampil.filter((item) => {
        return item.stok_ready > 0;
      });
      setRowsKu(data);
    } else if (kondisi == 'Expired') {
      const data = rowsKuTampil.filter((item) => {
        return item.stok_expired > 0;
      });
      setRowsKu(data);
    } else {
      setRowsKu(rowsKuTampil);
    }
  };

  const cariStockTahun = () => {
    axios({
      method: 'get',
      url: STOCK_GUDANG,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          // data.map((item, index) => {
          //   data[index].totalDana = numberWithCommas(item.totalDana);
          //   if (item.target == 'Kelompok') {
          //     data[index].total = item.jmlPenerima * item.jmlPerklp;
          //   } else {
          //     data[index].total = item.jmlPenerima;
          //   }
          // });
          let dataFilter = [];

          // if (currentUser.peran == 'admin') {
          //   dataFilter = data.filter((item) => {
          //     return item.dinas == currentUser.dinas;
          //   });
          // } else {
          //   dataFilter = data;
          // }

          setJumlahObat(data.length);
          setRowsKuTampil([...data]);
          setRowsKu([...data]);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  useEffect(() => {
    if (!currentUser) {
      let currentUser = JSON.parse(localStorage.getItem('user'));
      //untuk kepentngan development

      setCurrentUser(currentUser);
      // logApp(currentUser.uid, 'Daftar Bantuan');
    }
  }, []);

  useEffect(() => {
    const uid = currentUser ? currentUser.uid : null;
    if (uid) cariStockTahun('2023');
  }, [currentUser]);

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: DELETE_BANTUAN_TAHUN + idHapus,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          alert('Data bantuan berhasil dihapus');
          const idToDelete = idHapus;
          const filteredArray = rowsKu.filter((obj) => obj.id !== idToDelete);
          setRowsKu(filteredArray);
          setModalDelete(false);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };
  const openModal = (cell) => {
    const id = cell;

    setIdHapus(id);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };
  const actionFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={s.controlBtn}
          color='danger'
          size='xs'
          onClick={() => {
            openModal(cell);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const updateWindowDimensions = () => {
    setTableWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(actions.doFetch({}));
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const columns = [
    {
      dataField: 'id',
      sort: true,

      text: 'id',
    },
    {
      dataField: 'nama',
      sort: true,
      text: 'Nama',
    },
    {
      dataField: 'total_masuk',
      sort: true,
      text: 'Masuk',
    },
    {
      dataField: 'total_keluar',
      sort: true,
      text: 'Keluar',
    },
    {
      dataField: 'stok',
      sort: true,
      text: 'Stock',
    },
    {
      dataField: 'stok_ready',
      sort: true,
      text: 'Ready',
    },
    {
      dataField: 'stok_expired',
      sort: true,
      text: 'expired',
    },
    {
      dataField: 'satuan',
      sort: true,
      text: 'Satuan',
    },

    {
      dataField: 'id',
      formatter: actionFormatter,
      text: 'Actions',
    },
  ];

  return (
    <div>
      <Widget className='widget-p-md'>
        <Row>
          <Col
            className='pr-grid-col'
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <code> Jumlah Obat </code>{' '}
            <Badge color='danger'>{rowsKu.length}</Badge>
          </Col>
          <Col
            className='pr-grid-col'
            xs={6}
            style={{
              display: 'flex',

              justifyContent: 'flex-end',
            }}
          >
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color='primary'>
                {simpleDropdownValue2}
              </DropdownToggle>

              <DropdownMenu>
                {listKondisi &&
                  listKondisi.map((item) => (
                    <DropdownItem onClick={changeSelectDropdownSimple2}>
                      {item.kondisi}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>

        <ToolkitProvider columns={columns} data={rowsKu} keyField='id' search>
          {(props) => (
            <React.Fragment>
              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover mt-4 ${
                  tableWidth < 1150 ? 'table-responsive' : ''
                }`}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Widget>
      <Modal size='sm' isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Konfirmasi hapus</ModalHeader>
        <ModalBody>
          apakah anda yakin akan menghapus data bantuan ini?
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => handleDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.list.loading,
    rows: store.users.list.rows,
    modalOpen: store.users.list.modalOpen,
    idToDelete: store.users.list.idToDelete,
  };
}

export default connect(mapStateToProps)(StoackGudangListTable);

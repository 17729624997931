import React from "react";
import { Badge, Col, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Widget from "../../../components/Widget/Widget.js";
import {
  Form,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
  CustomInput,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { numberWithCommas } from "../../../utils/func.js";

import DatePicker from "react-datepicker";
import s from "./Lists.module.scss";

import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useParams } from "react-router";
import {
  API_TIMEOUT,
  STOCK_GUDANG_TERSEDIA,
  LOKASI,
  PERMINTAAN,
  STOCK_PUSKESMAS_TERSEDIA,
} from "../../../utils/index.js";
import { set } from "lodash";
import swal from "sweetalert";
import { green } from "@material-ui/core/colors";
import { logApp } from "../../../utils/func.js";

export default function AjukanPermintaan() {
  const [list, setList] = useState([]);
  const [listTampil, setListTampil] = useState([]);

  const [allDay, setAllDay] = useState(false);

  const [firstTable, setFirstTable] = useState([]);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [infoSelected, setInfoSelected] = useState();
  const [stok, setStok] = useState(0);

  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [tampilDaftarObat, setTampilDaftarObat] = useState(true);

  const [edit, setEdit] = useState(false);

  const [lokasi, setLokasi] = useState("");

  const [prosesSimpan, setProsesSimpan] = useState(false);

  const [idLokasi, setIdLokasi] = useState("");
  const [level, setLevel] = useState("");
  const [puskesmas, setPuskesmas] = useState("");
  const [unit, setUnit] = useState("");
  const [dokumenSurat, setDokumenSurat] = useState("");
  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const pageSize = 10;
  const [modalDok, setModalDok] = useState(false);

  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);

  const onChange1 = (e) => {
    setDokumenSurat(e.target.files[0]);
  };

  const { makan } = useParams();
  let kategoriKu = [];

  if (kategoriKu.length == 1) kategoriKu = kategoriKu[0];
  if (kategoriKu.length == 2) kategoriKu = kategoriKu[0] + " " + kategoriKu[1];

  const formatDate = (tglKu) => {
    const tgl = new Date(tglKu);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${day}-${month}-${year}`;
    return date_string;
  };

  const containerStyle = {
    width: "100%",
    height: "300px", // Atur tinggi sesuai kebutuhan Anda
    overflow: "auto",
    /* Gaya tampilan scroll */
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
  };

  const radialChartStyle = {
    top: "50%",
    left: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px",
  };

  const [selected, setSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [jumlah, setJumlah] = useState(0);

  const [uid, setUid] = useState();
  const [user, setUser] = useState();

  const [id_dataObat, setId_dataObat] = useState("");

  const [satuan, setSatuan] = useState("");
  const [namaObat, setNamaObat] = useState("");
  const [idEdit, setIdEdit] = useState();

  const [id_tujuan, setId_tujuan] = useState("");
  const [id_asal, setId_asal] = useState("");
  const [id_petugas, setId_petugas] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [stokError, setStokError] = useState("");

  const CloseBtn = (
    <i
      className="eva eva-close cursor-pointer"
      onClick={() => onModalClose()}
    />
  );

  function mergeRows(dataArray) {
    return dataArray.reduce((accumulator, currentItem) => {
      const existingItem = accumulator.find(
        (item) => item.id_dataObat === currentItem.id_dataObat
      );

      if (existingItem) {
        existingItem.list_masuk.push({
          id_masuk: currentItem.id_masuk,
          id_masukGudang: currentItem.id_masukGudang,
          tanggalMasuk: formatDate(currentItem.tanggalMasuk),
          ed: formatDate(currentItem.tanggalMasuk),
          sumber: currentItem.sumber,
          penyedia: currentItem.penyedia,
          total_masuk: currentItem.total_masuk,
          total_keluar: currentItem.total_keluar,
          stok_ready: currentItem.stok_ready,
          noFaktur: currentItem.noFaktur,
          harga: currentItem.harga,
          tahunPengadaan: currentItem.tahunPengadaan,
        });
        existingItem.stok_ready += currentItem.stok_ready;
      } else {
        accumulator.push({
          id_dataObat: currentItem.id_dataObat,
          nama: currentItem.nama,
          satuan: currentItem.satuan,
          list_masuk: [
            {
              id_masuk: currentItem.id_masuk,
              id_masukGudang: currentItem.id_masukGudang,
              tanggalMasuk: formatDate(currentItem.tanggalMasuk),
              ed: formatDate(currentItem.tanggalMasuk),
              sumber: currentItem.sumber,
              penyedia: currentItem.penyedia,
              total_masuk: currentItem.total_masuk,
              total_keluar: currentItem.total_keluar,
              stok_ready: currentItem.stok_ready,
              noFaktur: currentItem.noFaktur,
              harga: currentItem.harga,
              tahunPengadaan: currentItem.tahunPengadaan,
            },
          ],
          stok_ready: currentItem.stok_ready,
        });
      }

      return accumulator;
    }, []);
  }

  function generateInvoiceNumber() {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    const invoiceNumber =
      `INV${year}${month}${day}${hours}${minutes}${seconds}` +
      "A" +
      id_tujuan +
      "T" +
      idLokasi +
      "min";
    return invoiceNumber;
  }

  const [noFaktur, setNoFaktur] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setUser(user);
      setUid(user.uid);
      setIdLokasi(user.idLokasi);
      logApp(user.uid, "Permintaan");
    }
  }, []);

  useEffect(() => {
    if (idLokasi) {
      ambilDataLokasi();
    }
  }, [idLokasi]);

  useEffect(() => {
    if (id_tujuan) {
      ambilDataObat();
    }
  }, [id_tujuan]);

  const ambilDataObat = () => {
    axios({
      method: "get",
      url:
        id_tujuan == 1
          ? STOCK_GUDANG_TERSEDIA
          : STOCK_PUSKESMAS_TERSEDIA + id_tujuan,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          const data = mergeRows(response.data.data);
          setList(data);

          setListTampil(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilDataLokasi = () => {
    axios({
      method: "get",
      url: LOKASI + "ambil/",
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          if (data.length > 0) {
            const fil = data.filter((item) => {
              return idLokasi == item.id;
            });

            const level = fil[0].level;

            if (level == "Puskesmas") {
              setPuskesmas(fil[0].lokasi);
              setUnit("");
              setId_tujuan(1);
              setId_asal(idLokasi);
            } else {
              const datafilter = data.filter((item) => {
                return item.id == fil[0].id_Puskesmas;
              });

              setId_tujuan(fil[0].id_Puskesmas);
              setId_asal(idLokasi);

              setUnit(fil[0].lokasi);

              setPuskesmas(datafilter[0].lokasi);
            }
            setLevel(level);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  const selectInfoAction = (item) => {
    setModalOpen2(true);
    setInfoSelected(item);
  };

  const validateStok = (value) => {
    setJumlah(value);
    if (value > stok) {
      setStokError("jumlah tidak lebih besar dari stok");
    } else {
      setStokError("");
    }
  };

  const selectAction = (item) => {
    setSelected(item);
    setNamaObat(item.nama);
    setId_dataObat(item.id_dataObat);

    setStok(item.stok);
    setSatuan(item.satuan);
    setModalOpen(true);
  };

  const handleSimpan = () => {
    if (firstTable.length < 1) {
      swal("Info", "Anda belum memasukan data obat", "info");
    } else {
      setModalDok(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!id_tujuan) {
      swal("Info", "Anda belum memilih tujuan Permintaan obat", "info");
      return;
    }

    if (stokError) {
      swal(
        "Info",
        "Jumlah tidak bisa lebih besar dari stok yang tersedia",
        "info"
      );
      return;
    }

    if (edit) {
      handleEditList();
      return;
    }

    const data = {};

    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    data.id = firstTable.length + 1;
    data.id_dataObat = id_dataObat;
    data.nama = namaObat;

    data.satuan = satuan;
    data.id_petugas = uid;
    data.stok = stok;

    data.id_tujuan = id_tujuan;
    data.labelTujuan = id_tujuan == 1 ? "Gudang" : puskesmas;
    data.tanggal = date_string;

    data.noFaktur = noFaktur;

    data.jumlah = jumlah;

    setFirstTable((prevArray) => [...prevArray, data]);
    onModalClose();
  };

  const onModalClose = () => {
    setId_dataObat("");

    setJumlah("");

    setSatuan("");
    setNamaObat("");
    setStok(0);
    setStokError("");
    setEdit(false);
    setIdEdit();

    setModalOpen(false);
  };

  const openEdit = (item) => {
    setEdit(true);
    setIdEdit(item.id);

    setNamaObat(item.nama);
    setId_dataObat(item.id_dataObat);

    setSatuan(item.satuan);
    setStok(item.stok);

    setId_petugas(item.id_petugas);
    setJumlah(item.jumlah);
    setTanggal(new Date(item.tanggal));

    setSatuan(item.satuan);

    setModalOpen(true);
  };

  const handleEditList = () => {
    if (!idEdit) {
      return;
    }

    const data = firstTable;
    setFirstTable([]);
    const index = data.findIndex((item) => item.id === idEdit);

    if (index !== -1) {
      data[index].jumlah = jumlah;
    }

    setFirstTable(data);
    onModalClose();
  };

  const searchItems = (e) => {
    const src = e.target.value;
    if (src == "") {
      setListTampil(list);
    } else {
      const filteredItems = list.filter((item) =>
        item.nama.toUpperCase().includes(src.toUpperCase())
      );
      setListTampil(filteredItems);
    }
  };

  const handleSimpanDB = () => {
    if (!dokumenSurat) {
      swal("Gagal", "Surat permintaan belum diupload", "error");
      setProsesSimpan(false);
      return;
    }

    const dataKu = firstTable;

    const dataOk = dataKu.map((item) => {
      return [
        item.id_dataObat,
        idLokasi,
        item.id_tujuan,
        item.id_petugas,
        item.jumlah,
        item.noFaktur,
        item.tanggal,
      ];
    });

    const formData = new FormData();

    formData.append("photo", dokumenSurat);
    formData.append("data", JSON.stringify(dataOk));

    axios({
      method: "post",
      url: PERMINTAAN + "input/items",
      timeout: API_TIMEOUT,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${""}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setFirstTable([]);
          setNoFaktur(generateInvoiceNumber());
          setModalDok(false);
          setDokumenSurat("");

          setId_tujuan("");
          setTanggal("");
          setNoFaktur("");
          ambilDataObat();
          setProsesSimpan(false);
          swal("Success", "Data berhasil disimpan", "success");
        }
      })
      .catch((error) => {
        // ERROR
        setProsesSimpan(false);
        swal("Gagal", error, "error");
      });
  };

  const handleDelete = (id) => {
    const data = firstTable.filter((item) => {
      return item.id != id;
    });

    setFirstTable(data);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row>
            <Col>
              <FormGroup>
                <CustomInput
                  type="switch"
                  id="allDay"
                  name="customSwitch"
                  label="Tampilkan Dafar Obat"
                  checked={tampilDaftarObat}
                  onChange={(e) => setTampilDaftarObat(e.target.checked)}
                  inline
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="gutter mb-4">
            {tampilDaftarObat && (
              <Col xs={12} md={firstTable.length > 0 ? 4 : 6}>
                <Widget className="widget-p-md">
                  <div className="headline-2">
                    {" "}
                    {level == "Puskesmas" ? (
                      <div>
                        Daftar obat tersedia # Gudang
                        <code>{"Dinkes"}</code>
                      </div>
                    ) : (
                      <div>
                        Daftar obat tersedia # Puskesmas{" "}
                        <code>{puskesmas}</code>
                      </div>
                    )}
                  </div>
                  <div className="mt-2 mb-3 body-3 muted">
                    Lakukan pencarian data obat tersedia
                  </div>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search-input1"
                        onChange={(e) => searchItems(e)}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="default">Search</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <div style={containerStyle}>
                    <ul className="list-group">
                      {listTampil ? (
                        listTampil.map((item) => (
                          <li className="list-group-item" key={item.id}>
                            <div className={s.listBadge}>
                              {level == "Puskesmas" ? (
                                <div>
                                  {item.nama}{" "}
                                  <div>
                                    <span
                                      style={{ color: "green", fontSize: 12 }}
                                    >
                                      Ready stock
                                    </span>
                                    <code>{item.stok_ready}</code> {item.satuan}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {item.nama}{" "}
                                  {item.stok_ready > 0 ? (
                                    <div>
                                      <span
                                        style={{ color: "green", fontSize: 12 }}
                                      >
                                        Ready stock
                                      </span>
                                      <code>{item.stok_ready}</code>{" "}
                                      {item.satuan}
                                    </div>
                                  ) : (
                                    <span
                                      style={{ color: "red", fontSize: 12 }}
                                    >
                                      Not Ready
                                    </span>
                                  )}
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Badge
                                  color="warning"
                                  style={{
                                    cursor: "pointer",
                                    position: "relative",
                                  }}
                                  onClick={() => selectInfoAction(item)}
                                >
                                  {item.list_masuk.length > 1 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        backgroundColor: "#24ae24",
                                        position: "absolute",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 5,
                                        borderRadius: "50%",
                                        left: -10,
                                        top: -10,
                                        width: 17,
                                        height: 17,
                                        color: "white",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => ""}
                                    >
                                      <text style={{ fontSize: 8 }}>
                                        {" "}
                                        {item.list_masuk.length}
                                      </text>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                  <i className="fa fa-info mr-2" />
                                </Badge>
                                <div style={{ marginLeft: 5 }}></div>
                                <Badge
                                  color="primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (!id_tujuan || !tanggal) {
                                      swal(
                                        "Info",
                                        "Anda belum memilih tanggal Permintaan obat",
                                        "info"
                                      );
                                      return;
                                    }

                                    selectAction(item);
                                  }}
                                >
                                  <i className="fa fa-plus mr-2" />
                                </Badge>
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div />
                      )}
                    </ul>
                  </div>
                </Widget>
              </Col>
            )}
            <Col
              xs={12}
              md={!tampilDaftarObat ? 12 : firstTable.length > 0 ? 8 : 6}
              className="mt-4 mt-md-0"
            >
              <Widget className="widget-p-md">
                <div className="headline-2">Daftar permintaan obat</div>
                <Row>
                  <Col>
                    {" "}
                    <Label>
                      <span>No </span>
                      <code>{noFaktur}</code>
                    </Label>
                  </Col>
                  <Col>
                    <Label for="endDate">Tanggal Permintaan</Label>
                    <Flatpickr
                      id="endDate"
                      tag={Flatpickr}
                      name="endDate"
                      className="form-control"
                      onChange={(date) => {
                        setTanggal(date[0]);
                        setNoFaktur(generateInvoiceNumber());
                      }}
                      value={tanggal}
                      options={{
                        enableTime: allDay === false,
                        dateFormat: "d-m-Y",
                      }}
                      required
                    />
                  </Col>
                </Row>

                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className="checkbox checkbox-primary">
                            <input
                              className="styled"
                              id="checkbox100"
                              type="checkbox"
                            />
                            <label for="checkbox100" />
                          </div>
                        </th>
                        <th className="w-25">Obat</th>
                        <th className="w-25">Tujuan</th>
                        <th className="w-25">Jumlah</th>
                        <th className="w-25">Tanggal</th>
                        <th className="w-25">Nomor Faktur</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id={item.nama}
                                  className="styled"
                                  type="checkbox"
                                />
                                <Label for={item.nama} />
                              </div>
                            </td>
                            <td className="d-flex align-items-center">
                              <span className="ml-3">{item.nama}</span>
                            </td>

                            <td>{item.labelTujuan}</td>
                            <td>{numberWithCommas(item.jumlah)}</td>

                            <td>{item.tanggal}</td>

                            <td>{item.noFaktur}</td>
                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="primary"
                                size="xs"
                                onClick={() => {
                                  openEdit(item);
                                }}
                              >
                                edit
                              </Button>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="danger"
                                size="xs"
                                onClick={() => handleDelete(item.id)}
                              >
                                delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
                <Button color="primary" onClick={handleSimpan}>
                  Upload Surat
                </Button>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal isOpen={""}>
        <ModalBody>
          <FormGroup>
            <Label for="bar">{selected && selected.asupan}</Label>
            <Row>
              <Col md={8}>
                <InputGroup>
                  <Input type="text" id="bar" value={jumlah} />
                  <InputGroupAddon addonType="append">
                    <ButtonGroup>
                      <Button
                        color="secondary-red"
                        className="px-3 border-radius-0"
                        onClick={() => setJumlah(() => jumlah - 1)}
                      >
                        <i className={`fa fa-minus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color="secondary-yellow"
                        className="px-3"
                        onClick={() => setJumlah(() => jumlah + 1)}
                      >
                        <i className={`fa fa-plus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color="success"
                        className="px-3"
                        onClick={() => setJumlah(0)}
                      >
                        <i className={`fa fa-refresh ${s.btnIcon}`} />
                      </Button>
                    </ButtonGroup>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={4}>
                <Input
                  type="text"
                  id="disabled-input"
                  disabled="disabled"
                  value={selected && selected.ukuran}
                />
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <div className="mx-auto">
            <Button
              className="btn-rounded btn-outline-secondary mr-3"
              outline
              onClick={() => {
                setModalOpen(false);
                setSelected(null);
                setJumlah(0);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-rounded"
              color="primary"
              onClick={() => handleSimpan()}
            >
              Simpan
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalOpen}
        toggle={() => onModalClose()}
        className="sidebar-lg"
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader className="mb-1" tag="div" close={CloseBtn}>
          <h5 className="modal-title">{"Add"}</h5>
        </ModalHeader>
        <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="namaobat">
                Nama Obat <span className="text-danger">*</span>
              </Label>
              <Input
                id="namaobat"
                name="namaobat"
                placeholder="nama obat"
                value={namaObat}
                required
              />
            </FormGroup>

            <FormGroup></FormGroup>

            <FormGroup>
              <Label for="jml">
                {stokError ? (
                  <span className="label muted" style={{ color: "red" }}>
                    {stokError}
                  </span>
                ) : (
                  <span className="label muted">
                    Jumlah dalam <code>{satuan}</code>
                  </span>
                )}
              </Label>
              <Input
                id="jml"
                type="number"
                value={jumlah}
                placeholder="jumlah"
                onChange={(e) => validateStok(e.target.value)}
                style={{ borderColor: stokError ? "red" : "initial" }}
                required
              />
            </FormGroup>

            <FormGroup>
              <Label for="nofaktur">Nomor Faktur</Label>
              <Input
                id="nofaktur"
                value={noFaktur}
                placeholder="no faktur"
                required
              />
            </FormGroup>
            <FormGroup>
              <Fragment>
                <Button
                  className="mr-3 btn-rounded"
                  type="submit"
                  color="primary"
                >
                  {edit ? "Edit" : "Add"}
                </Button>
                <Button
                  className="btn-rounded"
                  color="secondary"
                  type="reset"
                  onClick={() => onModalClose(false)}
                  outline
                >
                  Cancel
                </Button>
              </Fragment>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalOpen2} toggle={() => setModalOpen2(false)}>
        <ModalBody>
          <Widget className="widget-p-md">
            {infoSelected && (
              <div>
                <Row>
                  <Col>Nama Obat</Col>
                  <Col>
                    <div style={{ color: "red" }}>{infoSelected.nama}</div>
                  </Col>
                </Row>

                <Row>
                  <Col>Satuan Terkecil</Col>
                  <Col>{infoSelected.satuan}</Col>
                </Row>

                <ul className="list-group">
                  {infoSelected.list_masuk.map((item, index) => (
                    <li className="list-group-item" key={index}>
                      <div className={s.listBadge}>
                        <div>
                          Tanggal masuk{" "}
                          <Button size="xs" color="secondary-red">
                            {item.tanggalMasuk}{" "}
                          </Button>{" "}
                          Stok Ready
                          <code>{item.stok_ready}</code> {infoSelected.satuan}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        ></div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Widget>
        </ModalBody>
        <ModalFooter>
          <div className="mx-auto">
            <Button
              className="btn-rounded"
              color="success"
              onClick={() => setModalOpen2(false)}
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal size="lg" isOpen={modalDok} toggle={() => setModalDok(false)}>
        <ModalHeader
          className="mb-1"
          tag="div"
          close={() => setModalDok(false)}
        >
          <h5 className="modal-title">{"Upload Surat Permintaan"}</h5>
        </ModalHeader>

        <ModalBody>
          <Widget className="widget-p-md">
            <FormGroup row>
              <Label lg="3" className="text-md-right mt-3">
                File Surat (Pdf)
              </Label>
              <Col lg="9">
                <div className="input-group mb-4 px-2 py-2 rounded-pill bg-light-gray">
                  <input
                    id="upload"
                    type="file"
                    accept="application/pdf"
                    onChange={onChange1}
                    className={`form-control border-0 ${s.upload}`}
                  />
                  <label
                    id="upload-label"
                    htmlFor="upload"
                    className={`font-weight-light text-muted ${s.uploadLabel}`}
                  >
                    {dokumenSurat ? dokumenSurat.name : "Pilih file"}
                  </label>
                  <div className="input-group-append">
                    <label
                      htmlFor="upload"
                      className="btn btn-light m-0 rounded-pill px-4"
                    >
                      <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                    </label>
                  </div>
                </div>
              </Col>
            </FormGroup>
          </Widget>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <div className="mx-auto">
              <Button
                className="btn-rounded"
                color="secondary-red"
                style={{ marginRight: 10 }}
                onClick={() => setModalDok(false)}
              >
                Cancel
              </Button>
              {prosesSimpan ? (
                <Button>
                  {" "}
                  <Spinner size="sm" /> Simpan...
                </Button>
              ) : (
                <Button
                  className="btn-rounded"
                  color="primary"
                  onClick={() => {
                    setProsesSimpan(true);
                    handleSimpanDB();
                  }}
                >
                  Simpan
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

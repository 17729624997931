import { useEffect, useState } from 'react';
import axios from 'axios';
import { GET_CONTACT } from '../utils';

// Ganti dengan URL backend Anda

export default function useDatabaseStorage_contact(key, initialValue) {
  const [value, setValue] = useState(initialValue);

  // Memuat data dari backend saat pertama kali
  useEffect(() => {
    axios
      .get(`${GET_CONTACT}`)
      .then((response) => {
        if (response.data) {
          setValue(response.data);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [key]);

  // Menyimpan data kembali ke backend setiap kali nilai berubah
  // useEffect(() => {
  //   axios
  //     .post(`${BACKEND_URL}/${key}`, { value })
  //     .catch((error) => console.error('Error saving data:', error));
  // }, [key, value]);
  // console.log('contact ', value);
  return [value, setValue];
}

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Form,
  Input,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget.js';
import './table.css';

import optionsIcon from '../../assets/tables/optionsIcon.svg';
import printerIcon from '../../assets/tables/printerIcon.svg';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import s from './Tables.module.scss';

import {
  API_TIMEOUT,
  DATA_OBAT,
  KELUAR,
  LOKASI,
  REKAPAN,
} from '../../utils/index.js';
import axios from 'axios';

import { useDispatch } from 'react-redux';

import Success from '../../assets/success.png';
import { formatDate, logApp, numberWithCommas } from '../../utils/func.js';
import Select, { components } from 'react-select';
import swal from 'sweetalert';
import { dateTime } from '../../utils/func.js';
import Stop from '../../assets/stop.png';

const TableDataObat = function () {
  const [firstTable, setFirstTable] = useState([]);
  const [secondTable, setSecondTable] = useState([]);
  const [lokasi, setLokasi] = useState('');
  const [level, setLevel] = useState('');
  const [puskesmas, setPuskesmas] = useState('');
  const [unit, setUnit] = useState('');

  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);

  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState('All');
  const [preFirstTable, setPreFirstTable] = useState([]);

  const [tahun, setTahun] = useState('2023');

  const [modalEdit, setModalEdit] = useState(false);

  const [edit, setEdit] = useState(false);

  const [currentUser, setCurrentUser] = useState();
  const [idLokasi, setIdLokasi] = useState('');
  const [status, setStatus] = useState(-1);
  const [namaObat, setNamaObat] = useState('');
  const [kategori, setKategori] = useState('');
  const [satuan, setSatuan] = useState('');
  const [satuanPengadaan, setSatuanPengadaan] = useState('');
  const [jumlahKonversi, setJumlahKonversi] = useState('');
  const [idObat, setIdObat] = useState('');

  const onModalClose = () => {
    setModalEdit(false);
    setEdit(false);
    setNamaObat('');
    setKategori('');
    setSatuan('');
    setJumlahKonversi('');
    setSatuanPengadaan('');
  };
  const getDataKeluar = (faktur) => {
    axios({
      method: 'get',
      url:
        level == 'Dinas'
          ? KELUAR + 'gudang/' + faktur
          : level == 'Puskesmas'
          ? KELUAR + 'puskesmas/' + faktur
          : KELUAR + 'unit/' + faktur,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          setSecondTable(data);

          //menyesuakian kolom dari permintaan sesuai dengan input dari list
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const listSatuan = [
    {
      label: '',
      value: 1,
    },

    {
      label: 'Ampul',
      value: 4,
    },
    {
      label: 'Botol',
      value: 5,
    },
    {
      label: 'Box/36',
      value: 6,
    },
    {
      label: 'Buah',
      value: 7,
    },
    {
      label: 'Dos',
      value: 8,
    },
    {
      label: 'Galon',
      value: 9,
    },
    {
      label: 'Kaplet',
      value: 10,
    },
    {
      label: 'Kapsul',
      value: 11,
    },
    {
      label: 'Lembar',
      value: 12,
    },
    {
      label: 'Nebul',
      value: 13,
    },
    {
      label: 'Pak',
      value: 14,
    },
    {
      label: 'Pasang',
      value: 15,
    },
    {
      label: 'Pcs',
      value: 16,
    },
    {
      label: 'Pen',
      value: 17,
    },
    {
      label: 'Pot',
      value: 18,
    },
    {
      label: 'Roll',
      value: 19,
    },
    {
      label: 'Set',
      value: 20,
    },
    {
      label: 'Suppo',
      value: 21,
    },
    {
      label: 'Tablet',
      value: 22,
    },
    {
      label: 'Tablet Sal',
      value: 23,
    },
    {
      label: 'Tube',
      value: 24,
    },
    {
      label: 'Vial',
      value: 25,
    },
  ];

  const openModalAdd = () => {
    setModalEdit(true);
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, 'Data Obat');
      setIdLokasi(currentUser.idLokasi);
    }
  }, []);

  const containerStyle = {
    width: '100%',
    height: '300px', // Atur tinggi sesuai kebutuhan Anda
    overflow: 'auto',
    /* Gaya tampilan scroll */
    scrollbarWidth: 'thin',
    scrollbarColor: '#888888 #f0f0f0',
  };

  const rubahKategori = (status) => {
    if (status) {
      if (status == 'All') {
        setFirstTable(preFirstTable);
      } else {
        const dd = preFirstTable.filter((item) => {
          return item.kategori == status;
        });

        setFirstTable(dd);
      }
    }
  };

  useEffect(() => {
    if (idLokasi) {
      ambilDataLokasi();
    }
  }, [idLokasi]);

  useEffect(() => {
    if (level) {
      ambilDataObat(idLokasi);
    }
  }, [level]);

  const ambilDataLokasi = () => {
    axios({
      method: 'get',
      url: LOKASI + 'ambil/',
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          if (data.length > 0) {
            const fil = data.filter((item) => {
              return idLokasi == item.id;
            });

            const level = fil[0].level;

            if (level == 'Puskesmas') {
              setPuskesmas(fil[0].lokasi);
              setUnit('');

              const datafilter = data.filter((item) => {
                return item.id_Puskesmas == fil[0].id;
              });
              let lok = [];
              for (var i = 1; i < datafilter.length; i++) {
                lok.push({
                  value: datafilter[i].id,
                  label: datafilter[i].lokasi,
                  color: 'primary',
                });
              }

              setLokasi(lok);
            } else {
              const datafilter = data.filter((item) => {
                return item.id == fil[0].id_Puskesmas;
              });

              setUnit(fil[0].lokasi);

              setPuskesmas(datafilter[0].lokasi);

              setLokasi([
                {
                  value: 74,
                  label: 'Pasien',
                  color: 'primary',
                },
              ]);
            }
            setLevel(level);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleDelete = (id) => {
    return;

    axios({
      method: 'delete',
      url: DATA_OBAT + id,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          ambilDataObat();
          setModalEdit(false);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleEdit = () => {
    const data = {};
    data.nama = namaObat;
    data.satuanTerkecil = satuan;
    data.konversiSatuan = satuanPengadaan;
    data.jumlahKonversi = jumlahKonversi;

    data.kategori = kategori;

    axios({
      method: 'patch',
      url: DATA_OBAT + idObat,
      timeout: API_TIMEOUT,
      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          ambilDataObat();
          setModalEdit(false);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  const handleAdd = () => {
    const data = {};
    data.nama = namaObat;
    data.satuanTerkecil = satuan;
    data.konversiSatuan = satuanPengadaan;
    data.jumlahKonversi = jumlahKonversi;

    data.kategori = kategori;

    axios({
      method: 'post',
      url: DATA_OBAT,
      timeout: API_TIMEOUT,
      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setModalEdit(false);
          ambilDataObat();
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  const handleData = () => {
    if (edit) {
      handleEdit();
    } else {
      handleAdd();
    }
  };

  const ambilDataObat = (id) => {
    axios({
      method: 'get',
      url: DATA_OBAT + 'ambil',
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          setPreFirstTable(data);
          setFirstTable(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const listKategori = [
    { value: -1, kategori: 'All' },
    { value: 0, kategori: 'OBAT' },
    { value: 1, kategori: 'BMHP' },
    { value: 2, kategori: 'VAKSIN' },
  ];

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curKategori = listKategori.filter((item) => {
      return item.kategori == select;
    });
    setStatus(curKategori[0].value);
    rubahKategori(curKategori[0].kategori);
    setTahun(curKategori[0].kategori);

    // ambilDataBantuanKategori(currentBantuan, curAnak[0].tahun);
  };

  const OptionComponent = ({ data, ...props }) => {
    return (
      <components.Option {...props}>
        <div className={`bullet bullet-${data.color} bullet-sm mr-2`}></div>
        {data.label}
      </components.Option>
    );
  };

  const pageSize = 100;
  const pageSize2 = 100;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const openModal = (data) => {
    setModalEdit(true);

    setEdit(true);
    setNamaObat(data.nama);

    const kat = listKategori2.filter((item) => {
      return item.label == data.kategori;
    });

    setKategori(kat);

    const satuanTer = listSatuan.filter((item) => {
      return item.label == data.satuanTerkecil;
    });

    setSatuan(satuanTer);

    setJumlahKonversi(data.jumlahKonversi);
    const satuanPen = listSatuan.filter((item) => {
      return item.label == data.konversiSatuan;
    });
    setSatuanPengadaan(satuanPen);
  };

  const listKategori2 = [
    {
      value: 1,
      label: 'OBAT',
      color: 'primary',
    },
    {
      value: 2,
      label: 'BMHP',
      color: 'danger',
    },
    {
      value: 2,
      label: 'VAKSIN',
      color: 'success',
    },
  ];

  return (
    <div>
      <Row>
        <Col>
          <Row className='mb-4'>
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className='headline-2'>
                    Data Dasar <code>{'Obat'}</code>{' '}
                    <Badge color='primary'>{firstTable.length}</Badge>
                  </div>
                  <div>
                    {' '}
                    <ReactHTMLTableToExcel
                      table='neraca_sumber'
                      filename={'Data Dasar Obat'}
                      sheet='Data'
                      className='btnKu'
                    />
                  </div>

                  <div className='d-flex'>
                    <Col className='pr-grid-col' xs={4}>
                      <Label>Kategori</Label>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color='primary'>
                          {simpleDropdownValue2}
                        </DropdownToggle>

                        <DropdownMenu>
                          {listKategori &&
                            listKategori.map((item) => (
                              <DropdownItem
                                onClick={changeSelectDropdownSimple2}
                              >
                                {item.kategori}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </div>
                  <div className='d-flex'>
                    <div>
                      <Button color='success' onClick={openModalAdd}>
                        Tambah
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='widget-table-overflow'>
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    id='neraca_sumber'
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>No</th>
                        <th className='w-25'>Nama Obat</th>
                        <th className='w-25'>KATEGORI</th>

                        <th className='w-25'>SATUAN TERKECIL</th>
                        <th className='w-25'>SATUAN PENGADAAN</th>

                        <th className='w-25'>JUMLAH KONVERSI</th>

                        <th className='w-25'>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item, index) => (
                          <tr key={uuidv4()}>
                            <td>{index + 1}</td>
                            <td className='d-flex align-items-center'>
                              <span className='ml-3'>{item.nama}</span>
                            </td>

                            <td>{item.kategori}</td>

                            <td>{item.satuanTerkecil}</td>
                            <td>{item.konversiSatuan}</td>
                            <td>{item.jumlahKonversi}</td>

                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color='primary'
                                style={{ position: 'relative' }}
                                size='xs'
                                onClick={() => {
                                  setIdObat(item.id);
                                  openModal(item);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color='danger'
                                style={{ position: 'relative' }}
                                size='xs'
                                onClick={() => {
                                  handleDelete(item.id);
                                }}
                              >
                                Delete
                              </Button>
                            </td>

                            <td></td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className='pagination-borderless'
                    aria-label='Page navigation example'
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href='#top'
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href='#top'
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href='#top'
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        isOpen={modalEdit}
        toggle={() => onModalClose()}
        className='sidebar-lg'
        contentClassName='p-0'
        modalClassName='modal-slide-in event-sidebar'
      >
        <ModalHeader className='mb-1' tag='div'>
          <h5 className='modal-title'>{edit ? 'Edit' : 'Add'}</h5>
        </ModalHeader>
        <ModalBody className='flex-grow-1 pb-sm-0 pb-3'>
          <FormGroup>
            <Label for='namaobat'>
              Nama Obat <span className='text-danger'>*</span>
            </Label>
            <Input
              id='namaobat'
              name='namaobat'
              placeholder='nama obat'
              value={namaObat}
              onChange={(e) => setNamaObat(e.target.value)}
              required
            />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup style={{ zIndex: 15 }}>
                <Label>Kategori</Label>

                <Select
                  id='normal-field'
                  className='react-select'
                  classNamePrefix='select'
                  options={listKategori2}
                  isClearable={false}
                  value={kategori}
                  onChange={(data) => setKategori([data])}
                  components={{
                    Option: OptionComponent,
                  }}
                  required
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup style={{ zIndex: 10 }}>
                <Label>Satuan Terkecil</Label>

                <Select
                  id='normal-field'
                  className='react-select'
                  classNamePrefix='select'
                  options={listSatuan}
                  isClearable={false}
                  value={satuan}
                  onChange={(data) => setSatuan([data])}
                  components={{
                    Option: OptionComponent,
                  }}
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ zIndex: 5 }}>
                <Label>Satuan Pengadaan</Label>

                <Select
                  id='normal-field'
                  className='react-select'
                  classNamePrefix='select'
                  options={listSatuan}
                  isClearable={false}
                  value={satuanPengadaan}
                  onChange={(data) => setSatuanPengadaan([data])}
                  components={{
                    Option: OptionComponent,
                  }}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label for='nofaktur'>
              Jumlah Konversi Satuan Pengadaan ke Satuan Terkecil
            </Label>
            <Input
              id='nofaktur'
              type='number'
              value={jumlahKonversi}
              placeholder='jumlah'
              onChange={(e) => setJumlahKonversi(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Button
              className='mr-3 btn-rounded'
              color='primary'
              onClick={handleData}
            >
              {edit ? 'Edit' : 'Add'}
            </Button>
            <Button
              className='btn-rounded'
              color='secondary'
              type='reset'
              onClick={() => onModalClose(false)}
              outline
            >
              Cancel
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TableDataObat;

import React from "react";
import { Badge, Col, Row, Spinner } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import Widget from "../../../components/Widget/Widget.js";
import {
  Form,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
  Progress,
  CustomInput,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Select, { components } from "react-select";

import Flatpickr from "react-flatpickr";

import { numberWithCommas } from "../../../utils/func.js";

import DatePicker from "react-datepicker";
import s from "./Lists.module.scss";

import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useParams } from "react-router";
import {
  API_TIMEOUT,
  STOCK_GUDANG_TERSEDIA,
  LOKASI,
  KELUAR_GUDANG,
  FAKTUR_PERMINTAAN,
  PERMINTAAN,
  DOMAIN_SIMOBAT,
} from "../../../utils/index.js";

import swal from "sweetalert";

import { logApp } from "../../../utils/func.js";
export default function KeluarStock() {
  const [list, setList] = useState([]);
  const [listTampil, setListTampil] = useState([]);

  const [allDay, setAllDay] = useState(false);

  const [firstTable, setFirstTable] = useState([]);
  const [secondTable, setSecondTable] = useState([]);

  const [stok, setStok] = useState(0);

  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tampilDaftarObat, setTampilDaftarObat] = useState(true);
  const [modalPermintaan, setModalPermintaan] = useState(false);
  const [idFaktur, setIdFaktur] = useState("");

  const [modalOpen2, setModalOpen2] = useState(false);
  const [infoSelected, setInfoSelected] = useState();

  const [edit, setEdit] = useState(false);

  const [lokasi, setLokasi] = useState("");
  const [catatan, setCatatan] = useState("");
  const [idTolak, setIdTolak] = useState("");
  const [modalTolak, setModalTolak] = useState(false);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const pageSize = 10;
  const pageSize2 = 10;

  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const closeModal = () => {
    setModalPermintaan(false);
  };

  const { makan } = useParams();
  let kategoriKu = [];

  if (kategoriKu.length == 1) kategoriKu = kategoriKu[0];
  if (kategoriKu.length == 2) kategoriKu = kategoriKu[0] + " " + kategoriKu[1];

  const containerStyle = {
    width: "100%",
    height: "300px", // Atur tinggi sesuai kebutuhan Anda
    overflow: "auto",
    /* Gaya tampilan scroll */
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
  };

  const radialChartStyle = {
    top: "50%",
    left: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px",
  };

  const [selected, setSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [jumlah, setJumlah] = useState(0);

  const [uid, setUid] = useState();
  const [user, setUser] = useState();

  const [id_dataObat, setId_dataObat] = useState("");

  const [totalKeluar, setTotalKeluar] = useState("");
  const [satuan, setSatuan] = useState("");
  const [namaObat, setNamaObat] = useState("");
  const [idEdit, setIdEdit] = useState();

  const [id_Masuk, setId_Masuk] = useState("");
  const [id_tujuan_Keluar, setId_Tujuan_Keluar] = useState("");
  const [id_petugasKeluar, setId_petugasKeluar] = useState("");
  const [tanggalKeluar, setTanggalKeluar] = useState("");
  const [stokError, setStokError] = useState("");
  const [permintaan, setPermintaan] = useState(false);
  const [jumlahFakturMinta, setJumlahFakturMinta] = useState(0);

  const [prosesSimpan, setProsesSimpan] = useState(false);

  const [idLokasi, setIdLokasi] = useState(1);

  const CloseBtn = (
    <i
      className="eva eva-close cursor-pointer"
      onClick={() => onModalClose()}
    />
  );

  function generateInvoiceNumber(lok) {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    const invoiceNumber =
      `INV${year}${month}${day}${hours}${minutes}${seconds}` +
      "A1T" +
      lok +
      "kel";
    return invoiceNumber;
  }

  const [noFaktur, setNoFaktur] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setUser(user);
      setUid(user.uid);
      logApp(user.uid, "Stock Gudang Keluar");
    }
  }, []);

  useEffect(() => {
    ambilDataObat();
    ambilDataLokasi();
    ambilJumlahFakturPermintaan();
  }, []);

  const selectInfoAction = (item) => {
    setModalOpen2(true);
    setInfoSelected(item);
  };

  const ambilDataObat = () => {
    axios({
      method: "get",
      url: STOCK_GUDANG_TERSEDIA,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setList(response.data.data);
          setListTampil(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilJumlahFakturPermintaan = () => {
    axios({
      method: "get",
      url: FAKTUR_PERMINTAAN + "ambil/" + idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          setJumlahFakturMinta(response.data.data.length);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilFakturPermintaan = () => {
    axios({
      method: "get",
      url: FAKTUR_PERMINTAAN + "ambil/" + idLokasi,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          setSecondTable(response.data.data);

          setModalPermintaan(true);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  //masuk list stok keluar dari permintaan

  const getFakturPermintaan = () => {
    if (!tanggalKeluar) {
      swal("Info", "Tanggal pengeluaran harus diisi terlebih dahulu", "info");
      return;
    }
    ambilFakturPermintaan();
    setPermintaan(true);
  };

  const getPermintaan = (item) => {
    setIdFaktur(item.id);

    axios({
      method: "get",
      url: PERMINTAAN + item.noFaktur,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const puskes = lokasi.filter((itemKu) => {
            return itemKu.value == item.id_asal;
          });

          //menyesuakian kolom dari permintaan sesuai dengan input dari list
          setId_Tujuan_Keluar(puskes);
          const nomorFak = generateInvoiceNumber(puskes[0].value);
          setNoFaktur(nomorFak);
          const data = response.data.data;
          const tk = { totalKeluar: 0 };
          const ket = { ket: "permintaan" };
          let date_string = "";
          if (tanggalKeluar) {
            const tgl = new Date(tanggalKeluar);
            const year = tgl.getFullYear();
            const month = String(tgl.getMonth() + 1).padStart(2, "0");
            const day = String(tgl.getDate()).padStart(2, "0");
            date_string = `${year}-${month}-${day}`;
          }
          for (let i = 0; i < data.length; i++) {
            let id = { id: i + 1 };
            let namaObat = { namaObat: data[i].nama };
            let tanggalKeluar = { tanggalKeluar: date_string };

            let stokKu = 0;
            let idMasuk = "";
            const dataobat = listTampil.filter((itemKu) => {
              return data[i].id_dataObat == itemKu.id_dataObat;
            });

            if (dataobat.length > 0) {
              stokKu = dataobat[0].stok_ready;
              idMasuk = dataobat[0].id_masuk;
            }
            let stok = { stok: stokKu };
            let id_Masuk = { id_Masuk: idMasuk };
            let noFakturKu = { noFaktur: nomorFak };
            let idUser = { id_petugasKeluar: uid };

            data[i] = {
              ...data[i],
              ...tk,
              ...ket,
              ...id,
              ...namaObat,
              ...tanggalKeluar,
              ...stok,
              ...id_Masuk,
              ...noFakturKu,
              ...idUser,
            };
          }

          setFirstTable(data);
          setModalPermintaan(false);
          setTampilDaftarObat(false);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilDataLokasi = () => {
    axios({
      method: "get",
      url: LOKASI + "ambil/",
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          if (data.length > 0) {
            const datafilter = data.filter((item) => {
              return item.level == "Puskesmas";
            });
            const lokasiKu = datafilter.map((item) => {
              return { value: item.id, label: item.lokasi, color: "primary" };
            });

            setLokasi(lokasiKu);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const validateStok = (value) => {
    setTotalKeluar(value);
    if (value > stok) {
      setStokError("jumlah tidak lebih besar dari stok");
    } else {
      setStokError("");
    }
  };

  //menambahkan list ke dalam table first table dari list data obat
  const selectAction = (item) => {
    if (!tanggalKeluar || !id_tujuan_Keluar) {
      swal(
        "Info",
        "Tanggal pengeluaran dan Tujuan obat keluar harus diisi terlebih dahulu",
        "info"
      );
      return;
    }
    setSelected(item);
    setNamaObat(item.nama);
    setId_dataObat(item.id_dataObat);
    setId_Masuk(item.id_masuk);
    setStok(item.stok_ready);
    setSatuan(item.satuan);
    setModalOpen(true);
  };

  const handleSimpan = () => {
    if (firstTable.length < 1) {
      swal("Info", "Anda belum memasukan data obat", "info");
    } else {
      setProsesSimpan(true);
      handleSimpanDB();
    }
  };

  const formatDate = (tglKu) => {
    const tgl = new Date(tglKu);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${day}-${month}-${year}`;
    return date_string;
  };

  //masuk list stok keluar dari list Daftar Obat
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!id_tujuan_Keluar) {
      swal("Info", "Anda belum memilih tujuan keluar obat", "info");
      return;
    }

    if (stokError) {
      swal(
        "Info",
        "Jumlah tidak bisa lebih besar dari stok yang tersedia",
        "info"
      );
      return;
    }

    if (edit) {
      handleEditList();
      return;
    }

    const data = {};

    const tgl = new Date(tanggalKeluar);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;

    data.id = firstTable.length + 1;
    data.id_dataObat = id_dataObat;
    data.nama = namaObat;
    data.id_Masuk = id_Masuk;
    data.satuan = satuan;
    data.id_petugasKeluar = uid;
    data.stok = stok;
    data.ket = "listDO";

    data.id_tujuan_Keluar = id_tujuan_Keluar[0].value;
    data.lokasi = id_tujuan_Keluar[0].label;
    data.tanggalKeluar = date_string;

    data.noFaktur = noFaktur;
    data.jumlahMinta = 0;
    data.totalKeluar = totalKeluar;

    setFirstTable((prevArray) => [...prevArray, data]);

    onModalClose();
  };

  const onModalClose = () => {
    setId_dataObat("");
    setId_petugasKeluar("");
    setTotalKeluar("");
    setId_Masuk("");
    setSatuan("");
    setNamaObat("");
    setStok(0);
    setStokError("");
    setEdit(false);
    setIdEdit();

    setModalOpen(false);
  };

  const openEdit = (item) => {
    setEdit(true);
    setIdEdit(item.id);

    setNamaObat(item.nama);
    setId_dataObat(item.id_dataObat);
    setId_Masuk(item.id_Masuk);
    setSatuan(item.satuan);
    setStok(item.stok);

    setId_petugasKeluar(item.id_petugasKeluar);
    setTotalKeluar(item.totalKeluar);
    setTanggalKeluar(item.tanggalKeluar ? new Date(item.tanggalKeluar) : "");

    const lok = lokasi.filter((itemKu) => {
      return itemKu.value == item.id_tujuan_Keluar;
    });
    setId_Tujuan_Keluar(lok);
    setSatuan(item.satuan);

    setModalOpen(true);
  };

  const handleEditList = () => {
    if (!idEdit) {
      return;
    }

    if (!tanggalKeluar) {
      swal("Gagal", "Tanggal Keluar belum diisi", "error");
      return;
    }

    const data = firstTable;
    setFirstTable([]);
    const index = data.findIndex((item) => item.id === idEdit);

    if (index !== -1) {
      data[index].totalKeluar = totalKeluar;
    }

    setFirstTable(data);
    onModalClose();
  };

  const searchItems = (e) => {
    const src = e.target.value;
    if (src == "") {
      setListTampil(list);
    } else {
      const filteredItems = list.filter((item) =>
        item.nama.toUpperCase().includes(src.toUpperCase())
      );
      setListTampil(filteredItems);
    }
  };

  const handleSimpanDB = () => {
    const dataKu = firstTable;
    let cek = true;
    dataKu.map((item) => {
      if (item.totalKeluar == 0) {
        cek = false;
      }
    });
    if (!cek) {
      swal(
        "Gagal",
        "Masih ada jumlah obat keluar yang belum terisi. Periksa kembali data inputan",
        "error"
      );
      return;
    }

    const dataFaktur = {
      id_petugasRes: uid,
      tanggalRes: dataKu[0].tanggalKeluar,
      id: idFaktur,
      status: 1,
    };

    const dataFakturKM = [
      dataKu[0].noFaktur,
      idLokasi,
      dataKu[0].id_tujuan_Keluar,
      uid,
      dataKu.length,
      0,
      "",
      dataKu[0].tanggalKeluar,
      "keluar",
      "",
      dataKu[0].fakturMinta ? dataKu[0].fakturMinta : "",
    ];
    const dataOk = dataKu.map((item) => {
      return [
        item.id_Masuk,
        item.totalKeluar,
        item.id_tujuan_Keluar,
        item.id_petugasKeluar,
        "",
        item.noFaktur,
        item.tanggalKeluar,
        item.tanggalKeluar,
        0,
        item.fakturMinta ? item.fakturMinta : "",
        item.id_dataObat,
        "",
      ];
    });

    const permin = permintaan ? 1 : 0;
    const dataKita = {
      data: dataOk,
      dataFaktur: dataFaktur,
      dataFakturKM: dataFakturKM,
      permintaan: permin,
    };

    axios({
      method: "post",
      url: KELUAR_GUDANG + "input/items",
      timeout: API_TIMEOUT,
      data: dataKita,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setFirstTable([]);

          setTanggalKeluar("");

          setId_Tujuan_Keluar("");
          setTampilDaftarObat(true);
          setNoFaktur("");
          setPermintaan(false);
          setIdFaktur("");

          ambilJumlahFakturPermintaan();
          ambilDataObat();
          setProsesSimpan(false);
          swal("Success", "Data berhasil disimpan", "success");
        }
      })
      .catch((error) => {
        // ERROR
        setProsesSimpan(false);
        swal("Gagal", "Data gagal tersimpan", "error");
      });
  };

  const openPDF = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  const handleDelete = (id) => {
    const data = firstTable.filter((item) => {
      return item.id != id;
    });

    setFirstTable(data);
  };

  const handleTolak = () => {
    const data = {
      tanggalRes: formatDateDB(tanggalKeluar),
      status: 3,
      id_petugas: uid,
      catatan: catatan,
    };

    axios({
      method: "patch",
      url: FAKTUR_PERMINTAAN + "edit/" + idTolak,
      timeout: API_TIMEOUT,
      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setIdTolak("");
          setCatatan("");
          swal("Success", "Permintaan berhasil ditolak", "success");
          ambilJumlahFakturPermintaan();
          setModalPermintaan();
          setModalTolak(false);
        }
      })
      .catch((error) => {
        // ERROR

        swal("Gagal", "Data gagal tersimpan", "error");
      });
  };

  const tolakPermintaan = (item) => {
    setIdTolak(item.id);
    setModalTolak(true);
  };

  const formatDateDB = (tglKu) => {
    const tgl = new Date(tglKu);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, "0");
    const day = String(tgl.getDate()).padStart(2, "0");
    const date_string = `${year}-${month}-${day}`;
    return date_string;
  };

  return (
    <div>
      <Row>
        <Col>
          <Row>
            <Col>
              <FormGroup>
                <CustomInput
                  type="switch"
                  id="allDay"
                  name="customSwitch"
                  label="Tampilkan Dafar Obat"
                  checked={tampilDaftarObat}
                  onChange={(e) => setTampilDaftarObat(e.target.checked)}
                  inline
                />
              </FormGroup>
            </Col>
            <FormGroup style={{ position: "relative" }}>
              <Button color="primary" onClick={() => getFakturPermintaan()}>
                Permintaan
              </Button>
              {jumlahFakturMinta ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#ff5252",
                    position: "absolute",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 5,
                    borderRadius: "50%",
                    right: 4,
                    top: -15,
                    width: 27,
                    height: 27,
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => getFakturPermintaan()}
                >
                  <text style={{ fontSize: 12 }}> {jumlahFakturMinta}</text>
                </div>
              ) : (
                <div />
              )}
            </FormGroup>
          </Row>
          <Row className="gutter mb-4">
            {tampilDaftarObat && (
              <Col xs={12} md={firstTable.length > 0 ? 4 : 6}>
                <Widget className="widget-p-md">
                  <div className="headline-2">Daftar obat tersedia</div>
                  <div className="mt-2 mb-3 body-3 muted">
                    Lakukan pencarian data obat tersedia
                  </div>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        type="text"
                        id="search-input1"
                        onChange={(e) => searchItems(e)}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="default">Search</Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <div style={containerStyle}>
                    <ul className="list-group">
                      {listTampil ? (
                        listTampil.map((item) => (
                          <li className="list-group-item" key={item.id}>
                            <div className={s.listBadge}>
                              <div>
                                {item.nama} <code>{item.stok}</code>{" "}
                                {item.satuan}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Badge
                                  color="warning"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => selectInfoAction(item)}
                                >
                                  <i className="fa fa-info mr-2" />
                                </Badge>
                                <div style={{ marginLeft: 5 }}></div>
                                <Badge
                                  color="primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => selectAction(item)}
                                >
                                  <i className="fa fa-plus mr-2" />
                                </Badge>
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <div />
                      )}
                    </ul>
                  </div>
                </Widget>
              </Col>
            )}
            <Col
              xs={12}
              md={!tampilDaftarObat ? 12 : firstTable.length > 0 ? 8 : 6}
              className="mt-4 mt-md-0"
            >
              <Widget className="widget-p-md">
                <div className="headline-2">Daftar obat Keluar</div>
                <Row>
                  <Col>
                    <Label>
                      <span>No </span>
                      <code>{noFaktur}</code>
                    </Label>
                    <FormGroup>
                      <Label for="endDate">Tanggal Pengeluaran</Label>
                      <Flatpickr
                        id="endDate"
                        tag={Flatpickr}
                        name="endDate"
                        className="form-control"
                        onChange={(date) => {
                          setTanggalKeluar(date[0]);
                        }}
                        value={tanggalKeluar}
                        options={{
                          enableTime: allDay === false,
                          dateFormat: "d-m-Y",
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="label">Puskesmas</Label>
                      <Select
                        id="label"
                        value={id_tujuan_Keluar}
                        options={lokasi}
                        className="react-select"
                        classNamePrefix="select"
                        isClearable={false}
                        onChange={(data) => {
                          if (firstTable.length == 0) {
                            setId_Tujuan_Keluar([data]);
                            setNoFaktur(generateInvoiceNumber(data.value));
                          } else {
                            swal(
                              "Info",
                              "Perubahan lokasi tujuan obat tidak bisa dilaksanakan apabila data tabel obat keluar sudah terisi",
                              "info"
                            );
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className="checkbox checkbox-primary">
                            <input
                              className="styled"
                              id="checkbox100"
                              type="checkbox"
                            />
                            <label for="checkbox100" />
                          </div>
                        </th>
                        <th className="w-25">Obat</th>
                        <th className="w-25">Tujuan</th>
                        <th className="w-25">Jumlah</th>
                        <th className="w-25">Permintaan</th>
                        <th className="w-25">Tanggal</th>
                        <th className="w-25">Akomodir</th>
                        <th className="w-25">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id={item.nama}
                                  className="styled"
                                  type="checkbox"
                                />
                                <Label for={item.nama} />
                              </div>
                            </td>
                            <td className="d-flex align-items-center">
                              <span className="ml-3">{item.nama}</span>
                            </td>

                            <td>{item.label ? item.label : item.lokasi}</td>
                            <td>{numberWithCommas(item.totalKeluar)}</td>
                            <td>{item.jumlahMinta}</td>
                            <td>{item.tanggalKeluar}</td>

                            <td>
                              {item.stok == 0 ? (
                                <code>Stok tidak tersedia</code>
                              ) : (
                                <div
                                  className={`d-flex justify-content-between`}
                                >
                                  <div className="d-flex flex-column mt-3">
                                    <div className={s.activity}>
                                      <p className="body-2">
                                        {Math.round(
                                          (item.totalKeluar /
                                            item.jumlahMinta) *
                                            100
                                        )}
                                        % akomodir{" "}
                                      </p>
                                      <p className="body-2">
                                        {item.totalKeluar}
                                        <span className="body-3 muted">
                                          /{item.jumlahMinta}
                                        </span>
                                      </p>
                                    </div>
                                    <Progress
                                      color={
                                        (item.totalKeluar / item.jumlahMinta) *
                                          100 >
                                        50
                                          ? "success"
                                          : "danger"
                                      }
                                      className="progress-xs"
                                      value={
                                        (item.totalKeluar / item.jumlahMinta) *
                                        100
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>
                              {item.stok > 0 ? (
                                <Button
                                  className={`${s.controlBtn} mx-2`}
                                  color="primary"
                                  size="xs"
                                  onClick={() => {
                                    openEdit(item);
                                  }}
                                >
                                  edit
                                </Button>
                              ) : (
                                <div />
                              )}
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color="danger"
                                size="xs"
                                onClick={() => handleDelete(item.id)}
                              >
                                delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
                {prosesSimpan ? (
                  <Button>
                    <Spinner size="sm" />
                    Simpan...
                  </Button>
                ) : (
                  <Button color="primary" onClick={handleSimpan}>
                    Simpan
                  </Button>
                )}
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal isOpen={""}>
        <ModalBody>
          <FormGroup>
            <Label for="bar">{selected && selected.asupan}</Label>
            <Row>
              <Col md={8}>
                <InputGroup>
                  <Input type="text" id="bar" value={jumlah} />
                  <InputGroupAddon addonType="append">
                    <ButtonGroup>
                      <Button
                        color="secondary-red"
                        className="px-3 border-radius-0"
                        onClick={() => setJumlah(() => jumlah - 1)}
                      >
                        <i className={`fa fa-minus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color="secondary-yellow"
                        className="px-3"
                        onClick={() => setJumlah(() => jumlah + 1)}
                      >
                        <i className={`fa fa-plus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color="success"
                        className="px-3"
                        onClick={() => setJumlah(0)}
                      >
                        <i className={`fa fa-refresh ${s.btnIcon}`} />
                      </Button>
                    </ButtonGroup>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={4}>
                <Input
                  type="text"
                  id="disabled-input"
                  disabled="disabled"
                  value={selected && selected.ukuran}
                />
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <div className="mx-auto">
            <Button
              className="btn-rounded btn-outline-secondary mr-3"
              outline
              onClick={() => {
                setModalOpen(false);
                setSelected(null);
                setJumlah(0);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-rounded"
              color="primary"
              onClick={() => handleSimpan()}
            >
              Simpan
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalOpen}
        toggle={() => onModalClose()}
        className="sidebar-lg"
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader className="mb-1" tag="div" close={CloseBtn}>
          <h5 className="modal-title">{"Add"}</h5>
        </ModalHeader>
        <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="namaobat">
                Nama Obat <span className="text-danger">*</span>
              </Label>
              <Input
                id="namaobat"
                name="namaobat"
                placeholder="nama obat"
                value={namaObat}
                required
              />
            </FormGroup>

            <FormGroup>
              {stok == 0 ? (
                <Label for="jml">
                  <span className="label muted" style={{ color: "red" }}>
                    Tidak tersedia stok di gudang
                  </span>
                </Label>
              ) : (
                <Label for="jml">
                  {stokError ? (
                    <span className="label muted" style={{ color: "red" }}>
                      {stokError}
                    </span>
                  ) : (
                    <span className="label muted">
                      Jumlah dalam <code>{satuan}</code>
                    </span>
                  )}
                </Label>
              )}
              <Input
                id="jml"
                type="number"
                value={totalKeluar}
                placeholder="jumlah"
                onChange={(e) => validateStok(e.target.value)}
                style={{ borderColor: stokError ? "red" : "initial" }}
                disabled={stok == 0 ? true : false}
              />
            </FormGroup>

            <FormGroup>
              <Label for="nofaktur">Nomor Faktur</Label>
              <Input
                id="nofaktur"
                value={noFaktur}
                placeholder="no faktur"
                required
              />
            </FormGroup>
            <FormGroup>
              <Fragment>
                <Button
                  className="mr-3 btn-rounded"
                  type="submit"
                  color="primary"
                >
                  {edit ? "Edit" : "Add"}
                </Button>
                <Button
                  className="btn-rounded"
                  color="secondary"
                  type="reset"
                  onClick={() => onModalClose(false)}
                  outline
                >
                  Cancel
                </Button>
              </Fragment>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={modalPermintaan} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          <div className={s.tableTitle}>
            <div className="headline-2">
              {"Daftar Permintaan"}
              <code> {"Masuk"}</code>
            </div>
          </div>
        </ModalHeader>

        <Widget className="widget-p-md">
          <div className="widget-table-overflow">
            <Table
              id="dataBantuan"
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead>
                <tr>
                  <th className="w-10">No</th>
                  <th className="w-15">Faktur</th>
                  <th className="w-25">Asal</th>
                  <th className="w-25">Tanggal</th>
                  <th className="w-25">Jumlah</th>
                  <th className="w-25">Status</th>
                  <th className="w-25">Surat</th>
                  <th className="w-25">Action</th>
                </tr>
              </thead>
              <tbody>
                {secondTable
                  .slice(
                    secondTableCurrentPage * pageSize2,
                    (secondTableCurrentPage + 1) * pageSize2
                  )
                  .map((item, index) => (
                    <tr key={uuidv4()}>
                      <td>{index + 1}</td>
                      <td>{item.noFaktur}</td>
                      <td>{item.asal}</td>
                      <td>{formatDate(item.tanggal)}</td>

                      <td>{item.jumlah}</td>
                      <td>
                        <code>belum dijawab</code>
                      </td>
                      <td>
                        {item.file ? (
                          <a
                            href={DOMAIN_SIMOBAT + item.file}
                            target="blank"
                            style={{ color: "green" }}
                          >
                            <i className="eva eva-file" />
                          </a>
                        ) : (
                          <div />
                        )}
                      </td>
                      <td>
                        <Button
                          size="xs"
                          color="success"
                          onClick={() => getPermintaan(item)}
                        >
                          Terima
                        </Button>
                        <Button
                          size="xs"
                          color="danger"
                          onClick={() => tolakPermintaan(item)}
                        >
                          Tolak
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              className="pagination-borderless"
              aria-label="Page navigation example"
            >
              <PaginationItem disabled={secondTableCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    setSecondTablePage(e, secondTableCurrentPage - 1)
                  }
                  previous
                  href="#top"
                />
              </PaginationItem>
              {[...Array(secondTablePagesCount)].map((page, i) => (
                <PaginationItem active={i === secondTableCurrentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => setSecondTablePage(e, i)}
                    href="#top"
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={secondTableCurrentPage >= secondTablePagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    setFirstTablePage(e, secondTableCurrentPage + 1)
                  }
                  next
                  href="#top"
                />
              </PaginationItem>
            </Pagination>
          </div>
        </Widget>
      </Modal>
      <Modal isOpen={modalOpen2} toggle={() => setModalOpen2(false)}>
        <ModalBody>
          <Widget className="widget-p-md">
            {infoSelected && (
              <div>
                <Row>
                  <Col>Nama Obat</Col>
                  <Col>
                    <div style={{ color: "red" }}>{infoSelected.nama}</div>
                  </Col>
                </Row>

                <Row>
                  <Col>Satuan Terkecil</Col>
                  <Col>{infoSelected.satuan}</Col>
                </Row>
                <Row>
                  <Col>Stok Tersedia</Col>
                  <Col>
                    {" "}
                    <code> {numberWithCommas(infoSelected.stok)}</code>
                  </Col>
                </Row>
                <Row>
                  <Col>Sumber Pengadaan</Col>
                  <Col>{infoSelected.sumber}</Col>
                </Row>
                <Row>
                  <Col>Expired Date</Col>
                  <Col>
                    {" "}
                    <code> {formatDate(infoSelected.ed)}</code>
                  </Col>
                </Row>

                <Row>
                  <Col>Tahun Pengadaan</Col>
                  <Col>{infoSelected.tahunPengadaan}</Col>
                </Row>

                <Row>
                  <Col>Total Masuk</Col>
                  <Col>{numberWithCommas(infoSelected.total_masuk)}</Col>
                </Row>
                <Row>
                  <Col>Total Keluar</Col>
                  <Col>{numberWithCommas(infoSelected.total_keluar)}</Col>
                </Row>

                <Row>
                  <Col>Harga Pengadaan</Col>
                  <Col>Rp.{numberWithCommas(infoSelected.harga)}</Col>
                </Row>
                <Row>
                  <Col>Penyedia</Col>
                  <Col>{infoSelected.penyedia}</Col>
                </Row>
              </div>
            )}
          </Widget>
        </ModalBody>
        <ModalFooter>
          <div className="mx-auto">
            <Button
              className="btn-rounded"
              color="success"
              onClick={() => setModalOpen2(false)}
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalTolak} toggle={() => setModalTolak(false)}>
        <ModalHeader toggle={() => setModalTolak(false)}>
          <div className={s.tableTitle}>
            <div className="headline-2">{"Permintaan Tidak Terima"}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Widget className="widget-p-md">
            <FormGroup>
              <Label for="description">Alasan permintaan tidak diterima</Label>
              <Input
                type="textarea"
                name="text"
                id="description"
                rows="3"
                value={catatan}
                onChange={(e) => setCatatan(e.target.value)}
                placeholder="catatan"
              />
            </FormGroup>
          </Widget>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-rounded"
            size="sm"
            color="secondary-red"
            onClick={() => setModalTolak(false)}
          >
            Cancel
          </Button>
          <div style={{ marginLeft: 5 }}></div>
          <Button
            className="btn-rounded"
            color="primary"
            size="sm"
            onClick={() => handleTolak()}
          >
            {" "}
            {"Simpan"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

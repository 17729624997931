import React, { useState, useEffect } from 'react';
import FIREBASE from '../../../config/FIREBASE';
//import FIREBASE from '../config/FIREBASE';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as dataFormat from './UsersDataFormatters';
import actions from '../../../actions/usersListActions';
import axios from 'axios';
import swal from 'sweetalert';
import { API_TIMEOUT, LOKASI, USERS } from '../../../utils';

import SimObat from '../../Icons/simobat.png';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Col,
  Form,
  Row,
  Badge,
  Label,
  FormGroup,
  Input,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Spinner,
} from 'reactstrap';
import Select, { components } from 'react-select';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Widget from '../../Widget/Widget';

import s from '../Users.module.scss';
import { useDispatch } from 'react-redux';
import { numberWithCommas } from '../../../utils/func';
import { logApp } from '../../../utils/func';

const listStatus = [
  { value: 2, label: 'Admin', columns: 'warning' },
  { value: 3, label: 'User', columns: 'warning' },
];

const { SearchBar } = Search;

const AdminListTable = (props) => {
  const { rows, dispatch, loading, idToDelete, modalOpen } = props;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);

  const [currentUser, setCurrentUser] = useState();
  const [rowsKu, setRowsKu] = useState([]);
  const [idHapus, setIdHapus] = useState();

  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState('2023');
  const [bantuan, setBantuan] = useState([]);
  const [tahun, setTahun] = useState('2023');
  const [jumlahPenerimaBantuan, setJumlahPenerimaBantuan] = useState(0);
  const [nama, setNama] = useState('');
  const [idAdmin, setIdAdmin] = useState('');

  const [status, setStatus] = useState('');
  const [username, setUsername] = useState('');

  const [url, setUrl] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [cari, setCari] = useState(false);
  const [proses, setProses] = useState(false);

  const [edit, setEdit] = useState(false);
  const [tambah, setTambah] = useState(false);
  const [gambar, setGambar] = useState('');
  const [uid, setUid] = useState('');

  const [lokasi, setLokasi] = useState([]);
  const [idLokasi, setIdLokasi] = useState('');
  const [level, setLevel] = useState('');
  const [puskesmas, setPuskesmas] = useState('');
  const [unit, setUnit] = useState('');
  const [lokasiUser, setLokasiUser] = useState('');

  const [modalDelete, setModalDelete] = useState(false);
  const [modalDelete2, setModalDelete2] = useState(false);
  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
  ];

  const dispatchKu = useDispatch();

  const OptionComponent = ({ data, ...props }) => {
    return (
      <components.Option {...props}>
        <div className={`bullet bullet-${data.color} bullet-sm mr-2`}></div>
        {data.label}
      </components.Option>
    );
  };

  const ambilDataLokasi = () => {
    axios({
      method: 'get',
      url: LOKASI + 'ambil/',
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          if (data.length > 0) {
            const fil = data.filter((item) => {
              return idLokasi == item.id;
            });

            const level = fil[0].level;

            if (level == 'Puskesmas') {
              setPuskesmas(fil[0].lokasi);
              props.setPuskesmasKu(fil[0].lokasi);
              setUnit('');

              const datafilter = data.filter((item) => {
                return item.id_Puskesmas == fil[0].id;
              });

              let lok = [];
              for (var i = 0; i < datafilter.length; i++) {
                lok.push({
                  value: datafilter[i].id,
                  label: datafilter[i].lokasi,
                  color: 'primary',
                });
              }

              setLokasi(lok);
            } else {
              const datafilter = data.filter((item) => {
                return item.level == 'Puskesmas' || item.level == 'Dinas';
              });

              setUnit('');

              setPuskesmas('');

              let lok = [];
              for (var i = 0; i < datafilter.length; i++) {
                lok.push({
                  value: datafilter[i].id,
                  label: datafilter[i].lokasi,
                  color: 'primary',
                });
              }

              setLokasi(lok);
            }
            setLevel(level);
            props.setLevelKu(level);
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const getUserEmail = (email) => {
    FIREBASE.database()
      .ref('users')
      .orderByChild('email')
      .equalTo(email)
      .once('value', (querySnapshot) => {
        //hasil disini

        let data = querySnapshot.val() ? querySnapshot.val() : [];

        let arrayOfNames = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const nama = data[key];
            arrayOfNames.push(nama);
          }
        }

        if (data) {
          setCari(false);
          setNama(arrayOfNames[0].nama);
          setUsername(arrayOfNames[0].email);

          //  setDinas(arrayOfNames[0].unitKerja);
          setUrl(arrayOfNames[0].avatar);
          setUid(arrayOfNames[0].uid);
          convertGambar(arrayOfNames[0].avatar);
        } else {
          setCari(false);
          swal('Gagal', 'Username tidak ditemukan', 'failed');
        }
      })
      .catch((error) => {
        setCari(false);
        swal('Gagal', 'Username tidak ditemukan', 'failed');
      });
  };

  const cariUser = (email) => {
    setCari(true);
    getUserEmail(email);
  };

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listTahun.filter((item) => {
      return item.tahun == select;
    });
    // ambilBantuan(curAnak[0].tahun);
    setTahun(curAnak[0].tahun);
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
      setIdLokasi(currentUser.idLokasi);
      logApp(currentUser.uid, 'List Users');
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      ambilDataLokasi();

      ambilListAdmin();
    }
  }, [currentUser]);

  const ambilListAdmin = () => {
    axios({
      method: 'get',
      url:
        currentUser.level == 'Dinas'
          ? USERS + 'dinas/ambil'
          : USERS + 'puskesmas/' + idLokasi,
      timeout: API_TIMEOUT,
      data: {
        bantuan: bantuan,
        tahun: tahun,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          setJumlahPenerimaBantuan(data.length);

          setRowsKu(data);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const handleSimpan = () => {
    if (edit) {
      closeModal();
    } else if (tambah) {
      if (!nama || !username || !status || !lokasiUser) {
        swal('Gagal', 'Data yang anda masukan harus lengkap', 'error');
        return;
      }

      setProses(true);
      simpanAdmin();
    }
  };

  const handleDelete = () => {
    setProses(true);
    deleteAdmin();
  };

  const openModal3 = (cell) => {
    const id = cell;
    //  getDataPenerimabantuan(cell);
    setIdAdmin(id);

    const admin = rowsKu.filter((item) => {
      return item.id == id;
    });
    const adminKu = admin[0];

    setNama(adminKu.nama);

    setModalDelete2(true);
  };

  const closeModal2 = () => {
    setEdit(false);
    setTambah(false);
    setNama('');
    setUsername('');
    setStatus('');
    //setDinas('');
    setUrl('');
    setSearchItem('');
    setGambar('');
    setIdAdmin('');

    setModalDelete2(false);
  };

  const deleteAdmin = async () => {
    const response = await axios.delete(
      'https://tulufali.rotendaokab.go.id/api/postadmin/' + idAdmin,

      {
        //credentials: 'include',
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        //   Authorization: `Bearer ${accessToken}`,
        // },
      }
    );

    if (response.data.success === 1) {
      swal('Sukses', 'Data Admin ' + nama + ' berhasil dihapus', 'success');
      ambilListAdmin();
      setProses(false);
      closeModal2();
    } else {
    }
  };

  const simpanAdmin = async () => {
    const formData = new FormData();

    formData.append('photo', gambar);

    formData.append('id_Smart', uid);
    formData.append('id_Lokasi', lokasiUser[0].value);
    formData.append('username', username);
    formData.append('status', status[0].value);

    formData.append('nama', nama);

    const response = await axios.post(USERS + 'tambah', formData, {
      //credentials: 'include',
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      //   Authorization: `Bearer ${accessToken}`,
      // },
    });

    if (response.data.success === 1) {
      setProses(false);
      swal('Sukses', 'Data Admin ' + nama + ' berhasil ditambahkan', 'success');

      closeModal();
      ambilListAdmin();
    }
  };

  const convertGambar = (avatar) => {
    // Data gambar dalam format data URI
    if (avatar) {
      const imageDataURI = avatar; // Sisipkan data gambar lengkap di sini

      // Memisahkan data URI menjadi tipe dan base64 encoded data
      const [dataType, base64Data] = imageDataURI.split(',');

      // Mengubah base64 encoded data menjadi bentuk binary
      const binaryData = atob(base64Data);

      // Mendapatkan panjang data binary
      const len = binaryData.length;

      // Membuat array untuk menyimpan data binary dalam bentuk Uint8Array
      const uint8Array = new Uint8Array(len);

      // Mengisi array dengan data binary
      for (let i = 0; i < len; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Membuat file baru dari Uint8Array dan menambahkan tipe datanya
      const file = new File([uint8Array], 'gambar.jpg', { type: dataType });

      setGambar(file);
    } else {
      setGambar('');
    }
  };

  const openModal = (cell) => {
    const id = cell;

    setIdAdmin(id);

    const admin = rowsKu.filter((item) => {
      return item.id == id;
    });
    const adminKu = admin[0];

    setNama(adminKu.nama);

    const lok = lokasi.filter((item) => {
      return item.value == adminKu.id_Lokasi;
    });

    setUsername(adminKu.username);
    const stat = listStatus.filter((item) => {
      return item.value == adminKu.status;
    });
    setStatus(stat);
    setLokasiUser(lok);
    setUrl(adminKu.profile);

    setModalDelete(true);
  };

  const openModal2 = () => {
    setTambah(true);

    setModalDelete(true);
  };

  const closeModal = () => {
    setEdit(false);
    setTambah(false);
    setNama('');
    setUsername('');
    setStatus('');
    setPuskesmas('');
    setUrl('');
    setSearchItem('');
    setGambar('');
    setCari(false);
    setLokasiUser('');

    setModalDelete(false);
  };

  const actionFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={`${s.controlBtn} mx-2`}
          color='primary'
          size='sm'
          onClick={() => {
            setEdit(true);
            openModal(cell);
          }}
        >
          Edit
        </Button>
      </div>
    );
  };

  const statusFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={s.controlBtn}
          color={
            cell == 1
              ? 'secondary-red'
              : cell == 2
              ? 'warning'
              : cell == 3
              ? 'success'
              : ''
          }
          size='xs'
          onClick={() => {
            '';
          }}
        >
          {cell == 1
            ? 'Super Admin'
            : cell == 2
            ? 'Admin'
            : cell == 3
            ? 'User'
            : ''}
        </Button>
      </div>
    );
  };

  const updateWindowDimensions = () => {
    setTableWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(actions.doFetch({}));
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const columns = [
    {
      dataField: 'avatar',
      sort: true,
      formatter: dataFormat.imageFormatter,
      text: 'Avatar',
    },
    {
      dataField: 'nama',
      sort: true,
      text: 'Nama',
    },
    {
      dataField: 'lokasi',
      sort: true,
      text: 'Lokasi',
    },
    {
      dataField: 'username',
      sort: true,
      text: 'Username',
    },

    {
      dataField: 'status',
      sort: true,
      formatter: statusFormatter,
      text: 'Status',
    },
    {
      dataField: 'id',
      formatter: actionFormatter,
      text: 'Actions',
    },
  ];

  return (
    <div>
      <Widget className='widget-p-md'>
        <Row>
          <Col
            className='pr-grid-col'
            xs={4}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <code> Jumlah Users </code>{' '}
            <Badge style={{ marginLeft: 5 }} color='danger'>
              {jumlahPenerimaBantuan}
            </Badge>
          </Col>
          <Col className='pr-grid-col' xs={2}>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color='primary'>
                {simpleDropdownValue2}
              </DropdownToggle>

              <DropdownMenu>
                {listTahun &&
                  listTahun.map((item) => (
                    <DropdownItem onClick={changeSelectDropdownSimple2}>
                      {item.tahun}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
          <Col
            className='pr-grid-col'
            xs={6}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              color='success'
              className='btn'
              size='sm'
              onClick={() => openModal2()}
            >
              Tambah Admin
            </Button>
          </Col>
        </Row>
        <ToolkitProvider columns={columns} data={rowsKu} keyField='id' search>
          {(props) => (
            <React.Fragment>
              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover mt-4 ${
                  tableWidth < 1150 ? 'table-responsive' : ''
                }`}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Widget>
      <Modal size='md' isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Admin SIM Obat</ModalHeader>
        <ModalBody>
          <FormGroup>
            <CardBody>
              {tambah && (
                <FormGroup>
                  <InputGroup>
                    <Input
                      type='text'
                      id='search-input1'
                      value={searchItem}
                      onChange={(e) => setSearchItem(e.target.value)}
                    />
                    <InputGroupAddon addonType='append'>
                      {cari ? (
                        <Button color='default' disabled>
                          <Spinner size='sm' color='light' /> Loading
                        </Button>
                      ) : (
                        <Button
                          color='default'
                          onClick={() => cariUser(searchItem)}
                        >
                          Search
                        </Button>
                      )}
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              )}
              {url && (
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={url ? url : ''}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 50,

                      marginBottom: 10,
                      border: '2px solid blue',
                    }}
                  />
                </Row>
              )}

              {nama && (
                <Form onSubmit={(e) => ''}>
                  <FormGroup row>
                    <Label md={3} for='normal-field' className='text-md-right'>
                      Nama
                    </Label>
                    <Col md={9}>
                      <Input
                        type='text'
                        id='default-textarea'
                        placeholder='nama'
                        value={nama}
                        onChange={(e) => setNama(e.target.value)}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} for='normal-field' className='text-md-right'>
                      Username
                    </Label>
                    <Col md={9}>
                      <Input
                        type='text'
                        id='default-textarea'
                        placeholder='username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} for='normal-field' className='text-md-right'>
                      Peran
                    </Label>
                    <Col md={9}>
                      <Select
                        type='select'
                        id='normal-field'
                        options={listStatus}
                        value={status}
                        onChange={(data) => setStatus([data])}
                        className='react-select'
                        classNamePrefix='select'
                        isClearable={false}
                        components={{
                          Option: OptionComponent,
                        }}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} for='normal-field' className='text-md-right'>
                      Lokasi
                    </Label>
                    <Col md={9}>
                      <Select
                        type='text'
                        id='normal-field'
                        value={lokasiUser}
                        onChange={(data) => setLokasiUser([data])}
                        options={lokasi}
                        required
                        className='react-select'
                        classNamePrefix='select'
                        isClearable={false}
                        components={{
                          Option: OptionComponent,
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              )}
            </CardBody>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => closeModal()}>
            Cancel
          </Button>
          {proses ? (
            <Button color='default' disabled>
              <Spinner size='sm' color='light' /> Kirim
            </Button>
          ) : (
            <Button color='primary' onClick={() => handleSimpan()}>
              {tambah ? 'Tambah' : 'Simpan'}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal size='sm' isOpen={modalDelete2} toggle={() => closeModal2()}>
        <ModalHeader toggle={() => closeModal2()}>Hapus Admin</ModalHeader>
        <ModalBody>
          <FormGroup>
            <CardBody>
              {'Apakah anda yakin ingin menghapus admin atas nama '}
              <code> {nama}</code>
              {' ?'}
            </CardBody>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size='sm' color='success' onClick={() => closeModal2()}>
            Cancel
          </Button>
          {proses ? (
            <Button
              size={modalDelete2 ? 'sm' : 'md'}
              color={modalDelete2 ? 'default' : 'danger'}
              disabled
            >
              <Spinner size='sm' color='light' />{' '}
              {modalDelete2 ? 'proses' : 'Kirim'}
            </Button>
          ) : (
            <Button size='sm' color='danger' onClick={() => handleDelete()}>
              {'Hapus'}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.list.loading,
    rows: store.users.list.rows,
    modalOpen: store.users.list.modalOpen,
    idToDelete: store.users.list.idToDelete,
  };
}

export default connect(mapStateToProps)(AdminListTable);

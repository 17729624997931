import React, { useState, useEffect } from 'react';

import {
  Col,
  Row,
  Progress,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget.js';

import 'eva-icons/style/eva-icons.css';

import ApexCharts from 'react-apexcharts';

import gymIcon from '../../assets/dashboard/gymIcon.svg';
import therapyIcon from '../../assets/dashboard/therapyIcon.svg';

import jwt_decode from 'jwt-decode';
import swal from 'sweetalert';
import { logApp } from '../../utils/func.js';

import axios from 'axios';
import { API_TIMEOUT, ADMIN_TOKEN, DASHBOARD } from '../../utils/index.js';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import s from './Dashboard.module.scss';

import Map from '../../components/am4chartMap/am4chartMap.js';

import { logoutUser } from '../../actions/auth.js';
import { useDispatch } from 'react-redux';

import RechartsPieChart from '../extra/charts/components/RechartsPieChart.js';
import ApexLineChart from '../extra/charts/components/ApexLineChart.js';
import ApexRadarChart from '../extra/charts/components/ApexRadarChart.js';

export default function Dashboard(props) {
  const [checkboxes, setCheckboxes] = useState([true, false]);

  const [currentUser, setCurrentUser] = useState(null);

  const [simpleDropdownValue, setSimpleDropdownValue] = useState('2023');
  const [neraca, setNeraca] = useState([]);
  const [puskes, setPuskes] = useState([]);

  const [progresDinas, setProgresDinas] = useState([]);
  const [dataAdmin, setDataAdmin] = useState();
  const [expire, setExpire] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const dispatch = useDispatch();

  const [dataRadar, setDataRadar] = useState([]);
  const doLogout = () => {
    dispatch(logoutUser());
  };
  const refreshToken = async () => {
    try {
      const response = await axios.get(ADMIN_TOKEN, {
        credentials: 'include',
      });
      const decoded = jwt_decode(response.data.accessToken);

      setAccessToken(response.data.accessToken);

      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        setAccessToken('response.data.accessToken');
        // doLogout();
      }
    }
  };

  const [listPekerjaanPenduduk, setListPekerjaanPenduduk] = useState({
    label: [],
    penduduk: [],
  });

  const [limaTahun, setLimaTahun] = useState({ label: [], data: [] });

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ADMIN_TOKEN);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;

        const decoded = jwt_decode(response.data.accessToken);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const highcharts = {
    variableRadiusPie: {
      chart: {
        type: 'variablepie',
      },
      title: {
        text: 'Pekerjaan KK Miskin Ekstrim',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '',
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
          'Jenis Pekerjaan: <b>{point.y}</b><br/>',
      },
      colors: [
        '#16365f',
        '#41D5E2',
        '#FFC405',
        '#43BC13',
        '#FF5668',
        '#C7D0D9',
        '#00A5FF',
      ],
      series: [
        {
          minPointSize: 10,
          innerSize: '20%',
          zMin: 0,
          name: 'countries',
          data: listPekerjaanPenduduk,
        },
      ],
    },
  };

  //auth

  //auth

  const [kecamatan, setKecamatan] = useState([
    'Baa',
    'Bat',
    'Bus',
    'Del',
    'Eah',
    'Fea',
    'Kor',
    'Nda',
    'Oela',
    'Oele',
    'Son',
    'Sot',
  ]);

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);
    const curTahun = listTahun.filter((item) => {
      return item.tahun == select;
    });
    //getProgresDinas(curTahun[0].tahun);
  };

  const apexCharts = {
    lineColumn: {
      series: [
        {
          name: 'Masuk',
          type: 'column',
          data: neraca && neraca.masuk,
        },
        {
          name: 'Keluar',
          type: 'line',
          data: neraca && neraca.keluar,
        },
      ],
      options: {
        colors: ['#C7D0D9', '#FF5668'],
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: [0, 1],
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          style: {
            fontSize: '8px',
            fontWeight: 500,
          },
          background: {
            borderWidth: 0,
          },
          formatter: function (val) {
            const nilai = val / 1000;
            return (
              'Rp ' + nilai.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            ); // Mengubah ke format Rupiah
          },
        },
        labels: kecamatan,
        xaxis: {
          type: 'category',
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
        yaxis: [
          {
            title: {
              text: 'Rupiah',
              style: {
                fontSize: '12px',
                fontWeight: 400,
                color: '#6B859E',
              },
            },
            labels: {
              style: {
                colors: ['#6B859E'],
              },
              formatter: function (value) {
                const nilai = value / 1000;
                return (
                  'Rp ' + nilai.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                ); // Mengubah ke format Rupiah
              },
            },
          },
        ],
        fill: {
          type: 'solid',
          opacity: 1,
        },
      },
    },

    lineArea: {
      series: limaTahun.data,
      options: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        xaxis: {
          type: 'category',
          categories: limaTahun.label,
          labels: {
            style: {
              colors: '#6B859E',
              opacity: 0.7,
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const nilai = value / 1000;
              return (
                'Rp ' + nilai.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
              );
            },
            style: {
              colors: ['#6B859E'],
              opacity: 0.7,
            },
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 1,
            stops: [40, 90, 100],
          },
        },
        colors: ['#4D53E0', '#41D5E2'],
        legend: {
          show: true,
          horizontalAlign: 'center',
        },
      },
    },
  };

  const toggleCheckbox = (id) => {
    setCheckboxes((checkboxes) =>
      checkboxes.map((checkbox, index) => (index === id ? !checkbox : checkbox))
    );
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, 'Dashboard');
    }
    ambilNeraca();
    // refreshToken();
    // getDataMiskinKecamatan();
    // getDataMiskinKeluargaKecamatan();
    // getProgresDinas('2023');
    // pekerjaanPenduduk();
    // limaTahunTerakhir();
  }, []);

  useEffect(() => {
    if (accessToken) {
      // getDataMiskinKecamatan();
      // getDataMiskinKeluargaKecamatan();
      // getProgresDinas('2023');
      // pekerjaanPenduduk();
      // limaTahunTerakhir();
    }
  }, [accessToken]);

  const ambilNeraca = () => {
    axios({
      method: 'get',
      url: DASHBOARD + 'ambil/rekap/' + 2023,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          const pus = data.map((item) => {
            return item.lokasi;
          });
          const masuk = data.map((item) => {
            return item.total_masuk;
          });

          const keluar = data.map((item) => {
            return item.total_keluar;
          });

          let mas = 0;
          let kel = 0;
          let exp = 0;

          data.map((item) => {
            mas = mas + item.total_masuk;
            kel = kel + item.total_keluar;
            exp = exp + item.stok;
          });

          const dR = [
            { name: 'Masuk', value: mas, color: '#FFC405' },
            { name: 'Keluar', value: kel, color: '#FF5668' },
            { name: 'Stok', value: exp, color: '#4D53E0' },
          ];
          setDataRadar(dR);
          setPuskes(pus);
          setNeraca({ masuk: masuk, keluar: keluar });
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
  ];

  //auth

  const toSmallCaps = (text) => {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, (char) => {
      return char.toUpperCase();
    });
  };

  //auth

  return (
    <div>
      <Row>
        <Col className='pr-grid-col' xs={12} lg={12}>
          <Row style={{ marginBottom: 20 }}>
            <Col className='pr-grid-col' xs={12} lg={8}>
              <Widget style={{ padding: 20 }}>
                <Map />
              </Widget>
            </Col>
            <Col className='mt-4 mt-lg-0 pl-grid-col' xs={12} lg={4}>
              <Widget className='widget-p-lg'>
                <div className={s.goalsTitle}>
                  <p className='headline-3'>Bansos</p>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle caret color='primary'>
                      {simpleDropdownValue}
                    </DropdownToggle>

                    <DropdownMenu>
                      {listTahun &&
                        listTahun.map((item) => (
                          <DropdownItem onClick={changeSelectDropdownSimple}>
                            {item.tahun}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
                {progresDinas ? (
                  progresDinas.map((item) => (
                    <div className='d-flex flex-column mt-3'>
                      <div className={s.activity}>
                        <p className='body-2'>{toSmallCaps(item.dinas)} </p>
                        <p className='body-2'>
                          {item.penerima}
                          <span className='body-3 muted'>
                            /{item.totalPenerima}
                          </span>
                        </p>
                      </div>
                      <Progress
                        color={
                          (item.penerima / item.totalPenerima) * 100 < 50
                            ? 'danger'
                            : (item.penerima / item.totalPenerima) * 100 >=
                                50 &&
                              (item.penerima / item.totalPenerima) * 100 <= 90
                            ? 'warning'
                            : 'success'
                        }
                        className='progress-xs'
                        value={(item.penerima / item.totalPenerima) * 100}
                      />
                    </div>
                  ))
                ) : (
                  <div />
                )}

                <p className='headline-3'>Konseling</p>
                <div className={`mt-3 ${s.widgetBlock}`}>
                  <div className={s.widgetBody}>
                    <div className='d-flex'>
                      <img className='img-fluid mr-2' src={gymIcon} alt='...' />
                      <div className='d-flex flex-column'>
                        <p className='body-2'>Langsung</p>
                        <p className='body-3 muted'>Pengembalian</p>
                      </div>
                    </div>
                    <div className='checkbox checkbox-primary'>
                      <input
                        id='checkbox0'
                        type='checkbox'
                        className='styled'
                        checked={checkboxes[0]}
                        onChange={(event) => toggleCheckbox(0)}
                      />
                      <label htmlFor='checkbox0' />
                    </div>
                  </div>
                </div>
                <div className={`mt-3 ${s.widgetBlock}`}>
                  <div className={s.widgetBody}>
                    <div className='d-flex'>
                      <img
                        className='img-fluid mr-2'
                        src={therapyIcon}
                        alt='...'
                      />
                      <div className='d-flex flex-column'>
                        <p className='body-2'>Hibah</p>
                        <p className='body-3 muted'>Bansos</p>
                      </div>
                    </div>
                    <div className='checkbox checkbox-primary'>
                      <input
                        id='checkbox1'
                        type='checkbox'
                        className='styled'
                        checked={checkboxes[1]}
                        onChange={(event) => toggleCheckbox(1)}
                      />
                      <label htmlFor='checkbox1' />
                    </div>
                  </div>
                </div>
                <a
                  className={`btn-secondary-red ${s.statsBtn}`}
                  href='#top'
                  role='button'
                >
                  <div style={{ marginRight: 10 }}>
                    <i className='eva eva-phone' />
                  </div>
                  <div>
                    <p className='headline-2'>KONSULTASI</p>
                    <p className='body-3'>Kirim pertayaan konsultasi</p>
                  </div>
                </a>
              </Widget>
            </Col>
          </Row>
          <Row className='gutter mb-4'>
            <Col xs={12} lg={6}>
              <Widget style={{ padding: 20 }}>
                <div className='headline-2 mb-2'>Stock Masuk vs Keluar</div>
                <ApexCharts
                  series={apexCharts.lineColumn.series}
                  options={apexCharts.lineColumn.options}
                />
              </Widget>
            </Col>

            <Col xs={12} xl={3}>
              <Widget className='widget-p-md'>
                <div className='headline-2 mb-3'>Donut chart</div>
                <RechartsPieChart data={dataRadar} />
              </Widget>
            </Col>
            <Col className='mt-4 mt-xl-0' xs={12} xl={3}>
              <Widget className='widget-p-md'>
                <div className='headline-2'>Radar Chart</div>
                <ApexRadarChart />
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

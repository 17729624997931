import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter, Link, Redirect } from 'react-router-dom';
import config from '../../../config';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import jwt from 'jsonwebtoken';
import { loginUser, receiveToken, doInit } from '../../../actions/auth';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
  Spinner,
} from 'reactstrap';
import Widget from '../../../components/Widget/Widget';
import Footer from '../../../components/Footer/Footer';

import loginImage from '../../../assets/loginImage.svg';

import SimObat from '../../../components/Icons/simobat.png';
import GoogleIcon from '../../../components/Icons/AuthIcons/GoogleIcon.js';
import { loginSmart } from '../../../actions/auth';

import { validateEmail, validatePassword } from '../../../utils/func';

const Login = (props) => {
  const myInput = document.getElementById('myInput');
  const inputValue = myInput.value;
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const [idSmart, setIdSmart] = useState(inputValue);

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [loginProses, setLoginProses] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const loginSmartKu = (e) => {
    setLoginProses(true);

    props.dispatch(loginSmart(inputValue, setLoginProses));
  };

  const doLogin = (e) => {
    setLoginProses(true);
    e.preventDefault();
    if (passwordError || emailError) {
      alert('Password dan Email harus sesuai standar');
      setLoginProses(false);
      return;
    }
    props.dispatch(
      loginUser({ password: password, email: email }, setLoginProses)
    );
  };

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    validateEmail(event.target.value, setEmailError);
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    if (token) {
      props.dispatch(receiveToken(token));
      props.dispatch(doInit());
    }
  });

  return (
    <div className='auth-page'>
      <Container className='col-12'>
        <Row className='d-flex align-items-center'>
          <Col xs={12} lg={6} className='left-column'>
            <Widget className='widget-auth widget-p-lg'>
              <div className='d-flex align-items-center justify-content-between py-3'>
                <p className='auth-header mb-0'>Login</p>
                <div className='logo-block'>
                  <img src={SimObat} style={{ width: 50, height: 50 }} />
                  <p className='mb-0'>SIM OBAT</p>
                </div>
              </div>
              <div className='auth-info my-2'>
                {idSmart ? (
                  <p>
                    Aplikasi <b>SIM OBAT</b> diperuntukan bagi upaya manajemen
                    Obat yang lebih baik.
                    <b>Rote Smart Service</b> Klik tombol di bawah untuk login!
                  </p>
                ) : (
                  <p>
                    Aplikasi <b>SIM OBAT</b> diperuntukan bagi upaya manajemen
                    Obat yang lebih baik.
                    <br />
                    <b>Akun rote smart service dan password"</b> untuk login!
                  </p>
                )}
              </div>
              <form onSubmit={(event) => doLogin(event)}>
                {idSmart ? (
                  <></>
                ) : (
                  <>
                    <FormGroup className='my-3'>
                      <FormText>Email</FormText>{' '}
                      {emailError ? (
                        <span className='label muted' style={{ color: 'red' }}>
                          {emailError}
                        </span>
                      ) : (
                        <span className='label muted'>
                          username smartservice
                        </span>
                      )}
                      <Input
                        id='email'
                        className='input-transparent pl-3'
                        value={email}
                        onChange={(event) => {
                          validateEmail(event.target.value, setEmailError);
                          setEmail(event.target.value);
                        }}
                        type='email'
                        style={{ borderColor: emailError ? 'red' : 'initial' }}
                        required
                        name='email'
                        placeholder='Email'
                      />
                    </FormGroup>
                    <FormGroup className='my-3'>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <FormText>Password</FormText>
                          {passwordError ? (
                            <span
                              className='label muted'
                              style={{ color: 'red' }}
                            >
                              {passwordError}
                            </span>
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <Link>Forgot password?</Link>
                      </div>
                      <Input
                        id='password'
                        className='input-transparent pl-3'
                        value={password}
                        onChange={(event) => {
                          validatePassword(
                            event.target.value,
                            setPasswordError
                          );
                          setPassword(event.target.value);
                        }}
                        type='password'
                        style={{
                          borderColor: passwordError ? 'red' : 'initial',
                        }}
                        required
                        name='password'
                        placeholder='Password'
                      />
                    </FormGroup>
                  </>
                )}
                <div className='bg-widget d-flex justify-content-center'>
                  {idSmart ? (
                    <Button
                      className='rounded-pill my-3'
                      onClick={() => loginSmartKu()}
                      color='primary'
                      disabled={loginProses}
                    >
                      Login dengan Akun RSS
                    </Button>
                  ) : loginProses ? (
                    <Button
                      className='rounded-pill my-3'
                      type='submit'
                      color='secondary-red'
                      disabled={true}
                    >
                      <Spinner size='sm' color='light' /> Login ...
                    </Button>
                  ) : (
                    <Button
                      className='rounded-pill my-3'
                      type='submit'
                      color='secondary-red'
                      disabled={loginProses}
                    >
                      Login
                    </Button>
                  )}
                </div>
                <p className='dividing-line my-3'>&#8195;Or&#8195;</p>
                {!idSmart && (
                  <>
                    <div className='d-flex align-items-center my-3'>
                      <p className='social-label mb-0'>
                        Download Rote Smart Service
                      </p>
                      <div className='socials'>
                        <a href='https://play.google.com/store/apps/details?id=com.rotesmartservice'>
                          <GoogleIcon />
                        </a>
                      </div>
                    </div>
                    <Link to='https://play.google.com/store/apps/details?id=com.rotesmartservice'>
                      Segera buat akun Rote Smart Service
                    </Link>
                  </>
                )}
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className='right-column'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={'https://tulufali.rotendaokab.go.id/tulufalilogin.png'}
                alt='Error page'
                style={{ maxWidth: '80%', height: 'auto' }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));

import React, { useContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import useDatabaseStorage_contact from '../hooks/useDBStorage_Contact';

const ContactsContext = React.createContext();

export function useContacts() {
  return useContext(ContactsContext);
}

export function ContactsProvider({ children }) {
  const [contacts, setContacts] = useDatabaseStorage_contact('contact', []);

  function createContact(id, name) {
    setContacts((prevContacts) => {
      return [...prevContacts, { id, name }];
    });
  }

  return (
    <ContactsContext.Provider value={{ contacts, createContact }}>
      {children}
    </ContactsContext.Provider>
  );
}

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Form,
  Input,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget.js';
import './table.css';

import optionsIcon from '../../assets/tables/optionsIcon.svg';
import printerIcon from '../../assets/tables/printerIcon.svg';

import TulufaliLogo from '../../components/Icons/tulufali_logo.png';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import s from './Tables.module.scss';

import { API_TIMEOUT, LOKASI, NERACA } from '../../utils/index.js';
import axios from 'axios';

import { useDispatch } from 'react-redux';

import Success from '../../assets/success.png';
import { logApp, numberWithCommas } from '../../utils/func.js';
import Select, { components } from 'react-select';
import swal from 'sweetalert';

const TableLapNeraca_Gudang = function () {
  const [firstTable, setFirstTable] = useState([]);
  const [secondTable, setSecondTable] = useState([]);
  const [lokasi, setLokasi] = useState('');
  const [level, setLevel] = useState('');
  const [puskesmas, setPuskesmas] = useState('');
  const [unit, setUnit] = useState('');

  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);

  const [modalDelete, setModalDelete] = useState(false);

  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState('2023');
  const [bantuanModal, setBantuanModal] = useState('');
  const [tahun, setTahun] = useState('2023');

  const [idLok2, setIdLok2] = useState('');

  const [modalSK, setModalSk] = useState(false);
  const [idKunci, setIdKunci] = useState('');

  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState();
  const [idLokasi, setIdLokasi] = useState('');

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
      logApp(currentUser.uid, 'Neraca Obat Puskesmas');
      setIdLokasi(currentUser.idLokasi);
    }
  }, []);

  const containerStyle = {
    width: '100%',
    height: '300px', // Atur tinggi sesuai kebutuhan Anda
    overflow: 'auto',
    /* Gaya tampilan scroll */
    scrollbarWidth: 'thin',
    scrollbarColor: '#888888 #f0f0f0',
  };

  useEffect(() => {
    if (idLokasi) {
      ambilDataLokasi();
      ambilNeracaGudang();
    }
  }, [idLokasi]);

  const ambilDataLokasi = () => {
    axios({
      method: 'get',
      url: LOKASI + 'ambil/',
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          if (data.length > 0) {
            const datafilter = data.filter((item) => {
              return item.level == 'Puskesmas' || item.level == 'Dinas';
            });
            let lok = [];
            for (var i = 0; i < datafilter.length; i++) {
              lok.push({
                value: datafilter[i].id,
                label: datafilter[i].lokasi,
                color: 'primary',
              });
            }

            setLokasi(lok);

            setLevel('Dinas');
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilNeraca = (id) => {
    axios({
      method: 'get',
      url: NERACA + 'puskesmas/sumber/' + id,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;
          setFirstTable(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilNeracaGudang = () => {
    axios({
      method: 'get',
      url: NERACA + 'gudang/sumber/',
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          const data = response.data.data;
          setFirstTable(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
  ];

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listTahun.filter((item) => {
      return item.tahun == select;
    });

    setTahun(curAnak[0].tahun);
    // ambilDataBantuanKategori(currentBantuan, curAnak[0].tahun);
  };

  const OptionComponent = ({ data, ...props }) => {
    return (
      <components.Option {...props}>
        <div className={`bullet bullet-${data.color} bullet-sm mr-2`}></div>
        {data.label}
      </components.Option>
    );
  };

  const pageSize = 100;
  const pageSize2 = 100;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize2);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const openModal = (data) => {
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };

  const openModalSk = (data) => {
    //ambilDataBantuanTahun(data);
    setModalSk(true);
  };
  const closeModalSk = () => {
    setIdKunci('');
    setModalSk(false);
  };

  const onChangeLokasi = (data) => {
    setIdLok2([data]);

    if (data.value == 1) {
      ambilNeracaGudang();
    } else {
      ambilNeraca(data.value);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className='mb-4'>
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className='headline-2'>
                    Laporan Neraca #<code>{'Gudang'}</code>
                  </div>
                  <div>
                    {' '}
                    <ReactHTMLTableToExcel
                      table='neraca_sumber'
                      filename={
                        level == 'Puskesmas'
                          ? 'Neraca -  Sumber' + puskesmas + ' ' + tahun
                          : 'Neraca -  Sumber' +
                            puskesmas +
                            '-' +
                            unit +
                            ' ' +
                            tahun
                      }
                      sheet='Data'
                      className='btnKu'
                    />
                  </div>

                  <FormGroup>
                    <Label for='label'>Pilih Lokasi Obat</Label>
                    <Select
                      id='label'
                      value={idLok2}
                      options={lokasi}
                      className='react-select'
                      classNamePrefix='select'
                      isClearable={false}
                      onChange={(data) => {
                        onChangeLokasi(data);
                      }}
                    />
                  </FormGroup>

                  <div className='d-flex'>
                    <Col className='pr-grid-col' xs={4}>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color='primary'>
                          {simpleDropdownValue2}
                        </DropdownToggle>

                        <DropdownMenu>
                          {listTahun &&
                            listTahun.map((item) => (
                              <DropdownItem
                                onClick={changeSelectDropdownSimple2}
                              >
                                {item.tahun}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </div>
                </div>
                <div className='widget-table-overflow'>
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    id='neraca_sumber'
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className='checkbox checkbox-primary'>
                            <input
                              className='styled'
                              id='checkbox100'
                              type='checkbox'
                            />
                            <label for='checkbox100' />
                          </div>
                        </th>
                        <th className='w-25'>TAHUN</th>

                        <th className='w-25'>SUMBER</th>
                        <th className='w-25'>STOK AWAL</th>
                        <th className='w-25'>PENERIMAAN</th>

                        <th className='w-25'>PENGELUARAN</th>
                        <th className='w-25'>STOK</th>
                        <th className='w-25'>EXPIRED</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item, index) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className='checkbox checkbox-primary'>
                                <input
                                  id={index}
                                  className='styled'
                                  type='checkbox'
                                />
                                <Label for={index} />
                              </div>
                            </td>
                            <td className='d-flex align-items-center'>
                              <span className='ml-3'>
                                {item.tahunPengadaan}
                              </span>
                            </td>

                            <td>{item.sumber}</td>
                            <td>{item.tahunPendanaan}</td>

                            <td>
                              Rp.
                              {item.total_harga_masuk &&
                                numberWithCommas(item.total_harga_masuk)}
                            </td>
                            <td>
                              Rp.
                              {item.total_harga_keluar &&
                                numberWithCommas(item.total_harga_keluar)}
                            </td>
                            <td>
                              Rp.
                              {item.total_harga_ready &&
                                numberWithCommas(item.total_harga_ready)}
                            </td>
                            <td>
                              Rp.
                              {item.total_harga_expired &&
                                numberWithCommas(item.total_harga_expired)}
                            </td>

                            <td>
                              <Button
                                className={`${s.controlBtn} mx-2`}
                                color='primary'
                                size='xs'
                                onClick={() => {
                                  const data = {};

                                  openModal(data);
                                }}
                              >
                                Data
                              </Button>
                            </td>

                            <td>
                              <div className={`d-flex justify-content-between`}>
                                <div className='d-flex flex-column mt-3'>
                                  <div className={s.activity}>
                                    <p className='body-2'>
                                      {Math.round(
                                        (item.total_harga_keluar /
                                          item.total_harga_masuk) *
                                          100
                                      )}
                                      % penggunaan{' '}
                                    </p>
                                    <p className='body-2'>
                                      {item.jumlahpenerima}
                                      <span className='body-3 muted'>
                                        /{item.jmlPenerima}
                                      </span>
                                    </p>
                                  </div>
                                  <Progress
                                    color={
                                      (item.total_harga_keluar /
                                        item.total_harga_masuk) *
                                        100 >
                                      50
                                        ? 'success'
                                        : 'danger'
                                    }
                                    className='progress-xs'
                                    value={
                                      (item.total_harga_keluar /
                                        item.total_harga_masuk) *
                                      100
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className='pagination-borderless'
                    aria-label='Page navigation example'
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href='#top'
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href='#top'
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href='#top'
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size='lg' isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          <div className={s.tableTitle}>
            <div className='headline-2'>
              {bantuanModal}
              <code> Tahun {tahun}</code>
            </div>
            <div className='d-flex'>
              <a>
                <ReactHTMLTableToExcel
                  table='dataBantuan'
                  filename={
                    'TuluFali Data Bansos-' + bantuanModal + ' ' + tahun
                  }
                  sheet='Data'
                  className='btnKu'
                />
              </a>
              <a>
                <img src={printerIcon} alt='Printer' />
              </a>
              <a>
                <img
                  className='d-none d-sm-block'
                  src={optionsIcon}
                  alt='Options'
                />
              </a>
              <a>
                <img
                  style={{ width: 30, height: 30 }}
                  src={Success}
                  alt='Funnel'
                />
              </a>
            </div>
          </div>
        </ModalHeader>

        <Widget className='widget-p-md'>
          <div className='widget-table-overflow'>
            <Table
              id='dataBantuan'
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead>
                <tr>
                  <th className='w-15'>TL</th>
                  <th className='w-25'>Nama</th>
                  <th className='w-25'>Kelompok</th>
                  <th className='w-25'>NIK</th>
                  <th className='w-25'>Status</th>
                  <th className='w-25'>Desa</th>

                  <th className='w-25'>Kecamatan</th>
                </tr>
              </thead>
              <tbody>
                {secondTable
                  .slice(
                    secondTableCurrentPage * pageSize2,
                    (secondTableCurrentPage + 1) * pageSize2
                  )
                  .map((item) => (
                    <tr key={uuidv4()}>
                      <td className='d-flex align-items-center'>
                        <img
                          className={s.image}
                          src={TulufaliLogo}
                          alt='User'
                        />
                        <span className='ml-3'>{item.name}</span>
                      </td>

                      <td>{item.nama}</td>
                      <td>{item.kelompok}</td>
                      <td>{item.nik}</td>
                      <td>
                        <code>
                          {item.idKeluarga != ''
                            ? ' miskin ekstrim '
                            : ' mampu '}
                        </code>
                      </td>
                      <td>{item.desa}</td>
                      <td>{item.kecamatan}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              className='pagination-borderless'
              aria-label='Page navigation example'
            >
              <PaginationItem disabled={secondTableCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    setSecondTablePage(e, secondTableCurrentPage - 1)
                  }
                  previous
                  href='#top'
                />
              </PaginationItem>
              {[...Array(secondTablePagesCount)].map((page, i) => (
                <PaginationItem active={i === secondTableCurrentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => setSecondTablePage(e, i)}
                    href='#top'
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={secondTableCurrentPage >= secondTablePagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    setFirstTablePage(e, secondTableCurrentPage + 1)
                  }
                  next
                  href='#top'
                />
              </PaginationItem>
            </Pagination>
          </div>
        </Widget>
      </Modal>
    </div>
  );
};

export default TableLapNeraca_Gudang;

import React, { Component, useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import CustomModal from '../../CustomModal/CustomModal';
import { Alert, Col } from 'reactstrap';
import cx from 'classnames';

import AdminListTable from './AdminListTable';

import s from '../Users.module.scss';

const AdminListPage = () => {
  const [promoAlert, setPromoAlert] = useState(false);

  const showPromoAlert = () => setPromoAlert(true);
  const [puskemasKu, setPuskesmasKu] = useState('');
  const [unitKu, setUnitKu] = useState('');
  const [levelKu, setLevelKu] = useState('');

  useEffect(() => {
    setTimeout(() => {
      showPromoAlert();
    }, 100);
  });

  return (
    <div>
      <div className='page-top-line'>
        <Col>
          {levelKu == 'Puskesmas' ? (
            <h2 className='page-title'>
              Daftar <span className='fw-semi-bold'>Users </span> Puskesmas{' '}
              <code>{puskemasKu}</code>
            </h2>
          ) : (
            <h2 className='page-title'>
              Daftar <span className='fw-semi-bold'>Users </span>{' '}
              <code>Puskesmas</code>
            </h2>
          )}
        </Col>
        <Alert
          color='warning'
          className={cx(s.promoAlert, { [s.showAlert]: promoAlert })}
        >
          Aplikasi ini terintegrasi ke{' '}
          <a
            className='text-white font-weight-bold'
            rel='noreferrer noopener'
            href=''
            target='_blank'
          >
            Rote Smart Service
          </a>{' '}
          integration!
        </Alert>
      </div>
      <AdminListTable
        setLevelKu={setLevelKu}
        setUnitKu={setUnitKu}
        setPuskesmasKu={setPuskesmasKu}
      />
    </div>
  );
};

export default AdminListPage;

import React, { useState } from "react";
import { Tab, Nav, Button } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Conversations from "./Conversations";
import Contacts from "./Contacts";
import NewContactModal from "./NewContactModal";
import NewConversationModal from "./NewConversationModal";

const CONVERSATIONS_KEY = "conversations";
const CONTACTS_KEY = "contacts";

export default function Sidebar({ id }) {
  const [activeKey, setActiveKey] = useState(CONVERSATIONS_KEY);
  const [modalOpen, setModalOpen] = useState(false);
  const conversationsOpen = activeKey === CONVERSATIONS_KEY;

  function closeModal() {
    setModalOpen(false);
  }

  const activeTabStyle = {
    backgroundColor: "white",
    color: "blue",
  };

  return (
    <div style={{ width: "250px" }} className="d-flex flex-column">
      <Tab.Container activeKey={activeKey} onSelect={setActiveKey}>
        <Nav variant="tabs" className="justify-content-center">
          <Nav.Item>
            <Nav.Link
              eventKey={CONVERSATIONS_KEY}
              style={activeKey === CONVERSATIONS_KEY ? activeTabStyle : {}}
            >
              Conversations
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={CONTACTS_KEY}
              style={activeKey === CONTACTS_KEY ? activeTabStyle : {}}
            >
              Contacts
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="border-right overflow-auto flex-grow-1">
          <Tab.Pane eventKey={CONVERSATIONS_KEY}>
            <Conversations id={id} />
          </Tab.Pane>
          <Tab.Pane eventKey={CONTACTS_KEY}>
            <Contacts />
          </Tab.Pane>
        </Tab.Content>
        {conversationsOpen && (
          <Button onClick={() => setModalOpen(true)} className="rounded-0">
            New {conversationsOpen ? "Conversation" : ""}
          </Button>
        )}
      </Tab.Container>

      <Modal
        size="md"
        isOpen={modalOpen}
        toggle={() => closeModal()}
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader toggle={() => closeModal()}>
          <div>
            <div className="headline-2">{"Percakapan Baru"}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <NewConversationModal closeModal={closeModal} id={id} />
        </ModalBody>
      </Modal>
    </div>
  );
}

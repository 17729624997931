import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useContacts } from "../contexts/ContactsProvider";
import { useConversations } from "../contexts/ConversationsProvider";
import { FormGroup, Label, Input } from "reactstrap";
import swal from "sweetalert";
import { DOMAIN } from "../utils";
import avatar from "../../assets/img/default-avatar.png";
import { Modal, ModalBody, ModalFooter, ModalHeader, Badge } from "reactstrap";

export default function NewConversationModal({ closeModal, id }) {
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const { contacts } = useContacts();
  const { createConversation } = useConversations();

  const [modalOpen, setModalOpen] = useState(false);
  const [namaGroup, setNamaGroup] = useState("");
  const contactsFilter = contacts.filter((item) => {
    return item.id != id;
  });

  function handleSubmit(e) {
    e.preventDefault();
    if (selectedContactIds.length == 0) {
      return swal("Info", "Belum ada kotak yang dipilih", "info");
    } else if (selectedContactIds.length > 1) {
      return setModalOpen(true);
    } else if (selectedContactIds.length == 1) {
      lanjutSimpan();
    }
  }

  const lanjutSimpan = () => {
    createConversation(selectedContactIds, namaGroup);
    onModalClose();
    closeModal();
  };

  function handleCheckboxChange(contactId) {
    setSelectedContactIds((prevSelectedContactIds) => {
      if (prevSelectedContactIds.includes(contactId)) {
        return prevSelectedContactIds.filter((prevId) => {
          return contactId !== prevId;
        });
      } else {
        return [...prevSelectedContactIds, contactId];
      }
    });
  }

  const onModalClose = () => {
    setModalOpen(false);
    setNamaGroup("");
  };

  function findContactNameById(id) {
    const contact = contacts.find((contact) => contact.id === id);
    return contact ? contact.name : undefined;
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {contactsFilter.map((contact) => (
          <div style={{ marginBottom: 10 }}>
            <FormGroup check key={contact.id}>
              <img
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 15,
                  marginRight: 10,
                }}
                src={contact.profile != "-" ? DOMAIN + contact.profile : avatar}
              />
              <Label check style={{ marginLeft: 20 }}>
                <Input
                  defaultValue=""
                  type="checkbox"
                  value={selectedContactIds.includes(contact.id)}
                  onChange={() => handleCheckboxChange(contact.id)}
                />

                <span className="form-check-sign">
                  <span className="check" /> {contact.name}
                </span>
              </Label>
            </FormGroup>
          </div>
        ))}
        <Button type="submit">Create</Button>
      </Form>
      <Modal
        size="sm"
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader toggle={() => setModalOpen(false)}></ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Nama Group</Label>

            <Input
              value={namaGroup}
              type="text"
              onChange={(e) => setNamaGroup(e.target.value)}
              style={{ color: "black" }}
            />
          </FormGroup>

          <FormGroup>
            <span style={{ color: "blue" }}>
              Anggota Group{" "}
              <Badge color="danger">{selectedContactIds.length}</Badge>
            </span>
            <Label>
              {selectedContactIds.map((r) => findContactNameById(r)).join(", ")}
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              className="btn-rounded"
              size="sm"
              color="secondary-red"
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <div style={{ marginLeft: 5 }}></div>

            <Button
              className="btn-rounded"
              color="primary"
              size="sm"
              onClick={() => {
                if (namaGroup) {
                  lanjutSimpan();
                } else {
                  swal("Info", "Nama group harus diinput", "info");
                }
              }}
            >
              {"Simpan"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export const API_KALORI = "http://localhost:4000/api/";
//'http://localhost:4000/api/admin/';

export const API_TULUFALI = "http://localhost:4000/api/";
//'http://localhost:4000/api/admin/';

export const API_SIMOBAT = "https://simobat.rotendaokab.go.id/api/";

export const DOMAIN_SIMOBAT = "https://simobat.rotendaokab.go.id/dokumen/";

//export const API_SIMOBAT = 'http://localhost:4000/api/';
//'http://localhost:4000/api/admin/';

//'https://smartservice.rotendaokab.go.id/api/admin/';
//export const API_SMART = 'http://192.168.43.39:4000/api/';

export const MASUK_GUDANG = API_SIMOBAT + "masukgudang/";
export const KELUAR_GUDANG = API_SIMOBAT + "keluargudang/";

export const KELUAR = API_SIMOBAT + "keluar/";

export const KELUAR_UNIT = API_SIMOBAT + "keluarunit/";
export const DATA_OBAT = API_SIMOBAT + "dataobat/";

export const MASUK = API_SIMOBAT + "masuk/";

export const MASUK_UNIT = API_SIMOBAT + "masukunit/";

export const STOCK_GUDANG = API_SIMOBAT + "stock/gudang/";

export const STOCK_PUSKESMAS = API_SIMOBAT + "stock/puskesmas/";
export const GET_KECAMATAN = API_SIMOBAT + "kecamatan";
export const GET_DESA = API_SIMOBAT + "desa";

export const GET_LIST_PENYAKIT = API_SIMOBAT + "listpenyakit";
export const USERS = API_SIMOBAT + "users/";

export const STOCK_UNIT = API_SIMOBAT + "stock/unit/";

export const PERMINTAAN = API_SIMOBAT + "permintaan/";

export const KELUAR_MASUK = API_SIMOBAT + "keluarmasuk/";
export const KELUAR_MASUK_UNIT = API_SIMOBAT + "keluarmasuk/unit/";

export const FAKTUR_PERMINTAAN = API_SIMOBAT + "fakturpermintaan/";
export const FAKTUR_MASUK = API_SIMOBAT + "fakturkeluarmasuk/";

export const STOCK_GUDANG_TERSEDIA =
  API_SIMOBAT + "stocktersedia/gudang/nonexpired/";

export const STOCK_PUSKESMAS_TERSEDIA =
  API_SIMOBAT + "stocktersedia/puskesmas/nonexpired/";

export const STOCK_UNIT_TERSEDIA =
  API_SIMOBAT + "stocktersedia/unit/nonexpired/";

export const LOKASI = API_SIMOBAT + "lokasi/";
export const LOG_APP = API_SIMOBAT + "logapp/";

export const NERACA = API_SIMOBAT + "neraca/";

export const DASHBOARD = API_SIMOBAT + "dashboard/";
export const REKAPAN = API_SIMOBAT + "rekapan/";

export const SUMBER = API_SIMOBAT + "sumber/";
export const SUB_SUMBER = API_SIMOBAT + "subsumber/";

export const PETA_PENYAKIT = API_SIMOBAT + "peta/penyakit";

//batasssss
export const GET_KELUARGA_KECAMATAN = API_TULUFALI + "keluarga/kecamatan/";

export const GET_BANTUAN_TAHUN = API_TULUFALI + "bantuantahun/ambil/tahun/";

export const POST_BANTUAN_TAHUN = API_TULUFALI + "bantuantahun/ambil";

export const DELETE_BANTUAN_TAHUN = API_TULUFALI + "bantuantahun/";

export const GET_BANTUAN_TAHUN_AMBIL = API_TULUFALI + "bantuantahun/ambil/";

export const GET_BANTUAN_TAHUN_KATEGORI =
  API_TULUFALI + "databantuan/kategori/";

export const GET_BANTUAN = API_TULUFALI + "databantuan/";
export const DELETE_BANTUAN = API_TULUFALI + "databantuan/";

export const DATA_BANTUAN_API = API_TULUFALI + "databantuan/";

export const GET_INDIVIDU_DESIL_DESA = API_TULUFALI + "individu/perdesil/desa";
export const GET_INDIVIDU_DESIL_KECAMATAN =
  API_TULUFALI + "individu/perdesil/kecamatan";

export const BANTUAN_TAHUN_API = API_TULUFALI + "bantuantahun/";

export const BANTUAN_API = API_TULUFALI + "bantuan/";

export const BANTUAN_DINAS_API = API_TULUFALI + "bantuan/dinas/";

export const CARI_DATA_MISKIN_LIKE = API_TULUFALI + "datamiskin/cari/like";

export const CARI_DATA_MISKIN_LIKE_DTKS =
  API_TULUFALI + "datamiskin/cari/like/dtks";

export const CARI_NIK_DTKS = API_TULUFALI + "dtks/";

export const CARI_NIK_DUKCAPIL = API_TULUFALI + "dukcapil/";

export const PROGRESS_BANTUAN_API = API_TULUFALI + "progresbantuan/";

export const HISTORI_BANTUAN_API = API_TULUFALI + "historibantuan/";
export const PROGRESS_DINAS_API = API_TULUFALI + "progresdinas/";

export const AKUMULASI_BANTUAN_TAHUN = API_TULUFALI + "ambil/akumulasibantuan/";

export const VALIDASI_BANTUAN = API_TULUFALI + "validasibantuan/";
export const VALIDASI_HISTORI = API_TULUFALI + "validasihistori/";
export const PIRAMIDA_PENDUDUK = API_TULUFALI + "piramidapenduduk/";
export const PEKERJAAN_PENDUDUK = API_TULUFALI + "pekerjaanpendudukmiskin/";

export const PENDIDIKAN_KECAMATAN = API_TULUFALI + "pendidikankecamatan/";
export const DESIL_KESEJAHTERAAN_KECAMATAN =
  API_TULUFALI + "desilkesejahteraankecamatan/";
export const PADAN_DUKCAPIL = API_TULUFALI + "padandukcapil/";

export const VALIDASI_INPUT_BANTUAN = API_TULUFALI + "validasi/inputbantuan/";

export const LIMA_TAHUN_TERAKHIR = API_TULUFALI + "bantuan/limatahunterakhir";
export const ADMIN_LOGIN = API_TULUFALI + "admin/login";
export const ADMIN_TOKEN = API_TULUFALI + "admin/token";

export const DESA_BANTUAN_TAHUN = API_TULUFALI + "bantuan/desa/";

export const UPDATE_SK = API_TULUFALI + "bantuantahun/updateSK/";
export const UPDATE_KUNCI = API_TULUFALI + "bantuantahun/updateKunci/";

export const LIST_ADMIN_TF = API_TULUFALI + "tulufali/admin/";

export const LOGOUT_ADMIN_TF = API_TULUFALI + "admin/";

export const VALIDASI_DIFABEL = API_TULUFALI + "difabelkel/";

export const GET_UKURAN = API_TULUFALI + "ukuran";
export const GET_ASUPAN = API_TULUFALI + "asupan";
export const POST_ASUPAN = API_TULUFALI + "asupan/input";
export const GET_USER = API_TULUFALI + "user";
export const POST_USER = API_TULUFALI + "user/input";

export const POST_MAKAN = API_KALORI + "makan/input";
export const DELETE_MAKAN = API_KALORI + "makan/";
export const GET_MAKAN = API_KALORI + "makan/ambil";

export const GET_KONSUMSI_KALORI = API_KALORI + "makan/konsumsi";
export const GET_KEBUTUHAN_KALORI = API_KALORI + "kebutuhankalori";
export const GET_KONSUMSI_KALORI_KATEGORI =
  API_KALORI + "makan/konsumsi/kategori";
export const GET_KONSUMSI_KALORI_HARI = API_KALORI + "konsumsi/kalori/hari";

export const API_TIMEOUT = 120000;
